import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNonPersistedStore } from "../store/nonPersistedStore";
import { deleteCollectionList, getCollectionList } from "../api/getRequest";
import {ReactComponent as ChevronBack} from '../assest/svg/chevronLeft.svg';
import { ReactComponent as DownArrowDesc } from "../assest/svg/sortDown.svg";
import { ReactComponent as UpArrowAsc } from "../assest/svg/sortUp.svg";
import {
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import DamHeader from "../components/DamHeader";
import LeftMenu from "../components/LeftMenu";
import { ReactComponent as Asset } from "../assest/svg/asset.svg";
import { ReactComponent as LeftArrow } from "../assest/svg/arrowLeft.svg";
import { ReactComponent as Share } from "../assest/svg/shareicon.svg";
import { ReactComponent as Edit } from "../assest/svg/editicon.svg";
import { ReactComponent as Delete } from "../assest/svg/deleteicon.svg";
import { ReactComponent as CollectionShare } from "../assest/svg/collectionshare.svg";
import { ReactComponent as RightArrow } from "../assest/svg/arrowRight.svg";

import Loader from "../components/Loader";
import DamPortalModal from "../components/DamPortalModal";
import { Box } from "@mui/system";
import { showErrorToast, showSuccessToast } from "../assest/Toaster";
import { collectionShareApi, createNewCollection, renameCollection } from "../api/postRequest";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import {withStyles} from "@material-ui/core/styles";
import { useStore } from "../store/persistedStore";
import { treeItemClasses } from "@mui/x-tree-view/TreeItem";
import commonMessages from '../utils/commomMessages';
import PaginationComponent from "../components/PaginationComponent";
import { handleKeyDown } from "../utils/handleKeyDownEvent";


const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);

const CollectionListPage = () => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [collectionDeleteModal, setCollectionDeleteModal] = useState(false);
  const [shareCollectionInitialModal, setShareCollectionInitialModal] = useState(false);
  const [isRenameCollectionModal, setIsRenameCollectionModal] = useState(false);
  const [addNewCollectionModal, setAddNewCollectionModal] = useState(false);
  const [shareCopyToClipboardModal,setShareCopyToClipboardModal]= useState(false)
  const [shareCollectioninitialId,setShareCollectioninitialId]= useState(false)
  const [searchValueCreateNewCollection,setSearchValueCreateNewCollection]= useState()
  
  
  const [linkPass, setLinkPass] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [collectionName, setCollectionName] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [shareName, setShareName] = useState();
  const [classSort, setClassSort] = useState();
  
  const navigate = useNavigate();
  const pageSize = 12; //
  const {
    

    setClickedCollectionRowZus,clickedCollectionRowZus
  } = useStore();
  const {
    selectedMimeTypeZusNon,
    collectionListZusNon,collectionListTotalCountZusNon,
    setCollectionListZusNon,clickedFolderZusNon,setCollectionListTotalCountZusNon
   
  } = useNonPersistedStore();
 


  const mutationGetCollectionList = useMutation((post) => getCollectionList(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          setCollectionListZusNon(result.data?.data?.collectionlist);
            setCollectionListTotalCountZusNon(result.data?.data?.totalResults);


        } else {
          setCollectionListZusNon(null);
          setCollectionListTotalCountZusNon(0);
          setErrorMessage(result?.data?.message)
        }
      }
    },
    onError: (error) => { console.log(error)},
  });

  const mutationDeleteCollectionList = useMutation(
    (post) => deleteCollectionList(post),
    {
      mutationKey: "deleteCollectionList",
      method: "GET",
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            let payload ={
              currentPage:currentPage
            }
            mutationGetCollectionList.mutate(payload);
            handleCloseModal();
            showSuccessToast(result?.data?.message);
          } else {
          }
        }
      },
      onError: (error) => {},
    }
  );



  const collectionShareApiMutation = useMutation((post) => collectionShareApi(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          setLinkPass(result?.data?.url)
          setShareCopyToClipboardModal(true)
        } else {
          showErrorToast(result?.data?.message)
        }
      }
    },
    onError: (error) => { console.log(error)},
  });



  

   
  const createNewCollectionMutation = useMutation(
    (post) => createNewCollection(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            showSuccessToast(result?.data?.message);
            setAddNewCollectionModal(false);
            handleCloseModal();
            let payload ={
              currentPage:currentPage
            }
            mutationGetCollectionList.mutate(payload);
          } else {
            showErrorToast(result?.data?.message)
          }
        }
      },
      onError: (error) => {},
    }
  );
  const renameCollectionMutation = useMutation(
    (post) => renameCollection(post, deleteId),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            showSuccessToast(result.data.message);
            handleCloseModal();
            let payload ={
              currentPage:currentPage
            }
            mutationGetCollectionList.mutate(payload);
          } else {
            showErrorToast(result.data.message)
          }
        }
      },
      onError: (error) => {},
    }
  );

  useEffect(() => {
    let payload ={
      currentPage:currentPage
    }
    mutationGetCollectionList.mutate(payload);
  }, []);
const handleAscSort=(val)=>{
 
  if(val=="name")
  {
     setClassSort("nameASC");

     let payload = {
      sortBy:"name",
      sortOrder:"asc"
    };

    mutationGetCollectionList.mutate(payload);
//   let cloneCollection= collectionListZusNon;
//  cloneCollection?.sort((a,b)=>a.label.localeCompare(b.label))
//  setCollectionListZusNon(cloneCollection)
  }
  if(val=="count")
  {
    setClassSort("countASC");
    let payload = {
      sortBy:"itemcount",
      sortOrder:"asc"
    };

    mutationGetCollectionList.mutate(payload);
//   let cloneCollection= collectionListZusNon;
//  cloneCollection?.sort((a,b)=>a?.item_count-b?.item_count)
//  setCollectionListZusNon(cloneCollection)
  }
  if(val=="date")
  {
    setClassSort("dateASC");
    let payload = {
      sortBy:"creationDate",
      sortOrder:"asc"
    };

    mutationGetCollectionList.mutate(payload);
//   let cloneCollection= collectionListZusNon;
//  cloneCollection?.sort((a,b)=>new Date(a?.creation_date)-new Date(b?.creation_date))
//  setCollectionListZusNon(cloneCollection)
  }
  if(val=="Mdate")
  {
    setClassSort("MdateASC")
    let payload = {
      sortBy:"modification_date",
      sortOrder:"asc"
    };

    mutationGetCollectionList.mutate(payload);

  }
}

const handleDescSort=(val)=>{
  if(val=="name")
  {
    setClassSort("nameDSC");
    let payload = {
      sortBy:"name",
      sortOrder:"desc"
    };

    mutationGetCollectionList.mutate(payload);
//   let cloneCollection= collectionListZusNon;
//  cloneCollection?.sort((a,b)=>b.label.localeCompare(a.label))
//  setCollectionListZusNon(cloneCollection)
  }
  if(val=="count")
  {
    setClassSort("countDSC");
    let payload = {
      sortBy:"itemcount",
      sortOrder:"desc"
    };

    mutationGetCollectionList.mutate(payload);
//   let cloneCollection= collectionListZusNon;
//  cloneCollection?.sort((a,b)=>b?.item_count-a?.item_count)
//  setCollectionListZusNon(cloneCollection)
  }
  if(val=="date")
  {
    setClassSort("dateDSC");
    let payload = {
      sortBy:"creationDate",
      sortOrder:"desc"
    };

    mutationGetCollectionList.mutate(payload);
//   let cloneCollection= collectionListZusNon;
//   cloneCollection?.sort((a,b)=>new Date(b?.creation_date)-new Date(a?.creation_date))
//  setCollectionListZusNon(cloneCollection)
  }
  if(val=="Mdate")
  {
    setClassSort("MdateDSC")
    let payload = {
      sortBy:"modification_date",
      sortOrder:"desc"
    };

    mutationGetCollectionList.mutate(payload);

  }
}

  const newCollection =
    collectionListZusNon &&
    collectionListZusNon?.map((obj) => ({
      name:obj?.label,
      ...obj,
   
      ["action"]: "actionSvgIcons",
    }));

    const headingsWithoutFilter = collectionListZusNon?.length > 0 ? Object.keys(newCollection[0]) : null;

  // const headingsWithoutFilter = collectionListZusNon && Object?.keys(newCollection[0]);

//  for (let i = 0; i < headingsWithoutFilter?.length; i++) {
//   if (headingsWithoutFilter[i] == "label") {
//     headingsWithoutFilter[i] = "Name";
//     break;
//   }
// }

let itemToRemove = "label"; // value of the item you want to remove
let indexToRemove = headingsWithoutFilter?.indexOf(itemToRemove);
if (indexToRemove !== -1) {
  headingsWithoutFilter?.splice(indexToRemove, 1);
}
 

  const headings =
    headingsWithoutFilter &&
    headingsWithoutFilter?.filter((e) => e !== "value");


 

 
  // const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => ` ${letter.toUpperCase()}`);
  const camelToNormalCase = (camelCase) =>
    camelCase
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim();

  const handleShareNavigation = (row) => {
    setShareCollectionInitialModal(true);
    setShareCollectioninitialId(row?.value);
    // navigate({
    //   pathname: "/shareAssetPage",
    // });
    setShareName(row?.name)
  };
  const handlePublicCollectionListing = (row) => {
  
    
     navigate(`/collectionListingPage/${row?.value}`);
  };

  
  const handleDeleteAsset = (id) => {
    setIsDeleteModal(true);
    setDeleteId(id);
    setCollectionDeleteModal(true)
  };
  const handleRenameCollectionModal = (id, name) => {
    setIsRenameCollectionModal(true);
    setDeleteId(id);
    setCollectionName(name);
  };

  const handleCallbackDelete = () => {
    if (isDeleteModal) {
      if (deleteId) {
        mutationDeleteCollectionList.mutate(deleteId);
      }
    }
  };
  const handleCallbackShareCollection = (payload) => {
    collectionShareApiMutation.mutate(payload)
 

  };

  const handleCloseModal = () => {
    setIsRenameCollectionModal(false);
    setAddNewCollectionModal(false);
    setIsDeleteModal(false);
    setShareCollectionInitialModal(false)
    setShareCopyToClipboardModal(false)
    setSearchValueCreateNewCollection(null)
  };
  
  const handleAddNewCollectionModal = () => {
  
  
    // setAssetId(id);
    setAddNewCollectionModal(true);
  };
  const handleCallbackAddNewCollectionModal = (value) => {
    // setAssetId(id);
    if (value) {
      let payload = {
        name: value,
      };
      createNewCollectionMutation.mutate(payload);
    }
  };
  const handleOnChangeCreateNewCollection = (e) => {
    if (e.target.value.startsWith(" ")) {
      return;
    } else {
      setSearchValueCreateNewCollection(e.target.value);
    }
  };
  
  const handleCallbackRenameCollectionModal = (value) => {
    if (deleteId) {
      let payload = {
        name: value,
      };
      renameCollectionMutation.mutate(payload);
    }
  };

  const handleNavigationEachCollection = (id,row) => {
    navigate({
      pathname: "/collectionDetailPage",
      search: `id=${id}`,
    });
    setClickedCollectionRowZus(row)
  };
  const handleNavigationBack = () => {
   
    navigate(-1);
  };

  const handlePageChange = (newPage) => {
    if (newPage === 0) {
      return;
    } else {
      setCurrentPage(newPage);

      let payload = {
        currentPage: newPage,
      };

      mutationGetCollectionList.mutate(payload);
    }
  };

 
  
  return (
    <>
      {mutationGetCollectionList?.isLoading && <Loader></Loader>}
      {mutationDeleteCollectionList?.isLoading && <Loader></Loader>}
      {renameCollectionMutation?.isLoading && <Loader></Loader>}
      {collectionShareApiMutation?.isLoading && <Loader></Loader>}

      <Box>
        <DamPortalModal
          open={shareCopyToClipboardModal}
          shareCopyToClipboardModalFlag={shareCopyToClipboardModal}
          onClose={handleCloseModal}
          titleMessage={"Collection share"}
          linkPass={linkPass}
        />
      </Box>
      <Box>
        <DamPortalModal
          open={isRenameCollectionModal}
          onClose={handleCloseModal}
          titleMessage={commonMessages?.renameCollection}
          collectionName={collectionName}
          isRenameCollectionModal={isRenameCollectionModal}
          handleCallbackRenameCollectionModal={
            handleCallbackRenameCollectionModal
          }
        />
      </Box>
      <Box>
        <DamPortalModal
          open={addNewCollectionModal}
          onClose={handleCloseModal}
          titleMessage={commonMessages?.createNewCollection}
          addNewCollectionModal={addNewCollectionModal}
          searchValueCreateNewCollection={searchValueCreateNewCollection}
          handleOnChangeCreateNewCollection={handleOnChangeCreateNewCollection}
          createNewCollectionMutation={createNewCollectionMutation}
          handleCallbackAddNewCollectionModal={
            handleCallbackAddNewCollectionModal
          }
        />
      </Box>
      <Box>
        <DamPortalModal
          open={isDeleteModal}
          collectionDeleteModal={collectionDeleteModal}
          onClose={handleCloseModal}
          titleMessage={"Delete"}
          deleteAsset={true}
          mutationDeleteCollectionList={mutationDeleteCollectionList}
          handleCallbackDelete={handleCallbackDelete}
        />
      </Box>

      {!shareCopyToClipboardModal && (
        <Box>
          <DamPortalModal
            open={shareCollectionInitialModal}
            shareCollectionInitialModal={shareCollectionInitialModal}
            shareCollectioninitialId={shareCollectioninitialId}
            onClose={handleCloseModal}
            titleMessage={"Share collection"}
            handleCallbackShareCollection={handleCallbackShareCollection}
            shareName={shareName}
          />
        </Box>
      )}

      <Grid container className="container bg">
        <Grid item xs={1} className="menu">
          <LeftMenu />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ minHeight: "calc(100% - 50px)" }}
          className="rightPart"
        >
          <DamHeader />

          <Grid container className=" bodyPart">
            <Box className="selector ">
              <Box className="breadcrumb">
                <Typography component="h2" variant="h5" className="heading">
                  Collections
                </Typography>
              </Box>
              <Box className="buttonGroup">
                <Button
                  size="small"
                  startIcon={<ChevronBack />}
                  className="button"
                  onClick={handleNavigationBack}
                >
                  Back
                </Button>
                <Button
                  size="small"
                  // startIcon={<LeftArrow />}
                  variant="filled"
                  className="button"
                 
                  onClick={() => handleAddNewCollectionModal()}
                  onKeyDown={handleKeyDown} // Add onKeyDown handler

                >
                  {commonMessages?.createNewCollection}
                </Button>
              </Box>
            </Box>
            {collectionListZusNon && collectionListZusNon?.length > 0 ? (
              <Box>
                <TableContainer sx={{ padding: "1em", marginTop: "70px" }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="table fixed"
                  >
                    <TableHead>
                      <TableRow>
                        {headings &&
                          headings?.map((heading, index) => (
                            <TableCell key={index}>
                              {heading === "item_count" ? (
                                <Box className="flex">
                                  Asset count
                                  <Box className="flex paddingL-sm arrow">
                                    <UpArrowAsc
                                      className={
                                        classSort == "countASC" ? "active" : ""
                                      }
                                      height={20}
                                      onClick={() => handleAscSort("count")}
                                    />
                                    <DownArrowDesc
                                      className={
                                        classSort == "countDSC" ? "active" : ""
                                      }
                                      height={20}
                                      onClick={() => handleDescSort("count")}
                                    />
                                  </Box>
                                </Box>
                              ) : heading === "creation_date" ? (
                                <Box className="flex">
                                  Creation date{" "}
                                  <Box className="flex paddingL-sm arrow">
                                    <UpArrowAsc
                                      className={
                                        classSort == "dateASC" ? "active" : ""
                                      }
                                      height={20}
                                      onClick={() => handleAscSort("date")}
                                    />
                                    <DownArrowDesc
                                      className={
                                        classSort == "dateDSC" ? "active" : ""
                                      }
                                      height={20}
                                      onClick={() => handleDescSort("date")}
                                    />
                                  </Box>
                                </Box>
                              ) : heading === "name" ? (
                                <Box className="flex">
                                  Name{" "}
                                  <Box className="flex paddingL-sm arrow">
                                    <UpArrowAsc
                                      className={
                                        classSort == "nameASC" ? "active" : ""
                                      }
                                      height={20}
                                      onClick={() => handleAscSort("name")}
                                    />
                                    <DownArrowDesc
                                      className={
                                        classSort == "nameDSC" ? "active" : ""
                                      }
                                      height={20}
                                      onClick={() => handleDescSort("name")}
                                    />
                                  </Box>
                                </Box>
                              ): heading === "modification_date" ? ( <Box className="flex">
                              Modification Date{" "}
                              <Box className="flex paddingL-sm arrow">
                                <UpArrowAsc
                                  className={
                                    classSort == "MdateASC" ? "active" : ""
                                  }
                                  height={20}
                                  onClick={() => handleAscSort("Mdate")}
                                />
                                <DownArrowDesc
                                  className={
                                    classSort == "MdateDSC" ? "active" : ""
                                  }
                                  height={20}
                                  onClick={() => handleDescSort("Mdate")}
                                />
                              </Box>
                            </Box>) : (
                                <> {camelToNormalCase(heading)}</>
                              )}
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& .button": {
                          border: 0,
                          margin: "2px !important",
                        },
                        "& img": {
                          height: "60px",
                          maxWidth: "60px",
                          objectFit: "cover",
                        },
                      }}
                    >
                      {newCollection &&
                        newCollection?.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {headings &&
                              headings?.map((heading, columnIndex) => (
                                <>
                                  {row[heading] === "actionSvgIcons" ? (
                                    <>
                                      <TableCell key={columnIndex}>
                                        <Box className="buttonGroup">
                                          <TooltipStyle
                                            placement="bottom"
                                            arrow
                                            title={
                                              commonMessages?.collectionShareHistoryHeading
                                            }
                                          >
                                            <Button
                                              size="small"
                                              className="button icon"
                                              onClick={() =>
                                                handlePublicCollectionListing(
                                                  row
                                                )
                                              }
                                            >
                                              <CollectionShare />
                                            </Button>
                                          </TooltipStyle>
                                          {newCollection[rowIndex]
                                            ?.item_count ? (
                                            <TooltipStyle
                                              placement="bottom"
                                              arrow
                                              title="Share"
                                            >
                                              <Button
                                                size="small"
                                                className="button icon"
                                                onClick={() => {
                                                  handleShareNavigation(row);
                                                }}
                                              >
                                                <Share />
                                              </Button>
                                            </TooltipStyle>
                                          ) : (
                                            <>
                                              <TooltipStyle
                                                placement="bottom"
                                                arrow
                                                title="Share"
                                              >
                                                <Button
                                                  size="small"
                                                  className="button icon"
                                                  onKeyDown={handleKeyDown} 
                                                  disabled={true}
                                                >
                                                  <Share />
                                                </Button>
                                              </TooltipStyle>
                                            </>
                                          )}

                                          <TooltipStyle
                                            placement="bottom"
                                            arrow
                                            title="Rename"
                                          >
                                            <Button
                                              size="small"
                                              className="button icon"
                                              onKeyDown={handleKeyDown} 
                                              onClick={() =>
                                                handleRenameCollectionModal(
                                                  row?.value,
                                                  row?.label
                                                )
                                              }
                                            >
                                              <Edit />
                                            </Button>
                                          </TooltipStyle>

                                          <TooltipStyle
                                            placement="bottom"
                                            arrow
                                            title="Delete"
                                          >
                                            <Button
                                              size="small"
                                              className="button icon"
                                              onClick={() => {
                                                handleDeleteAsset(row?.value);
                                              }}
                                              onKeyDown={handleKeyDown} 
                                            >
                                              <Delete />
                                            </Button>
                                          </TooltipStyle>
                                        </Box>
                                      </TableCell>
                                    </>
                                  ) : (
                                    <>
                                      <TableCell
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleNavigationEachCollection(
                                            row?.value,
                                            row
                                          );
                                        }}
                                      >
                                        {row[heading]}
                                      </TableCell>
                                    </>
                                  )}
                                  {/* <TableCell>icon</TableCell> */}
                                </>
                              ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  className="collectionList"
                  sx={{
                    marginBottom: "20px",
                    position: "relative",
                    marginTop: "40px",
                  }}
                >
                  <PaginationComponent
                    currentPage={currentPage}
                    totalCount={collectionListTotalCountZusNon}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    itemCount={collectionListZusNon?.length || 0}
                    // itemsPerPage={itemsPerPage}
                  />
                </Box>
              </Box>
            ) : (
              <>
                <Box
                  className="notificationWrapper col-12"
                  sx={{ padding: "1em", marginTop: "var(--sideSetHeight)" }}
                >
                  <Box className="notification">
                    {errorMessage ?? "No collection present"}
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default CollectionListPage;
