export const menuStyles = {
    overflow: "visible",
    filter: "drop-shadow(0px 0px 8px rgba(0,0,0,0.12))",
    mt: 0,
    borderRadius: 0,
    minWidth: "200px ",
    "& .MuiPaper-root": {
      borderRadius: "0",
      border: "1px solid var(--border)",
      minWidth: "200px ",
      marginBottom: '0px !important',
      paddingBottom: '0px !important',
    },
    "& .MuiList-root": {
      padding: '0 !important',
      color: "var(--text)",
      "& svg": {
        marginRight: "5px",
        fill: "var(--text)",
        width: "15px",
      },
    },
    "& .MuiMenuItem-root": {
      borderBottom: "1px solid var(--border)",
      fontSize: "14px",
      justifyContent: 'flex-start',

    },
    "& .MuiChip-root": {
      borderRadius: 0,
      padding: 0,
      fontSize: "12px",
      height: "auto",
      width: "50px",
      marginLeft: "auto",
    },
    "& .MuiPopover-paper":{
      border: '0',
      "& ul":{
        border: '1px solid var(--border)',
      },
      // "&:before": {
      //   content: '""',
      //   display: "block",
      //   position: "relative",
      //   top: '11px',
      //   right: '-10px',
      //   width: 10,
      //   height: 10,
      //   bgcolor: "background.paper",
      //   transform: "translateY(-50%) rotate(45deg)",
      //   zIndex: 0,
      // }
    }

  };
   
  export const paperPropsStyles = {
    elevation: 0,
    sx: menuStyles,
  };
  