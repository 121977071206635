import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import LeftMenu from "../components/LeftMenu";
import DamHeader from "../components/DamHeader";
import {ReactComponent as Folder} from '../assest/svg/folder.svg';
import SelectorDetail from "../components/SelectorDetail";
import Footer from "../components/Footer";
import { ReactComponent as UploadAsset } from "../assest/svg/UploadAsset.svg";
import { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropzone from "react-dropzone";
import {
  getCollectionModalTags,
  uploadAssetFromUrl,
  uploadSingleAssetFile,
  uploadZip,
} from "../api/postRequest";
import { useMutation } from "@tanstack/react-query";
import { showSuccessToast } from "../assest/Toaster";
import { useStore } from "../store/persistedStore";
import { useNonPersistedStore } from "../store/nonPersistedStore";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { uploadUrlSchema } from "../schemas/uploadUrlSchema";
import { ErrorMessageStyled } from "../styledComponents/ErrorMessageStyled";
import { ReactComponent as ChevronBack } from "../assest/svg/chevronLeft.svg";
import { ReactComponent as ChevronNext } from "../assest/svg/chevronRight.svg";
import { ReactComponent as Close } from "../assest/svg/close.svg";

import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import DamPortalModal from "../components/DamPortalModal";

const fileContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "10px",
  padding: "5px 10px",
  border: "1px solid #cccccc",
  borderRadius: "4px",
};

const UploadAssetsPage = () => {
  const { clickedFolderZus, setClickedFolderZus } = useStore();
  const { clickedFolderZusNon, setClickedFolderZusNon } =
    useNonPersistedStore();
  const [block, setBlock] = useState("File");
  const [searchParams] = useSearchParams();
  const [file, setFile] = useState(null);
  const [isUploadFileModal, setIsUploadFileModal] = useState(false);
  const [webUrlData, setWebUrlData] = useState();
  const [zipFile, setZipFile] = useState(null);
  const [uploadFileTags, setUploadFileTags] = useState();
  
  const navigate = useNavigate();
  const acceptedMimeTypes = ["application/zip", "application/x-zip-compressed"];
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(uploadUrlSchema),
    defaultValues: {},
  });

  const uploadSingleAssetFileMutation = useMutation(
    (post) => uploadSingleAssetFile(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            // mutation.mutate({assetId:detailPageData?.id})
            showSuccessToast(result?.data?.message);
            removeFile();
          } else {
          }
        }
      },
      onError: (error) => {},
    }
  );
  const uploadFromUrlMutation = useMutation(
    (post) => uploadAssetFromUrl(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            // mutation.mutate({assetId:detailPageData?.id})
            showSuccessToast(result?.data?.message);
          } else {
          }
        }
      },
      onError: (error) => {},
    }
  );
  const uploadZipMutation = useMutation((post) => uploadZip(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          // mutation.mutate({assetId:detailPageData?.id})
          showSuccessToast(result?.data?.message);
          removeFile();
        } else {
        }
      }
    },
    onError: (error) => {},
  });
  const getCollectionModalTagsMutation = useMutation((post) => getCollectionModalTags(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          setUploadFileTags(result?.data?.data?.items)
    
         
        } else {
        }
      }
    },
    onError: (error) => {},
  });
  const onDrop = useCallback((acceptedFiles) => {
    // Ensure only one file is accepted
    if (acceptedFiles.length > 0) {
      setFile(
        acceptedFiles[0]
        //  name: acceptedFiles[0].name,
        // parentId:clickedFolderZus.id
      );
    }
  }, []);
  const acceptedFileTypes = [".zip"];
  const onZipfileDrop = useCallback((acceptedFiles) => {
    const zipFiles = acceptedFiles.filter((file) =>
      acceptedFileTypes.some((type) => file.name.endsWith(type))
    );

    if (zipFiles.length > 0) {
      setZipFile(zipFiles[0]);
    }
  }, []);

  const removeFile = () => {
    setFile(null);
    if (block == "UploadZip") {
      setZipFile(null);
    }
  };

  const handleUpload = () => {
    let payloadFile = {
      parentId: searchParams?.get("folderId"),
      Filedata: file,
    };
    let payloadZip = {
      parentId: searchParams?.get("folderId"),
      Filedata: zipFile,
    };

    if (block == "File") {
      uploadSingleAssetFileMutation.mutate(payloadFile);
    }

    if (block == "UploadZip") {
      uploadZipMutation.mutate(payloadZip);
    }
  };
  const handleUploadFileModal = () => {
    setIsUploadFileModal(true)
    getCollectionModalTagsMutation.mutate()
  };


  const onSubmit = (data, event) => {
    event.preventDefault();
    setWebUrlData(data?.webUrl) 
    let payload = {
      url: data?.webUrl,
      parentId: searchParams?.get("folderId"),
    };
    if (searchParams?.get("folderId")) {
      setIsUploadFileModal(true)
      getCollectionModalTagsMutation.mutate()
      // uploadFromUrlMutation.mutate(payload);
    }
  };
  const handleNavigationBack = () => {
    navigate(-1);
  };
  const handleCloseModal = () => {
    setIsUploadFileModal(false)
  
  };
  const handleCallbackUploadFileModal = (paramsDataUploadModal) => {
   
    let payloadFile = {
      parentId: searchParams?.get("folderId"),
      Filedata: file,
      content: { tags: paramsDataUploadModal?.content },
      newFilename: paramsDataUploadModal?.newFilename,
    };
    let payloadZip = {
      parentId: searchParams?.get("folderId"),
      Filedata: zipFile,
    };

    let payloadWebUrl = {
      url: webUrlData,
      parentId: searchParams?.get("folderId"),
      content: { tags: paramsDataUploadModal?.content },
      newFilename: paramsDataUploadModal?.newFilename,
    };
    if (block == "File") {
     
      uploadSingleAssetFileMutation.mutate(JSON.stringify(payloadFile));
    }

    if (block == "UploadZip") {
      uploadZipMutation.mutate(payloadZip);
    }
    if (block == "WebUrl") {
      uploadFromUrlMutation.mutate(JSON.stringify(payloadWebUrl));
    }
   
  };
  
  return (
    <>
      {uploadFromUrlMutation?.isLoading && <Loader></Loader>}
      {uploadZipMutation?.isLoading && <Loader></Loader>}
      {uploadSingleAssetFileMutation?.isLoading && <Loader></Loader>}
      <Box>
        <DamPortalModal
          open={isUploadFileModal}
          onClose={handleCloseModal}
          titleMessage={
            block === "File" ? "Details" : block === "WebUrl" ? "Details" : ""
          }
          block={block}
          uploadFileTags={uploadFileTags}
          // collectionName={collectionName}
          isUploadFileModal={isUploadFileModal}
          handleCallbackUploadFileModal={handleCallbackUploadFileModal}
        />
      </Box>
      <Grid container className="container bg">
        <Grid item xs={1} className="menu">
          <LeftMenu />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ minHeight: "calc(100% - 50px)" }}
          className="rightPart"
        >
          <DamHeader />
          <Grid container className="bodyPart">
            <Grid item xs={7} className="assetDetailLeft">
              <Box className="headinAlign">
                <Typography component="h2" variant="h5">
                  Upload assets
                </Typography>
                <Box className="buttonGroup">
                  <Button
                    size="small"
                    startIcon={<ChevronBack />}
                    className="button"
                    onClick={handleNavigationBack}
                  >
                    Back
                  </Button>

                  {/* <Button
                    size="small"
                    endIcon={<ChevronNext />}
                    className="button"
                  >
                    Next
                </Button> */}
                </Box>
              </Box>

              {block === "File" ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "1em",
                      cursor: "pointer",
                    }}
                  >
                    <Dropzone onDrop={onDrop} maxFiles={1}>
                      {({ getRootProps, getInputProps }) => (
                        <>
                          <Box
                            {...getRootProps()}
                            // style={dropzoneStyle}
                            sx={{
                              border: "1px dashed var(--border)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "1em 0",
                              width: "100%",
                              minHeight: "300px",
                              boxSizing: "border-box",
                            }}
                          >
                            <input {...getInputProps()} />
                            <p>
                              Drag 'n' drop a file here, or click to select a
                              file
                            </p>
                          </Box>

                          {file && (
                            <Box className="filePath">
                              <span>{file.name}</span>
                              <Button
                                className="button icon"
                                startIcon={<Close />}
                                onClick={removeFile}
                              ></Button>
                            </Box>
                          )}

                          {file && Object.keys(file)?.length != 0 && (
                            <Button
                              className="button"
                              variant="filled"
                              onClick={handleUploadFileModal}
                            >
                              Next
                              {/* Upload asset */}
                            </Button>
                          )}
                        </>
                      )}
                    </Dropzone>
                  </Box>
                </>
              ) : null}
              {block === "WebUrl" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "1em 1em",
                    width: "100%",
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <input
                      type="text"
                      className="textbox"
                      // value={email}
                      placeholder="Enter url to upload form"
                      name="webUrl"
                      // onChange={handleEmailChange}
                      {...register("webUrl")}
                    ></input>
                    {errors?.webUrl && (
                      <ErrorMessageStyled>
                        {errors.webUrl?.message}
                      </ErrorMessageStyled>
                    )}

                    <Button className="button" type="submit" variant="filled">
                      Next
                    </Button>
                  </form>
                </Box>
              ) : null}
              {block === "UploadZip" ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "1em",
                      cursor: "pointer",
                    }}
                  >
                    <Dropzone
                      // accept={{
                      //   "application/zip": [".zip"],
                      // }}
                      // accept={acceptedFileTypes}

                      onDrop={onZipfileDrop}
                      maxFiles={1}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <>
                          <Box
                            {...getRootProps()}
                            // style={dropzoneStyle}
                            sx={{
                              border: "1px dashed var(--border)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "1em 0",
                              width: "100%",
                              minHeight: "300px",
                              boxSizing: "border-box",
                            }}
                          >
                            <input
                              {...getInputProps({ accept: acceptedFileTypes })}
                            />
                            <p>
                              Drag 'n' drop a file here, or click to select a
                              Zip file
                            </p>
                          </Box>

                          {zipFile && (
                            <Box className="filePath">
                              <span>{zipFile?.name}</span>
                              <Button
                                className="button icon"
                                startIcon={<Close />}
                                onClick={removeFile}
                              ></Button>
                            </Box>
                          )}
                        </>
                      )}
                    </Dropzone>

                    {zipFile && Object.keys(zipFile)?.length != 0 && (
                      <Button
                        className="button"
                        variant="filled"
                        onClick={handleUpload}
                      >
                        Upload Zip
                      </Button>
                    )}
                  </Box>
                </>
              ) : null}
            </Grid>
            <Grid item xs={5} className="mL">
              <Box className="headinAlign inner justifyEnd">
                <Typography component="h5" variant="h5">
                  <Folder /> {searchParams?.get("folderLabel")}
                </Typography>

                {/* <Typography component="h2" variant="h5">Share asset</Typography> */}
              </Box>
              <FormGroup
                sx={{ padding: "1em 2em", marginTop: "var(--sideSet)" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="File"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="File"
                      control={<Radio />}
                      label="File"
                      className="uploadAsset"
                      checked={block === "File"}
                      onClick={() => setBlock("File")}
                    />
                    <FormControlLabel
                      value="WebUrl"
                      control={<Radio />}
                      label="Web Url"
                      className="uploadAsset"
                      checked={block === "WebUrl"}
                      onClick={() => setBlock("WebUrl")}
                    />
                    <FormControlLabel
                      value="UploadZip"
                      control={<Radio />}
                      label="Upload Zip"
                      className="uploadAsset"
                      checked={block === "UploadZip"}
                      onClick={() => setBlock("UploadZip")}
                    />
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default UploadAssetsPage;
