
import axios from "axios";
import apiEndpoints from "../api/apiEndpoints";
import { API_URL } from "../config/index";
import { AUTH_TOKEN_REACT, getAuthToken } from "../config/constants";
const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Methods': 'POST',
  'Access-Control-Allow-Headers': 'Content-Type',
  "Authorization":getAuthToken(),
  // "Authorization":AUTH_TOKEN_REACT,
}
export async function fetchPosts() {
  const response = await fetch(`${API_URL}${apiEndpoints.GET_FEATURED_PRODUCTS}`);
  return response.json()
}

export async function loginToken(data) {
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.GET_LOGIN_TOKEN}`, data, { headers: headers })
}




export async function downloadSingleAsset(param) {
  const headers = {
    // "Authorization":AUTH_TOKEN_REACT,

    // 'Authorization': `Bearer ${param.token}`,
    "Authorization":getAuthToken(),
  }
  return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.DOWNLOAD_SINGLE_ASSET}/${param}`, { headers: headers })
}
export async function getHeaderListFilters(param) {
  const headers = {
    // "Authorization":AUTH_TOKEN_REACT,
    // 'Authorization': `Bearer ${param.token}`,
    "Authorization":getAuthToken(),
  }
  return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.GET_HEADER_LIST_FILTERS}`, { headers: headers })
}
export async function getCollectionList(param) {
  const headers = {
    // "Authorization":AUTH_TOKEN_REACT,
    // 'Authorization': `Bearer ${param.token}`,
    'Content-Type': 'multipart/form-data',
    "Authorization":getAuthToken(),
  }
  return axios.post(`${apiEndpoints.API_URL}${apiEndpoints.GET_COLLECTION_LIST}`,param, { headers: headers })
}
export async function deleteCollectionList(param) {
  const headers = {
    // "Authorization":AUTH_TOKEN_REACT,
    // 'Authorization': `Bearer ${param.token}`,
    "Authorization":getAuthToken(),
  }
  return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.DELETE_COLLECTION_LIST}/${param}`, { headers: headers })
}




export async function getPartList(param) {
    const headers = {
      "Authorization":getAuthToken(),
    }
    return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.GET_PART_LIST}?dealerId=${param.dealerId}&categoryId=${param.categoryId}&subCategoryId=${param.subCategoryId}&keyword=${param.keyword}&engineId=${param.engineId}`, { headers: headers })
  }
  
  export async function getMyWishList(param) {
    const headers = {
      "Authorization":getAuthToken(),
    }
    return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.GET_WISH_LIST}?dealerId=${param.dealerId}`, { headers: headers })
  }

  export async function getCartOption(){
    const headers = {
      // "Authorization":AUTH_TOKEN_REACT,
      // 'Authorization': `Bearer ${param.token}`,
      "Authorization":getAuthToken(),
    }
    return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.CART_OPTIONS}`, { headers: headers })
  }

  export async function viewProfile(){
    const headers = {
      // "Authorization":AUTH_TOKEN_REACT,
      // 'Authorization': `Bearer ${param.token}`,
      "Authorization":getAuthToken()
    }
    return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.VIEW_PROFILE}`, { headers: headers })
  }
  export async function getPermissions(){
    const headers = {
      // "Authorization":AUTH_TOKEN_REACT,
      // 'Authorization': `Bearer ${param.token}`,
      "Authorization":getAuthToken()
    }
    return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.GET_PERMISSIONS}`, { headers: headers })
  }
  

  export async function getDashboardData(){
    const headers = {
      // "Authorization":AUTH_TOKEN_REACT,
      // 'Authorization': `Bearer ${param.token}`,
      "Authorization":getAuthToken()
    }
    return axios.get(`${apiEndpoints.API_URL}${apiEndpoints.GET_DASHBOARD_API}`, { headers: headers })
  }
  


  