export const AUTH_TOKEN_REACT=localStorage.getItem('token')


export const getAuthToken = () => {
    return localStorage.getItem('token');
  };
  export const inputOptions = [
    { label: "Input", value: "in" },
    { label: "Checkbox", value: "checkbox" },
    { label: "TextArea", value: "textarea" },
    { label: "Date", value: "date" },
  ];