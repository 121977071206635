const commonMessages = {
  assetMetadataCheckBoxMessage:
    "Please select at least one checkbox and ensure corresponding dropdown value is selected.",
  damFeUrl: "https://dam-api-frontend.pimcoredemos.com/",

  localUrl: "https://localhost:3000/",

  collectionShareHistoryHeading: "Collection share history",
  publicShareHistoryHeading: "Public share history",
  addToCollection: "Add to collection",
  addToCart: "Add to cart",

  createNewCollection: "Create new collection",
  renameCollection: "Rename collection",

  batchMetdataUpdate: "Batch metadata update",

  uSureWannaDeleteThisCollection:
    "Are you sure you want to delete this collection?",

  uSureWannaDeleteThisFolder: "Are you sure you want to delete this folder?",

  uSureWannaDeleteThisAsset: "Are you sure you want to delete this asset?",
  uSureWannaDelete: "Are you sure you want to delete?",

  maxCharHundred: "Max characters 100",
  maxCharFivehundred: "Max characters 500",

  applyingFilterInstructionMsg:
    "For applying a filter or saving a filter, at least one of the fields must be selected.",

  batchMetdataUpdateInstructionMsg:
    "For metadata updates, one of the options must be selected.",

  namefieldErrorMsg: "Name field cannot be empty",
  filterInstructionMsg: "Please select a saved filter or custom filter",

  selectCheckboxestoCompareVersion :"Select atleast two checkboxes to compare versions",
  noCollectionPresent :"No collection present . Create new collection from collection listing page",
  clearCart:"Clear cart"
};
  
  export default commonMessages;
  