import React, { useEffect, useState } from 'react'
import DamHeader from '../components/DamHeader';
import { Box, Grid , Typography,Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox} from '@mui/material';
import Footer from '../components/Footer';
import LeftMenu from '../components/LeftMenu';
import { publicShareCollectionList, publicShareHistoryList, publicSharePSDelete } from '../api/postRequest';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {ReactComponent as ChevronBack} from '../assest/svg/chevronLeft.svg';
import {ReactComponent as Edit} from '../assest/svg/editicon.svg';

import {ReactComponent as Delete} from '../assest/svg/deleteicon.svg';
import Loader from '../components/Loader';
import { TooltipStyle } from '../components/RightSectionImages';
import DamPortalModal from '../components/DamPortalModal';
import { showSuccessToast } from '../assest/Toaster';
import commonMessages from '../utils/commomMessages';
import PaginationComponent from '../components/PaginationComponent';
import { useNonPersistedStore } from '../store/nonPersistedStore';
import { ReactComponent as DownArrowDesc } from "../assest/svg/sortDown.svg";
import { ReactComponent as UpArrowAsc } from "../assest/svg/sortUp.svg";

const PublicShareHistoryPage = () => {
    let publicShareParamsId = useParams();
   const [collecionData,setCollectionData]= useState([])
   const [isChecked, setIsChecked] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
   const [errorMessagePublicShare, setErrorMessagePublicShare] = useState(null);

   
   const [isDeletePSHistoryModal, setIsDeletePSHistoryModal] =
   useState(false);
   const [deletePSHistoryRowDetail, setDeletePSHistoryRowDetail] =
   useState(null);
   const [isEditPSHistoryModal, setIsEditPSHistoryModal] =
   useState(false);
   const [classSort, setClassSort] = useState();
    const navigate = useNavigate();
    const pageSize = 12; //
    const {
      setPublicShareHistoryPageTotalCountZusNon,
      publicShareHistoryPageTotalCountZusNon,publicShareHistoryListingZusNon,
      setPublicShareHistoryListingZusNon
    } = useNonPersistedStore();
    const publicShareHistoryMutation = useMutation(
      (post) => publicShareHistoryList(post),
      {
        onSuccess: (result) => {
          if (result) {
            if (result?.data?.success) {
              setPublicShareHistoryListingZusNon(result?.data?.data?.items);
              setCollectionData(result?.data?.data?.items);
              setPublicShareHistoryPageTotalCountZusNon(
                result?.data?.data?.totalResults
              );
            } else {
              setPublicShareHistoryListingZusNon([]);
              setErrorMessagePublicShare(result?.data?.message)
            }
          }
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );

    const publicSharePSDeleteMutation = useMutation(
      (post) => publicSharePSDelete(post),
      {
        onSuccess: (result) => {
          if (result) {
            if (result?.data?.success) {
              showSuccessToast(result?.data?.message);
              handleCloseModal();
              let payload = {
                currentPage: currentPage,
                showExpired: isChecked,
              };

              publicShareHistoryMutation.mutate(JSON.stringify(payload));
            } else {
            }
          }
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );

    useEffect(() => {
      let payload = {
        currentPage: currentPage,
      };

      publicShareHistoryMutation.mutate(JSON.stringify(payload));
    }, []);





  const handleNavigationBack = () => {
   
    navigate(-1);
  };
  const handleDeletePSHistoryModal = (item) => {
   
    setIsDeletePSHistoryModal(true)
    setDeletePSHistoryRowDetail(item)
  };
  const handleCloseModal = () => {
    setIsEditPSHistoryModal(false);
    setIsDeletePSHistoryModal(false);
  };

  const handlePageChange = (newPage) => {
    if (newPage === 0) {
      return;
    } else {
      setCurrentPage(newPage);

      let payload = {
        currentPage: newPage,
      };
      publicShareHistoryMutation.mutate(JSON.stringify(payload));
    }
  };

  const handleCallbackDeletePSHistory = (rowDetail) => {

   
    let payload ={
      shareId:[rowDetail?.id]

    }
    if(rowDetail?.id){
      publicSharePSDeleteMutation.mutate(payload)
    }
  
  };
  let headings = publicShareHistoryListingZusNon?.length > 0 ? Object.keys(publicShareHistoryListingZusNon[0]) : [];
  let keysToRemove =["configascac","id"]

  headings =headings?.filter(item =>!keysToRemove.includes(item))

  const handleConfigs = (row, configs) => {
    if (configs?.length > 1) {
      return configs?.map((item, idx) => {
        return (
          <Box>
            
            {configs[idx]?.type === "structuredData"
              ? "Metadata"
              : "Asset"}: {configs[idx]?.format}
            {idx !== configs?.length - 1 && ""}
          </Box>
        );
      });
    } else {
      return (
        <Box>
          {configs[0]?.type === "structuredData" ? "Metadata" : "Asset"}: {configs[0]?.format}
        </Box>
      );
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); 
    
  
  };
  const handleAscSort=(val)=>{
   
    if(val=="Name")
    {
      setClassSort("nameASC");
      let payload = {
        sortBy:"name",
        sortOrder:"asc",
        
        showExpired:isChecked
      };
      publicShareHistoryMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //  cloneCollection?.sort((a,b)=>a.Name.localeCompare(b.Name))
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
    if(val=="Asset count")
    {
      setClassSort("countASC");
      let payload = {
        sortBy:"assetIds",
        sortOrder:"asc",
        
        showExpired:isChecked
      };
      publicShareHistoryMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //  cloneCollection?.sort((a,b)=>a?.["Asset count"]-b?.["Asset count"])
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
    if (val == "Expiry date") {
      setClassSort("expASC");
      let payload = {
        sortBy:"expiryDate",
        sortOrder:"asc",
        
        showExpired:isChecked
      };
      publicShareHistoryMutation.mutate(JSON.stringify(payload));
      // let cloneCollection = publicShareCollectionHistoryListingZusNon;
      // cloneCollection?.sort(
      //   (a, b) => new Date(a?.["Expiry date"]) - new Date(b?.["Expiry date"])
      // );
      // setPublicShareCollectionHistoryListingZusNon(cloneCollection);
    }
    if (val == "Shared on") {
      setClassSort("sharedASC");
      let payload = {
        sortBy:"sharedOn",
        sortOrder:"asc",
        
        showExpired:isChecked
      };
      publicShareHistoryMutation.mutate(JSON.stringify(payload));
      // let cloneCollection = publicShareCollectionHistoryListingZusNon;
      // cloneCollection?.sort(
      //   (a, b) => new Date(a?.["Shared on"]) - new Date(b?.["Shared on"])
      // );
      // setPublicShareCollectionHistoryListingZusNon(cloneCollection);
    }

   
  }
  
  const handleDescSort=(val)=>{
    if(val=="Name")
    {
      setClassSort("nameDSC");
      let payload = {
        sortBy:"name",
        sortOrder:"desc",
        
        showExpired:isChecked
      };
      publicShareHistoryMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //  cloneCollection?.sort((a,b)=>b.Name.localeCompare(a.Name))
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
    if(val=="Asset count")
    {
      setClassSort("countDSC");
      let payload = {
        sortBy:"assetIds",
        sortOrder:"desc",
        
        showExpired:isChecked
      };
       publicShareHistoryMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //  cloneCollection?.sort((a,b)=>b?.["Asset count"]-a?.["Asset count"])
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
    if(val=="Expiry date")
    {
      setClassSort("expDSC");
      let payload = {
        sortBy:"expiryDate",
        sortOrder:"desc",
        
        showExpired:isChecked
      };
      publicShareHistoryMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //   cloneCollection?.sort((a,b)=>new Date(b?.["Expiry date"])-new Date(a?.["Expiry date"]))
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
    if(val=="Shared on")
    {
      setClassSort("sharedDSC");
      let payload = {
        sortBy:"sharedOn",
        sortOrder:"desc",
        
        showExpired:isChecked
      };
      publicShareHistoryMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //  cloneCollection?.sort((a,b)=>new Date(b?.["Shared on"])-new Date(a?.["Shared on"]))
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
  }

  useEffect(() => {
    let payload = {
      currentPage: currentPage,
      showExpired:isChecked
    };
    publicShareHistoryMutation.mutate(JSON.stringify(payload));
  
  
   
  }, [isChecked])
  



    return (
      <>
        {publicShareHistoryMutation?.isLoading && <Loader></Loader>}
        <Box>
          <DamPortalModal
            open={isDeletePSHistoryModal}
            onClose={handleCloseModal}
            titleMessage={commonMessages?.uSureWannaDelete}
            isDeletePSHistoryModal={isDeletePSHistoryModal}
            publicSharePSDeleteMutation={publicSharePSDeleteMutation}
            deletePSHistoryRowDetail={deletePSHistoryRowDetail}
            handleCallbackDeletePSHistory={handleCallbackDeletePSHistory}
          />
        </Box>

        <Grid container className="container bg">
          <Grid item xs={1} className="menu">
            <LeftMenu />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ minHeight: "calc(100% - 50px)" }}
            className="rightPart"
          >
            <DamHeader />

            <Grid container className=" bodyPart">
              <Box className="selector ">
                <Box className="breadcrumb">
                  <Typography component="h2" variant="h5" className="heading">

                 {commonMessages?.publicShareHistoryHeading}
                    
                  </Typography>
                </Box>
               

              
                <Box className="buttonGroup">

                <Box className="checkbox">
                <Checkbox
                  checked={isChecked}
                  name="Show expired"
                  onChange={handleCheckboxChange}
                />
                <Typography component="label">Show expired</Typography>
              </Box>
                  <Button
                    size="small"
                    startIcon={<ChevronBack />}
                    className="button"
                    onClick={handleNavigationBack}
                  >
                    Back
                  </Button>
                </Box>
              </Box>

              {!publicShareHistoryMutation.isLoading ? (publicShareHistoryListingZusNon &&  publicShareHistoryListingZusNon?.length >0 ? (
                <TableContainer sx={{ padding: "1em", marginTop: "70px" }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="table fixed"
                  >
                    <TableHead>
                      <TableRow>
                        {headings?.map((heading, index) => (
                          <TableCell key={index}>{ heading === "Config" ?<>Download config</>  : heading =="Name"? <Box className="flex">{heading} <Box className="flex paddingL-sm arrow">
                          <UpArrowAsc
                            className={
                              classSort == "nameASC" ? "active" : ""
                            }
                            height={20}
                            onClick={() => handleAscSort(heading)}
                          />
                          <DownArrowDesc
                            className={
                              classSort == "nameDSC" ? "active" : ""
                            }
                            height={20}
                            onClick={() => handleDescSort(heading)}
                          />
                        </Box></Box> :heading =="Asset count"?  <Box className="flex">{heading} <Box className="flex paddingL-sm arrow">
                          <UpArrowAsc
                            className={
                              classSort == "countASC" ? "active" : ""
                            }
                            height={20}
                            onClick={() => handleAscSort(heading)}
                          />
                          <DownArrowDesc
                            className={
                              classSort == "countDSC" ? "active" : ""
                            }
                            height={20}
                            onClick={() => handleDescSort(heading)}
                          />
                        </Box></Box>:heading =="Expiry date"? <Box className="flex">{heading} <Box className="flex paddingL-sm arrow">
                          <UpArrowAsc
                            className={
                              classSort == "expASC" ? "active" : ""
                            }
                            height={20}
                            onClick={() => handleAscSort(heading)}
                          />
                          <DownArrowDesc
                            className={
                              classSort == "expDSC" ? "active" : ""
                            }
                            height={20}
                            onClick={() => handleDescSort(heading)}
                          />
                        </Box></Box> :heading =="Shared on"? <Box className="flex">{heading} <Box className="flex paddingL-sm arrow">
                          <UpArrowAsc
                            className={
                              classSort == "sharedASC" ? "active" : ""
                            }
                            height={20}
                            onClick={() => handleAscSort(heading)}
                          />
                          <DownArrowDesc
                            className={
                              classSort == "sharedDSC" ? "active" : ""
                            }
                            height={20}
                            onClick={() => handleDescSort(heading)}
                          />
                        </Box></Box> :heading }</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& .button": {
                          border: 0,
                          margin: "2px !important",
                        },
                        "& img": {
                          height: "60px",
                          maxWidth: "60px",
                          objectFit: "cover",
                        },
                      }}
                    >
                      {publicShareHistoryListingZusNon?.map((item, index) => (
                        <TableRow key={index}>
                          {headings?.map((heading, index) => (
                            <TableCell key={index}>
                              { heading === "Config" ? (
                                <> {handleConfigs(item, item?.Config)}</>
                              ) : heading === "URL" ? (
                                <>
                                  <a
                                  className={`button link ${new Date() > new Date(item['Expiry date']) ? "disabledLink" : ""}`}
                                    href={item[heading]?.replace(
                                      /https:\/\/dam-api\.pimcoredemos\.com\//,
                                       commonMessages?.damFeUrl

                                      //  commonMessages?.localUrl
                                    )}
                                    target="_blank"
                                  >
                                    {" "}
                                    {item[heading]?.replace(
                                      /https:\/\/dam-api\.pimcoredemos\.com\//,
                                      commonMessages?.damFeUrl
                                      //  commonMessages?.localUrl
                                    )}
                                  </a>
                                </>
                              ) : heading === "Action" ? (
                                <>
                                  <Box className="buttonGroup">
                                 
                                    <TooltipStyle
                                      placement="bottom"
                                      arrow
                                      title="Delete"
                                    >
                                      <Button
                                        size="small"
                                        className="button icon"
                                        onClick={() =>
                                          handleDeletePSHistoryModal(item)
                                        }
                                      >
                                        <Delete />
                                      </Button>
                                    </TooltipStyle>
                                  </Box>
                                </>
                              ) : (
                                <>{item[heading]}
                                  </>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Box
                    sx={{
                      textAlign: "right",
                      padding: "2em 0",
                      marginBottom: "20px",
                      position: "relative",
                    }}
                    className="paginationWrapper"
                  >
                    <PaginationComponent
                      currentPage={currentPage}
                      totalCount={publicShareHistoryPageTotalCountZusNon}
                      pageSize={pageSize}
                      onPageChange={handlePageChange}
                      itemCount={publicShareHistoryListingZusNon?.length || 0}
                    />
                  </Box>
                </TableContainer>
              ) : (
                <><Box className="notificationWrapper col-12" sx={{marginTop: "var(--sideSetHeight)"}}>
                  <Box className="notification">{errorMessagePublicShare}</Box></Box></>
              ) ): ""}
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </>
    );
}

export default PublicShareHistoryPage