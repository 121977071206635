


import { Box, Grid ,Divider, Typography, Button, Radio, TableContainer, Table, TableBody, TableRow, TableCell, TableHead} from "@mui/material";


import LeftMenu from "../components/LeftMenu";
import DamHeader from "../components/DamHeader";
import Selector from "../components/Selector";
import Footer from "../components/Footer";
import {ReactComponent as ManageFolder} from '../assest/svg/ManageFolder.svg';
import {ReactComponent as LeftArrow} from '../assest/svg/arrowLeft.svg';
import {ReactComponent as RightArrow} from '../assest/svg/arrowRight.svg';
import {ReactComponent as Folder} from '../assest/svg/folderStructure.svg';
import { compareVersions } from "../api/postRequest";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";



const CompareVersionPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [versionIdFromParam, setVersionIdFromParam] = useState();
  const [versionApiData, setVersionApiData] = useState();


  useEffect(() => {
    setVersionIdFromParam(searchParams.get("id"));
  }, []);

  const mutation = useMutation((post) => compareVersions(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          setVersionApiData(result.data.data);
       
        } else {
        }
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if(versionIdFromParam){
      mutation.mutate({ versionIds: versionIdFromParam });
    }
   
  }, [versionIdFromParam]);
  const tableHeadings = versionApiData && Object.keys(versionApiData);

  // Extract all unique property keys dynamically
  const allPropertyKeys = versionApiData && Array.from(
    new Set(tableHeadings.flatMap((key) => Object.keys(versionApiData[key])))
  );
  const handleNavigation = () => {
   
    navigate(-1);
  };
  const handleNavigationMain = () => {
    navigate({
      pathname: "/",
    });
    
  };
  

  return (
    <Grid container className="container">
      <Grid item xs={1} className="menu">
        <LeftMenu />
      </Grid>

      <Grid
        item
        xs={11}
        sx={{ minHeight: "calc(100% - 50px)" }}
        className="rightPart"
      >
        <DamHeader />

      
        <Grid container className=" bodyPart">
          <Box className="selector">
            <Box className="breadcrumb">
              Files <RightArrow />{" "}
              <Typography component="b">Compare</Typography>
            </Box>
            <Box className="buttonGroup">
              <Button
                size="small"
                startIcon={<LeftArrow />}
                className="button"
                onClick={handleNavigation}
              >
                Back
              </Button>
            </Box>
          </Box>

          <Grid container sx={{ padding: "1em 0 1em 1em ", marginTop: "60px" }}>
            {versionApiData && (
              <table className="compareTable table">
                <thead>
                  <tr>
                    <th>Keys</th>
                    {tableHeadings &&
                      tableHeadings?.map((heading, index) => (
                        <th key={heading}>
                          Version{" "}
                          <Box
                            component="span"
                            sx={{ display: "inline", marginRight: "3px" }}
                          >
                            {index + 1}
                          </Box>
                          ({heading})
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {allPropertyKeys &&
                    allPropertyKeys?.map((property) => (
                      <tr key={property}>
                        <th>{property}</th>
                        {tableHeadings &&
                          tableHeadings?.map((heading) => (
                            <>
                              <td key={heading}>
                                {versionApiData[heading][property]}
                                {property === "fullPath" && (
                                  <img
                                    src={`${"http://"}${"dam-api.pimcoredemos.com"}${
                                      versionApiData[heading][property]
                                    }`}
                                    // title="green iguana"
                                  />
                                )}
                              </td>
                            </>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default CompareVersionPage;