// PasswordField.jsx
import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';

import { ReactComponent as Visibility } from '../assest/svg/eye.svg';
import { ReactComponent as VisibilityOff } from '../assest/svg/eyeoff.svg';

const PasswordField = ({ value, onChange, showPassword, toggleShowPassword }) => (
  <TextField
    label=""
    inputProps={{ maxLength: 50 }} // Set the maxLength here
    type={showPassword ? "text" : "password"}
    value={value}
    onChange={onChange}
    className="textbox"
    variant="standard"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={toggleShowPassword}
            edge="end"
            className='icon-btn'
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

export default PasswordField;