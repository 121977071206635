
import { Box, Button, Checkbox, Typography, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, TextField, TextareaAutosize, Tooltip, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Select} from "@mui/material";
import LeftMenu from "../components/LeftMenu";
import DamHeader from "../components/DamHeader";
import SelectorDetail from "../components/SelectorDetail";
import Footer from "../components/Footer";
import DatePicker from "react-datepicker";
import DetailedDescription from "../components/DetailedDescription";
import DetailedImage from "../components/DetailedImage";
import {ReactComponent as Share} from '../assest/svg/shareicon.svg';
import {ReactComponent as ChevronBack} from '../assest/svg/chevronLeft.svg';
import {ReactComponent as ChevronNext} from '../assest/svg/chevronRight.svg';
import { ReactComponent as Delete } from "../assest/svg/deleteicon.svg";
import {withStyles} from "@material-ui/core/styles";
import { useStore } from "../store/persistedStore";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { publicShareApi } from "../api/postRequest";
import {ReactComponent as Cross} from '../assest/svg/cross.svg';

import CustomMoment from 'moment-timezone';
import moment from "moment";

import { default as ReactSelect } from "react-select";
import { getCartOption } from "../api/getRequest";
import DamPortalModal from "../components/DamPortalModal";
import Loader from "../components/Loader";
import { showErrorToast, showErrorToast1 } from "../assest/Toaster";
import commonMessages from "../utils/commomMessages";

const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);


const ShareAssetPage = () => {
  const [deleteId, setDeleteId] = useState();
  const [collectionDeleteModal, setCollectionDeleteModal] = useState(false);

  const {
    setMainPageListingArr,
    mainPageListingArr,
    setTotalCountZustand,
    totalCountZustand,
  
  } = useStore();
  const location = useLocation();
  const [locationState, setLocationState] = useState(location?.state);
 const [inputValueName,setInputValueName]= useState()
 const [shareCopyToClipboardModal,setShareCopyToClipboardModal]= useState(false)
 
 const [inputCommentText,setInputCommentText]= useState()
 const [structuredDataOptions, setStructuredDataOptions] = useState();
 const [multipleShareCount, setMultipleShareCount] = useState( mainPageListingArr?.reduce((acc, obj) => obj.isChecked ? acc + 1 : acc, 0));
 
 const [assetOptions, setAssetOptions] = useState();
 const [customOptions, setCustomOptions] = useState();
 const [requireChecBox, setRequireChecBox] = useState(false);
 
 const [customData, setCustomData] = useState({
  format: "",
  height: "",
  width: "",
  quality: "",
});
 const [structuredData, setStructuredData] = useState({
  type: "",
  localized: false,
  attribute: null,
  format: "",
  setup: null,
});
const [asset, setAsset] = useState({
  type: "",
  localized: false,
  attribute: null,
  format: "",
  setup: null,
});


  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [linkPass, setLinkPass] = useState("");

  
  useEffect(() => {
    const selectedItems = mainPageListingArr?.filter(obj => (obj?.isChecked==true));
  
   
  }, [])
  
  const mutationGetCartOptions = useMutation((post) => getCartOption(post), {
    mutationKey: "getCartOption",
    method: "GET",
    onSuccess: (result) => {
      if (result?.data[0]?.data?.length) {
        let opt1 = result?.data[0]?.data[0]?.formats.map((value) => {
          return { label: value?.label, value: value?.label };
        });
        setStructuredDataOptions(opt1);

        let opt2 = result?.data[0]?.data[1]?.formats.map((value) => {
          return { label: value?.label, value: value?.id };
        });
        setAssetOptions(opt2);
        let res = result?.data[0]?.data[1]?.formats.find(
          (val) => val?.id === "custom"
        );
        setCustomOptions(res);
        setCustomData({
          ...customData,
          quality: res?.setup?.quality?.defaultValue,
          format: res?.setup?.format?.options[0]?.value,
        });
      }
    },
    onError: (error) => {},
  });
  const publicShareMutation = useMutation((post) => publicShareApi(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          setLinkPass(result?.data?.url)
          setShareCopyToClipboardModal(true)
        } else {
          showErrorToast(result.data.message)
        }
      }
    },
    onError: (error) => { console.log(error)},
  });

  useEffect(() => {
    mutationGetCartOptions.mutate();
  }, []);

  function getIdsAndParents(objects) {
    let result = objects
      .filter((obj) => obj.isChecked === true)
      .map(({ id, parent }) => ({ id, parent }));
    

  let output=   result?.map((item) => {
      return {
        dataPoolId: item?.parent,
        selectedId: item?.id,
      };
    });
    return output
  }
  const handleNavigationBack = () => {
    navigate(-1);
  };
  const handleShareBTnClicked = (structuredData, assetData, customData) => {
   
  

    if(assetData?.type?.length === 0 &&  structuredData?.type?.length === 0 ){

      showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);

      return;
    }
     else if (
        (!structuredData.type &&
          !assetData.format) ||
        (structuredData.type &&
          !structuredData.format) ||
        (assetData.type && !assetData.format)
      ) {
        // Handle validation error, display message to user or prevent API call
       
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
        
        return;
      }
      
    
   
    
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    if (searchParams?.size > 0) {
      const idParentIdPairs = getIdsAndParents(mainPageListingArr);
      let payload = {
        assets: idParentIdPairs,

        
       
       
        expiryDate:CustomMoment(selectedDate)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD"),
        name: inputValueName,
        termText: inputCommentText ?? null,
        config: configArr,
        userId:2,
        _locale:"en",
        showTermText:requireChecBox
      };
      publicShareMutation.mutate(payload);
    } else {
      let payload = {
        assets: [
          {
            dataPoolId: locationState?.parent,
            selectedId: locationState?.id,
          },
        ],
        
        expiryDate:CustomMoment(selectedDate)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD"),

     
        name: inputValueName,
        termText: inputCommentText ??  null,
        config: configArr,
        userId:2,
        _locale:"en",
        showTermText:requireChecBox
      };

      publicShareMutation.mutate(payload);
    }
  };

  const handleNameInputChange = (params) => {
  
    params?.length<=100 &&  setInputValueName(params)
  };

  const handleCommentInputChange = (params) => {
  
    params?.length <=500 &&   setInputCommentText(params)
  };
  
 

  const handleDateChange = date => {
    setSelectedDate(date);
  };
  const handleChangeFormat = (selectedValue, type) => {
    if (type === "structuredData") {
      setStructuredData({ ...structuredData, format: selectedValue?.value });
    }
    if (type === "asset") {
      setAsset({ ...asset, format: selectedValue?.value });
    }
  };

  const handleChangeCartConfigType = (type) => {
    if (type === "structuredData") {
      !structuredData?.type
        ? setStructuredData({ ...structuredData, type: "structuredData" })
        : setStructuredData({ ...structuredData, type: "" });
    }
    if (type === "asset") {
      !asset?.type
        ? setAsset({ ...asset, type: "asset" })
        : setAsset({ ...asset, type: "" });
    }
  };
  const handleDeleteAsset = (id) => {
    // setIsDeleteModal(true);
    
    // if (multipleShareCount > 1) {
      if (searchParams?.size > 0) {
      setDeleteId(id);

      let arr = mainPageListingArr;
      let a = arr.findIndex((y) => y.id === id);
      arr.splice(a, 1);

      setMainPageListingArr(arr);
      const count = arr?.reduce(
        (acc, obj) => (obj.isChecked ? acc + 1 : acc),
        0
      );

      // Output the count
      
      setMultipleShareCount(count);
    // }
      } else{
        
       setLocationState("delete")

       
      }
  };
 
  const tomorrow = moment().add(1, 'day');

 
  const handleChangeCustom = (value, field) => {
 
    if (field === "format") {
      setCustomData({ ...customData, [field]: value?.value });
    } else if (field === "quality" ) {
      
      setCustomData({ ...customData, [field]: value >100 ? 100 : value <0 ? 1: value });
      //setCustomData({ ...customData, [field]:  value <0 ? 1: value });
    }else if (field === "width" || field === "height")
    {
      const isValidNumeric = !isNaN(value) && (value<=8000)
    
      setCustomData({ ...customData, [field]: isValidNumeric ? value : customData[field] });
    } 
    else{
      setCustomData({ ...customData, [field]: value });
    }
  };

  const handleKeyPress = (event) => {
    const key = event.key;
    // Prevent entering non-numeric characters except backspace and delete
    if (!/[\d]/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Tab') {
      event.preventDefault();
    }
  };

  const handleCheckboXRequireChange = (value) => {
    setRequireChecBox(value);
  };
  const handleCloseModal = () => {
    setShareCopyToClipboardModal(false);
    
  };


  const handleCallbackPublicShare = (structuredData, assetData, customData) => {
   
  };

  const isValidAssetMetadata = () => {
    if (
      !inputValueName ||
      !selectedDate ||
      locationState === "delete" ||
      (!multipleShareCount && searchParams?.size > 0)
    ) {
      return false;
    }

    if (!asset.type && !structuredData.type) {
      return false;
    }
    if (
      (asset.type && !asset.format) ||
      (structuredData.type && !structuredData.format)
    ) {
      return false;
    }
    return true;
  };


  
  return (
    <>
      {publicShareMutation?.isLoading && <Loader></Loader>}

      <Grid container className="container bg ">
        <Box>
          <DamPortalModal
            open={shareCopyToClipboardModal}
            shareCopyToClipboardModalFlag={shareCopyToClipboardModal}
            onClose={handleCloseModal}
            titleMessage={"Public share"}
            linkPass={linkPass}
            handleCallbackPublicShare={handleCallbackPublicShare}
          />
        </Box>
        <Grid item className="menu">
          <LeftMenu />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ minHeight: "calc(100% - 50px)" }}
          className="rightPart"
        >
          <DamHeader />
          <Box className="bodyPart boxContainer">
            <Box className="assetDetailLeft1 items">
              <Box className="headinAlign">
                <Typography component="h2" variant="h5" className="heading">
                  Share assets
                </Typography>
                <Box className="buttonGroup">
                  <Button
                    onClick={handleNavigationBack}
                    size="small"
                    startIcon={<ChevronBack />}
                    className="button"
                  >
                    Back
                  </Button>
                </Box>
              </Box>
              {/* <DetailedImage /> */}
              {!multipleShareCount && searchParams?.size > 0 ? (
                <Box className="notification">No asset to share.</Box>
              ) : locationState === "delete" ? (
                <Box className="notification">No asset to share.</Box>
              ) : (
                <TableContainer sx={{ padding: "1em" }}>
                  <Typography component="p" mb={2}>
                    {/* Total {searchParams?.size < 2 ? "1" : searchParams?.size} assets
                selected to share */}
                    {searchParams?.size > 0
                      ? `Total number of assets in this share list : ${multipleShareCount} `
                      : `Total number of asset in this share list  : 1 `}
                  </Typography>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Mime type</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& .button": {
                          border: 0,
                          margin: "2px !important",
                        },
                        "& img": {
                          height: "60px",
                          maxWidth: "60px",
                          objectFit: "cover",
                        },
                      }}
                    >
                      {searchParams?.size > 0 ? (
                        mainPageListingArr
                          ?.filter((obj) => obj?.isChecked == true)
                          ?.map((imgObj, i) => (
                            <TableRow key={i}>
                              {/* <TableCell>
                              <Checkbox />
                            </TableCell> */}
                              <TableCell key={imgObj?.id}>
                                <TooltipStyle
                                  title={`${imgObj?.name}`}
                                  placement="bottom"
                                  arrow
                                >
                                  <img
                                    src={`${"https://"}${"dam-api.pimcoredemos.com"}${
                                      imgObj?.thumbnail
                                    }`}
                                    // src={`${"http://"}${"dam-api.pimcoredemos.com"}${
                                    //   imgObj?.path
                                    // }${imgObj?.name}`}
                                  />
                                </TooltipStyle>
                                {/* <img src={imgObj?.path} title="green iguana" /> */}
                              </TableCell>
                              <TableCell>{imgObj?.displayName}</TableCell>
                              <TableCell>
                                <Typography variant="p">
                                  {imgObj?.mimetype}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title="Remove asset from share"
                                >
                                  <Button
                                    size="small"
                                    className="button icon"
                                    onClick={() => {
                                      handleDeleteAsset(imgObj?.id);
                                    }}
                                  >
                                    <Cross />
                                    {/* <Delete /> */}
                                  </Button>
                                </TooltipStyle>
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <>
                          <TableRow>
                            {/* <TableCell>
                            <Checkbox />
                          </TableCell> */}
                            <TableCell key={locationState?.id}>
                              <TooltipStyle
                                title={`${locationState?.name}`}
                                placement="bottom"
                                arrow
                              >
                                {/* <img
                                  src={`${"http://"}${"dam-api.pimcoredemos.com"}${
                                    locationState?.path
                                  }${locationState?.name}`}
                                /> */}
                                <img
                                  src={`${"https://"}${"dam-api.pimcoredemos.com"}${
                                    locationState?.thumbnail
                                  }`}
                                />
                              </TooltipStyle>
                              {/* <img src={imgObj?.path} title="green iguana" /> */}
                            </TableCell>
                            <TableCell>{locationState?.displayName}</TableCell>
                            <TableCell>
                              <Typography variant="p">
                                {locationState?.mimetype}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <TooltipStyle
                                placement="bottom"
                                arrow
                                title="Remove asset from share"
                              >
                                <Button
                                  size="small"
                                  className="button icon"
                                  onClick={() => {
                                    handleDeleteAsset(locationState?.id);
                                  }}
                                >
                                  <Cross />
                                  {/* <Delete /> */}
                                </Button>
                              </TooltipStyle>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
            <Box className=" items">
              <Box className="headinAlign">
                {/* <SelectorDetail /> */}
                <Typography component="b" variant="h6">
                  Share
                </Typography>

                <Button
                  size="small"
                  variant="filled"
                  startIcon={<Share />}
                  className="button"
                  onClick={() =>
                    handleShareBTnClicked(structuredData, asset, customData)
                  }
                  // onClick={handleShareBTnClicked}
                  disabled={!isValidAssetMetadata()}
                  // disabled={
                  //   !inputValueName ||
                  //   !selectedDate ||
                  //   locationState === "delete" ||
                  //   (!multipleShareCount && searchParams?.size > 0)
                  // }
                >
                  Share
                </Button>
              </Box>
              <FormGroup className="shareAssetForm">
                <FormControl variant="standard">
                  <label>
                    Share name <span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Please enter name"
                    className="textbox"
                    onChange={(e) => handleNameInputChange(e.target.value)}
                    value={inputValueName}
                  />
                </FormControl>
                <span className="notification-text">
                  {commonMessages?.maxCharHundred}
                </span>
                <FormControl variant="standard">
                  <label>
                    Expiry date <span class="required">*</span>
                  </label>
                  {/* <input
                  type="text"
                  placeholder="Please enter Date"
                  className="textbox"
                /> */}
                  <DatePicker
                    placeholderText="Select expiry date"
                    showIcon
                    toggleCalendarOnIconClick
                    onFocus={(e) => (e.target.readOnly = true)}
                    onChange={(date) => handleDateChange(date)}
                    selected={selectedDate}
                    inputMode="none"
                    minDate={tomorrow.toDate()}
                    showYearDropdown
                    yearDropdownItemNumber={60}
                    scrollableYearDropdown
                  />
                </FormControl>

                <FormControl variant="standard">
                  <label>Comment</label>
                  <TextareaAutosize
                    maxLength={200}
                    style={{
                      maxHeight: "220px", // Limit the maximum height
                      overflowY: "auto", // Add scrollbars if content exceeds max-height
                      resize: "vertical", // Disable manual resizing
                    }}
                    className="textbox"
                    placeholder="Please enter comment"
                    onChange={(e) => handleCommentInputChange(e.target.value)}
                    value={inputCommentText}
                  />
                </FormControl>
                <span className="notification-text">
                  {commonMessages?.maxCharFivehundred}
                </span>

                <Box
                  component="div"
                  className="notification"
                  sx={{ padding: "1em", margin: "1em 0 1em 0" }}
                >
                  {commonMessages?.assetMetadataCheckBoxMessage}
                </Box>

                <Box sx={{ padding: "1em 0 1em 0.5em", marginBottom: "4em" }}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="stretch"
                    className="borderBox"
                    mb={2}
                  >
                    <Grid item xs={6} className="boxbg">
                      <Box>
                        <Checkbox
                          id="Asset"
                          checked={asset?.type === "asset" || false}
                          onChange={() => handleChangeCartConfigType("asset")}
                        />{" "}
                        <label htmlFor="Asset">Asset</label>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="Asset type">Asset type</label>
                      <ReactSelect
                        options={assetOptions || []}
                        isDisabled={!asset?.type}
                        onChange={(e) => handleChangeFormat(e, "asset")}
                        menuPlacement="auto"
                      />
                    </Grid>
                  </Grid>

                  {asset?.format === "custom" && (
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        border: "1px solid var(--bg)",
                        padding: "1em",
                        marginTop: "-1.2em !important",
                        marginBottom: "2em !important",
                        borderRadius: "0 0 3px 3px",
                        borderTop: "0",

                        "& label": {
                          fontWeight: "600",
                        },

                        "& .textbox": {
                          height: "38px",
                        },
                      }}
                    >
                      <Grid item xs={6}>
                        <label>Width</label>
                        <Box className="textboxPlaceholder">
                          <input
                            className="textbox"
                            type={customOptions?.setup?.width?.type}
                            min={customOptions?.setup?.width?.min}
                            value={customData?.width}
                            disabled={!asset?.type}
                            // onKeyPress={handleKeyPress}
                            onKeyDown={handleKeyPress}
                            onChange={(e) =>
                              handleChangeCustom(e.target.value, "width")
                            }
                            // max={100}
                          />
                          <span className="inputlabel">PX</span>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <label>Height</label>
                        <Box className="textboxPlaceholder">
                          <input
                            // max={100}
                            type={customOptions?.setup?.height?.type}
                            min={customOptions?.setup?.height?.min}
                            value={customData?.height}
                            onChange={(e) =>
                              handleChangeCustom(e.target.value, "height")
                            }
                            className="textbox"
                            onKeyDown={handleKeyPress}
                            disabled={!asset?.type}
                          />
                          <span className="inputlabel">PX</span>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <label>Format</label>

                        <ReactSelect
                          value={{
                            label: customData?.format,
                            value: customData?.format,
                          }}
                          menuPlacement="auto"
                          options={customOptions?.setup?.format?.options || []}
                          onChange={(e) => handleChangeCustom(e, "format")}
                          isDisabled={!asset?.type}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <label>Quality</label>
                        <input
                          value={customData?.quality}
                          type={customOptions?.setup?.quality?.type}
                          max={customOptions?.setup?.quality?.max}
                          min={customOptions?.setup?.quality?.min}
                          className="textbox"
                          onKeyPress={handleKeyPress}
                          onChange={(e) =>
                            handleChangeCustom(e.target.value, "quality")
                          }
                          disabled={!asset?.type}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    spacing={1}
                    alignItems="stretch"
                    className="borderBox"
                  >
                    <Grid item xs={6} className="boxbg">
                      <Box>
                        <Checkbox
                          id="metadata"
                          checked={
                            structuredData?.type === "structuredData" || false
                          }
                          onChange={() =>
                            handleChangeCartConfigType("structuredData")
                          }
                        />{" "}
                        <label htmlFor="metadata">Metadata</label>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="Metadata type">Metadata type</label>
                      <ReactSelect
                        options={structuredDataOptions || []}
                        isDisabled={!structuredData?.type}
                        onChange={(e) =>
                          handleChangeFormat(e, "structuredData")
                        }
                        menuPlacement="auto"
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* <FormControl>
                <Checkbox id="Metadata" />{" "}
                <label htmlFor="Metadata">Metadata</label>
                <Checkbox id="Assets" className="marginL-sm" />{" "}
                <label htmlFor="Assets">Assets</label>
              </FormControl>

              <FormControl variant="standard">
                <label>File Type</label>
                <Select>
                  <option>test</option>
                </Select>
              </FormControl>

              <FormControl variant="standard">
                <label>Original</label>
                <Select>
                  <option>test</option>
                </Select>
              </FormControl> */}
              </FormGroup>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}

export default ShareAssetPage