import { List, ListItemIcon, Tooltip } from '@mui/material';
import {ReactComponent as Home} from '../assest/svg/home.svg';
import {ReactComponent as Document} from '../assest/svg/document.svg';
import {ReactComponent as Folder} from '../assest/svg/folderStructure.svg';
import { ReactComponent as ShareHistory } from '../assest/svg/sharehistory.svg';
import {ReactComponent as Share} from '../assest/svg/share.svg';
// import {ReactComponent as Notification} from '../assest/svg/notification.svg';
import React from 'react';
import { useLocation,useNavigate } from 'react-router-dom';

import {withStyles} from "@material-ui/core/styles";
import { color } from '@mui/system';
import { useNonPersistedStore } from "../store/nonPersistedStore";

const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);

const LeftMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {setSelectedIconZusNon,selectedIconZusNon, setFilterMasalaZusNon} = useNonPersistedStore();
  const handleNavigation = () => {
    setSelectedIconZusNon("asset")
   if(location?.pathname!="/")
   {
    navigate({
      pathname: "/",
    });
    // setFilterMasalaZusNon(null)
  
  
  }
    else{
      window.location.reload()
      // setFilterMasalaZusNon(null)
    }
  };
  const handleHomeNavigation = () => {
    setSelectedIconZusNon("dashboard")
    navigate({
      pathname: "/dashboard",
      // search: `id=${[...versionCheckedId]}`,
    });
  };
  const handleCollectionNavigation = () => {
    setSelectedIconZusNon("collection")
    navigate({
      pathname: "/collectionPage",
      // search: `id=${[...versionCheckedId]}`,
    });
  };
  const handleShareNavigation = () => {
    navigate({
      pathname: "/shareAssetPage",
      // search: `id=${[...versionCheckedId]}`,
    });
  };

  const handleClickPublicShare = (event) => {
    navigate(`/publicShareHistoryPage`);
  };


  return (
    <>
      <List component="nav">
        <ListItemIcon
          title="Home"
          sx={{ cursor: "pointer" }}
          className={location.pathname == "/dashboard" ? "active" : ""}
        >
          <TooltipStyle title="Home" placement="right" arrow>
            <Home onClick={handleHomeNavigation} />
          </TooltipStyle>
        </ListItemIcon>
        <ListItemIcon
          sx={{ cursor: "pointer" }}
          className={
            location.pathname == "/" ||
            location.pathname == "/detailPage" ||
            location.pathname?.includes("/searchResultsPage")
              ? "active"
              : ""
          }
        >
          <TooltipStyle title="Asset management" placement="right" arrow>
            <Document onClick={handleNavigation} />
          </TooltipStyle>
        </ListItemIcon>
        <ListItemIcon
          sx={{ cursor: "pointer" }}
          className={
            location.pathname == "/collectionPage" ||
            location.pathname == "/collectionDetailPage" ||
            location.pathname?.includes("/collectionListingPage")
              ? "active"
              : ""
          }
          onClick={handleCollectionNavigation}
        >
          <TooltipStyle title="Collection" placement="right" arrow>
            <Folder />
          </TooltipStyle>
        </ListItemIcon>

        <ListItemIcon
          className={
            location.pathname == "/publicShareHistoryPage" ||
            location.pathname?.includes("/publicShareHistoryPage")
              ? "active"
              : ""
          }
          onClick={handleClickPublicShare}
          sx={{ cursor: "pointer" }}
        >
          <TooltipStyle title="Public share" placement="right" arrow>
            <ShareHistory />
          </TooltipStyle>
        </ListItemIcon>
        {/* <ListItemIcon title="Share" sx={{ cursor: "pointer" }}>
          <TooltipStyle title="Workflow" placement="right" arrow>
          <Share  onClick={handleShareNavigation}/>
          </TooltipStyle>
        </ListItemIcon> */}
        {/* <ListItemIcon title='Notification' sx={{ cursor: "pointer" }}>
          <Notification />
        </ListItemIcon> */}
      </List>
    </>
  );
};

export default LeftMenu;