
import CollectionDetailPage from "../pages/CollectionDetailPage";
import CollectionListPage from "../pages/CollectionListPage";
import CompareVersionPage from "../pages/CompareVersionPage";
import DetailPage from "../pages/DetailPage";

import LandingPage from "../pages/LandingPage";
import ManageFolderPage from "../pages/ManageFolderPage";
import NotFoundPage from "../pages/NotFoundPage";
import PublicShareAssetPage from "../pages/PublicShareAssetPage";
import PublicShareCollectionListPage from "../pages/PublicShareCollectionListPage";
import PublicShareHistoryPage from "../pages/PublicShareHistoryPage";
import SavedSearchesPage from "../pages/SavedSearchesPage";
import SearchResultPage from "../pages/SearchResultPage";
import  ShareAssetPage  from "../pages/ShareAssetPage";
import  UploadAssetsPage from "../pages/UploadAssetsPage";
import CartPage from "../pages/cartPage";
import Dashboard from "../pages/dashboard";



export const routes = [
  {
    link: "/",
    title: "landingPage",
    element: <LandingPage />,
  },

 

  {
    link: "/dashboard",
    title: "dashboard",
    element: <Dashboard/>,
  },
 
  
  {
    link: "/detailPage",
    title: "detailPage",
    element: <DetailPage/>,
  },
  {
    link: "/shareAssetPage",
    title: "shareAssetPage",
    element: <ShareAssetPage />,
  },
  // {
  //   link: "/api/public-share/list/:id",
  //   title: "publicShareAssetPage",
  //   element: <PublicShareAssetPage />,
  // },
  {
    link: "/collectionListingPage/:collectionTableId",
    title: "PublicShareCollectionListPage",
    element: <PublicShareCollectionListPage />,
  },
  // {
  //   link: "/api/public-share/collectionlist/:collectionSId",
  //   title: "publicShareAssetPage",
  //   element: <PublicShareAssetPage />,
  // },
  {
    link: "/publicShareHistoryPage",
    title: "publicShareHistoryPage",
    element: <PublicShareHistoryPage />,
  },

  


  {
    link: "/uploadAssetsPage",
    title: "uploadAssetsPage",
    element: <UploadAssetsPage/>,
  },
  {
    link: "/manageFolderPage",
    title: "ManageFolderPage",
    element: <ManageFolderPage/>,
  },
  {
    link: "/compareVersionPage",
    title: "compareVersionPage",
    element: <CompareVersionPage/>,
  },
  {
    link: "/searchResultsPage",
    title: "searchResultsPage",
    element: <SearchResultPage/>,
  },
  {
    link: "/collectionPage",
    title: "collectionPage",
    element: <CollectionListPage/>,
  },
  {
    link: "/cartPage",
    title: "cartPage",
    element: <CartPage/>,
  },
  {
    link: "/collectionDetailPage",
    title: "collectionDetailPage",
    element: <CollectionDetailPage/>,
  },
  {
    link: "/savedSearchesPage",
    title: "savedSearchesPage",
    element: <SavedSearchesPage/>,
  },
  
 
];


export const publicRoutes = [
  {
    link: "/api/public-share/list/:id",
    title: "publicShareAssetPage",
    element: <PublicShareAssetPage />,
  },
  
  {
    link: "/api/public-share/collectionlist/:collectionSId",
    title: "publicShareAssetPage",
    element: <PublicShareAssetPage />,
  },

  {
    link: "*",
    title: "notFound",
    element: <NotFoundPage />,
  },
  
];