
import './App.css';

import { Box, Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import LandingPage from './pages/LandingPage';
import { publicRoutes, routes } from './router/Routes';
import LoginPage from './pages/LoginPage';
import PrivateRoute from './router/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import PublicRoute from './router/PublicRoute';

function App() {
  return (
    <Box>
      <ToastContainer
        hideProgressBar={true}
        position="top-right"
        newestOnTop={true}
        pauseOnHover={true}
        draggable={true}
        closeOnClick={true}
        theme="dark"
        autoClose={1000}
      ></ToastContainer>
      
      <Routes>

      {publicRoutes?.map((item) => (
        <Route
          path={item.link}
          key={item.link}
          element={<PublicRoute />}
        >
          <Route path={item.link} element={item.element} />
        </Route>
      ))}
        {routes?.map((item) => {
          return (
            <Route
              exact
              path={item.link}
              key={item.link}
              element={<PrivateRoute />}
            >
              <Route exact path={item.link} element={item?.element} />
            </Route>
          );
        })}
        <Route exact path="/login" element={<LoginPage />} key="/login" />

        
      </Routes>
      {/* <LandingPage /> */}
    </Box>
  );
}

export default App;
