import * as yup from "yup";

 export const uploadUrlSchema = yup.object().shape({
   //    contactEmail: yup
   //      .string()
   //      .required("Email is required")
   //      .matches(
   //        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
   //        "Invalid email"
   //      ),
   //      zipPostalCode:yup.string().required("Zip is required"),

   webUrl: yup
     .string()
     .required("web url is required  ")
    //  .matches(
    //    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,"Invalid URL"
    //  ),
 });
