import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";


export const useStore = create(
  persist(
    (set) => ({
      mainPageListingArr: [],
      setMainPageListingArr: (payload) => set({ mainPageListingArr: payload }),
      totalCountZustand: "",
      setTotalCountZustand: (payload) => set({ totalCountZustand: payload }),
      totalCountCartZustand: "",
      setTotalCountCartZustand: (payload) =>
        set({ totalCountCartZustand: payload }),
      totalCountCollectionZustand: "",
      setTotalCountCollectionZustand: (payload) =>
        set({ totalCountCollectionZustand: payload }),
      clickedFolderZus: "",
      setClickedFolderZus: (payload) => set({ clickedFolderZus: payload }),
      folderTreeStructureZus: "",
      setFolderTreeStructureZus: (payload) =>
        set({ folderTreeStructureZus: payload }),
      languageOptionsZus: "",
      setLanguageOptionsZus: (payload) => set({ languageOptionsZus: payload }),
      filtersZustand: null,
      setFiltersZustand: (payload) => set({ filtersZustand: payload }),
      clickedCollectionRowZus: {},
      setClickedCollectionRowZus: (payload) =>
        set({ clickedCollectionRowZus: payload }),
      EngineModel: [],
      setEngineModel: (options) => set({ EngineModel: options }),
      loginUserProfileDataZus: null,
      setLoginUserProfileDataZus: (payload) =>
        set({ loginUserProfileDataZus: payload }),
      setFinalShipPrice: (finalShipPrice) =>
        set({ finalShipPrice: finalShipPrice }),
    }),
    {
      name: "my-persisted-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
