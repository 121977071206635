import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ReactComponent as GreenCheck} from '../assest/svg/greenSuccesscheckmark.svg';
import {ReactComponent as RedCheck} from '../assest/svg/redErrorCheck.svg';
import {ReactComponent as RedAlert} from '../assest/svg/redAlertIcon.svg';
export const showSuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
     closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
    icon: <GreenCheck />
   
  });
};

export const showSuccessToast1 = (message,time) => {
  toast.success(message, {
    position: "top-right",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const showErrorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    // icon: <RedCheck />
    icon:<RedAlert />
  });
};

export const showErrorToast1 = (message,time) => {
  toast.error(message, {
    position: "top-right",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};


export const showWarningToast = (message) => {
  toast.warning(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
