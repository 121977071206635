import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  Menu,
  MenuItem,
  Typography,Drawer
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as Download } from "../assest/svg/downloadicon.svg";
import { ReactComponent as Edit } from "../assest/svg/editicon.svg";

import { ReactComponent as More } from "../assest/svg/more.svg";
import { ReactComponent as Saved } from "../assest/svg/save.svg";
import { menuStyles, paperPropsStyles } from "../styledComponents/MenuStyles";

import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { downloadSingleAsset, getCollectionList } from "../api/getRequest";
import { showErrorToast, showSuccessToast } from "../assest/Toaster";
import { useNonPersistedStore } from "../store/nonPersistedStore";

import { ReactComponent as Share } from "../assest/svg/shareicon.svg";
import { ReactComponent as Media } from "../assest/svg/mediaicon.svg";
import { ReactComponent as Shop } from "../assest/svg/shopicon.svg";
import { ReactComponent as Downloaded } from "../assest/svg/downloadicon.svg";
// import {ReactComponent as Edit} from '../assest/svg/editicon.svg';
import { useCallback } from "react";
import { ReactComponent as Move } from "../assest/svg/moveicon.svg";
import { ReactComponent as Delete } from "../assest/svg/deleteicon.svg";
// import { menuStylesThreeDots, paperPropsStylesThreeDotsLAndingPAge } from "../styledComponents/MenuStylesThreeDotsCardLandingPage";
import DamPortalModal from "../components/DamPortalModal";
import { addToCart, addToCollection, customDownloadApi, deleteAsset, getUserCart, replaceAssetFile, uploadSingleAssetFile } from "../api/postRequest";
import { useStore } from "../store/persistedStore";
import commonMessages from "../utils/commomMessages";
import Loader from "./Loader";
import FolderTreeMoveToFolder from "./FolderTreeMoveToFolder";
import { ReactComponent as Close } from "../assest/svg/cross.svg";

const SelectorDetail = ({ id ,detailPageDatta,callbackSingleAssetApiFn}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [addToCartModal, setAddToCartModal] = useState(false);
  const [selectedValues, setSelectedValues] = useState();
  const [IsMoveModal, setIsMoveModal] = useState(false);
  const [downloadCustomModal, setDownloadCustomModal] = useState(false);
  const [asset, setAsset] = useState();
  const [assetId, setAssetId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [loopMenuPopId, setLoopMenuPopId] = useState(null);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState();
 
  const [parentandAssetId, setParentandAssetId] = useState();
  const [addToCollectionModal, setAddToCollectionModal] = useState(false);
  const [isReplaceModal, setIsReplaceModal] = useState(false);
  const [replaceFile, setReplaceFile] = useState(null);
  const [editAsset, setEditAsset] = useState();
  const navigate = useNavigate();
  const {
    clickedFolderZusNon,
   
    setEditAssetZusNon,collectionListZusNon,
    editAssetZusNon,
    setCollectionListZusNon,setUserCartListingZusNon,selectedMimeTypeZusNon,setReplacedImageZusNon,setUserCartListingTotalCountZusNon
  } = useNonPersistedStore();
  const {
    setMainPageListingArr,
    mainPageListingArr 
  } = useStore();
  const open = Boolean(anchorEl);
  const getSelectedIds = (arr) => {
    let selectedIds = arr?.reduce((acc, curr) => {
      if (curr?.isChecked) {
        acc.push(curr?.id);
      }
      return acc;
    }, []);
    return selectedIds || [];
  };

  const selectedIds = React.useMemo(
    () => getSelectedIds(mainPageListingArr),
    [mainPageListingArr]
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  
  };
  const handleNavigation = () => {
    navigate({
      pathname: "/",
    });
  };
  const replaceAssetFileMutation = useMutation(
    (post) => replaceAssetFile(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
  
          
            showSuccessToast(result?.data?.message);
            setIsReplaceModal(false)
            callbackSingleAssetApiFn()
            setReplaceFile(null)
            setReplacedImageZusNon(result?.data?.data?.asset?.data);
            // removeFile();
          } else {
            showErrorToast(result?.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const mutationAddToCollection = useMutation(
    (post) => addToCollection(post, selectedDropdownValue),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            showSuccessToast(result.data?.message);
            setAddToCollectionModal(false);
          } else {
            showErrorToast(result.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );
  const mutationGetCollectionList = useMutation((post) => getCollectionList(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          setCollectionListZusNon(result.data?.data?.collectionlist);
          


        } else {
          setCollectionListZusNon(null);
        
        }
      }
    },
    onError: (error) => { console.log(error)},
  });
  const mutation = useMutation((post) => downloadSingleAsset(post), {
    mutationKey: "downloadSingleAssetUn",
    method: "GET",
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
        } else {
        }
      }
    },
    onError: (error) => {},
  });
  const customDownloadApiMutation = useMutation(
    (post) => customDownloadApi(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            window.open(
              result?.data?.url,
              "_self"
              // "_blank"
            );

            handleCloseModal();
            showSuccessToast(result.data?.message);
          } else {
            showErrorToast(result.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );
  const mutationGetUserCart = useMutation((post) => getUserCart(post), {
    onSuccess: (result) => {
      if (result?.data?.success) {
        setUserCartListingZusNon(result?.data?.data?.entries);
        setUserCartListingTotalCountZusNon(result?.data?.data?.totalResults)
      } else {
      }
    },
    onError: (error) => {},
  });
  const mutationDeleteAsset = useMutation((post) => deleteAsset(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          showSuccessToast(result.data?.message);
          navigate(-1);
          handleCloseModal();
        } else {
        }
      }
    },
    onError: (error) => {},
  });
  const mutationAddToCart = useMutation((post) => addToCart(post), {
    onSuccess: (result) => {
      if (result?.data?.success) {
        showSuccessToast(result.data?.message);
        handleCloseModal();
         mutationGetUserCart.mutate();
      } else {
        showErrorToast(result.data?.message);
      }
    },
    onError: (error) => {},
  });
  const handleDownloadAsset = () => {
    window.open(
      `https://dam-api.pimcoredemos.com/api/download-asset/${id}`,
      "_self"
    );
    setTimeout(() => {
      showSuccessToast("Asset downloaded succesfully");
    }, 3000);

    //  mutation.mutate(id)
  };
  const handleEditAsset = () => {
    setEditAsset(true);
    setEditAssetZusNon(!editAssetZusNon);
  };

  const handleDeleteAsset = (obj) => {
    
    setIsDeleteModal(true);
    setDeleteId(obj?.id);
    // mutationDeleteAsset.mutate(id)
    // console.info("You clicked the delete icon.");
  };
  const handleCallbackAddToCollection = (selectedValues) => {
    
    setSelectedDropdownValue(selectedValues?.value);
  
    let payload =[{
      parentId: detailPageDatta?.parent ,
       assetId: detailPageDatta?.id 
    }]
     
     mutationAddToCollection.mutate(JSON.stringify(payload));
  
  };

  const handleAddToCollection = (id, parentId) => {
    if (id && parentId) {
      setParentandAssetId({
        parentId: parentId,
        assetId: id,
      });
    } else {
      setParentandAssetId();
    }
    setAddToCollectionModal(true);
    mutationGetCollectionList.mutate();
  };
  const handleCloseModal = () => {
    setAddToCollectionModal(false);
    setAddToCartModal(false);
    setIsMoveModal(false);
    setDownloadCustomModal(false);
     setIsDeleteModal(false);
     setIsReplaceModal(false)
   setReplaceFile(null)
   setSelectedValues(null)
  };

  const handleAddToCart = (obj, structuredData, assetData, customData) => {
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    let payload = {
      assets: [
        {
          dataPoolId: obj?.parent,
          selectedId: obj?.id,
          configs: configArr,
        },
      ],
    };
    mutationAddToCart.mutate(payload);
  };

  const handleCallbackAddToCart = (structuredData, assetData, customData) => {
    if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
      showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
    
      return;
    } else if (
      (!structuredData.type && !assetData.format) ||
      (structuredData.type && !structuredData.format) ||
      (assetData.type && !assetData.format)
    ) {
      // Handle validation error, display message to user or prevent API call

      showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
      
      return;
    }
    
      handleAddToCart(asset, structuredData, assetData, customData);
    
  };
  const handleOpenCartOptions = (obj) => {
    setAddToCartModal(true);
    if (obj) {
      setAsset(obj);
    }
  };

  const handleMoveModal = (id) => {
    if(id) {
      setAssetId(id);
    }
    else{
      setAssetId();
    }
    setIsMoveModal(true);
  };

  const handleMoveTofolderCallbackApi = () => {
    let payload = {
      // folderName:clickedFolderZusNon.label,
      mimetype: selectedMimeTypeZusNon?.mimetype,
    };
  
  };
  const handleSingleDownload = (obj, structuredData, assetData, customData) => {
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    let payload = {
      assets: [
        {
          dataPoolId: detailPageDatta?.parent,
          selectedId: detailPageDatta?.id,
          configs: configArr,
        },
      ],
    };
    customDownloadApiMutation.mutate(payload);
  };

  const handleCallbackDownload = (structuredData, assetData, customData) => {
    if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
      showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
    
      return;
    } else if (
      (!structuredData.type && !assetData.format) ||
      (structuredData.type && !structuredData.format) ||
      (assetData.type && !assetData.format)
    ) {
      // Handle validation error, display message to user or prevent API call

      showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
   
      
      return;
    }
    handleSingleDownload(asset, structuredData, assetData, customData);
  };
  const handleDownloadModal = (id, path, name) => {
    setDownloadCustomModal(true);
    if (id) {
      setAsset(id);
    }
  
  };

  const handleCallbackDelete = () => {
    if (isDeleteModal) {
      let payload = {
        elementId: [deleteId],
      };
      mutationDeleteAsset.mutate(payload);
    }
  };

  const handleShareNavigation = (param) => {
    if (param) {
      navigate(
        "/shareAssetPage",

        { state: param }
      );
    }

    setAnchorEl(null);
  };

  const handleReplaceModal = (param) => {
    setIsReplaceModal(true);
  };
  const handleCallbackReplaceAsset = (file) => {

    
    let payloadFile = {
      assetId: detailPageDatta?.id,
      Filedata: file,
    };
    replaceAssetFileMutation.mutate(payloadFile);
  };
   
  const handleCallbackOnDrop = useCallback((acceptedFiles) => {
    // Ensure only one file is accepted
    if (acceptedFiles.length > 0) {
      setReplaceFile(acceptedFiles[0]);
    
      
    }
  }, []);
  const handleOnChangeCollection = (selectedOptions) => {
    setSelectedValues(selectedOptions);
  };
  const shouldButtonDisappear = !clickedFolderZusNon?.permissions?.edit_asset;
 


  return (
    <>
     {replaceAssetFileMutation.isLoading && <Loader></Loader>}
    
      <Box>
        <DamPortalModal
          open={addToCollectionModal}
          onClose={handleCloseModal}
          titleMessage={commonMessages?.addToCollection}
          addToCollectionModalFlag={addToCollectionModal}
          selectedValues={selectedValues}
          handleOnChangeCollection={handleOnChangeCollection}
          // parentandAssetId={getParentandAssetId}
          handleCallbackAddToCollection={handleCallbackAddToCollection}
          collectionListZusNon={collectionListZusNon}
          mutationAddToCollection={mutationAddToCollection}
          
        />
      </Box>
      <Box>
        <DamPortalModal
          open={addToCartModal}
          addToCartModalFlag={addToCartModal}
          onClose={handleCloseModal}
          titleMessage=  {commonMessages?.addToCart}
          handleCallbackAddToCart={handleCallbackAddToCart}
          mutationAddToCart={mutationAddToCart}
        />
      </Box>
      {/* <Box>
        <DamPortalModal
          openDrawer={IsMoveModal}
          onClose={handleCloseModal}
          titleMessage={"Move to folder"}
          // node={selectedNode}
          moveTofolderModalFlag={IsMoveModal}
          assetId={[assetId]}
          assetArr={mainPageListingArr}
          handleMoveTofolderCallbackApi={handleMoveTofolderCallbackApi}
        />
      </Box> */}
       <Drawer open={IsMoveModal} anchor="right" className="uploadAsset">
          <Typography component="h5" variant="h5" className="drawertitle">
            <Move height={25} />{" "}
            <span className="marginL-xs">Move to folder</span>
            <Close className="closed" onClick={() => handleCloseModal()} />
          </Typography>
          <Box className="">
            <Grid container>
              <Grid item xs={12} className="manageFolder">
                
                <FolderTreeMoveToFolder
                  assetId={
                    // assetId ? [assetId] : getSelectedIds(mainPageListingArr)
                    assetId ? [assetId] : selectedIds
                  }

                  setIsMoveModal={setIsMoveModal}
                  moveTofolderModalFlag={IsMoveModal}
                  hanldleCallbackMoveToFolder={handleCloseModal}
                  handleMoveTofolderCallbackApi={handleMoveTofolderCallbackApi}
                  assetArr={mainPageListingArr}
                />

                {/* <FolderTree
                  assetId={
                    assetId ? [assetId] : getSelectedIds(mainPageListingArr)
                  }
                  setIsMoveModal={setIsMoveModal}
                  moveTofolderModalFlag={IsMoveModal}
                  hanldleCallbackMoveToFolder={hanldleCallbackMoveToFolder}
                  handleMoveTofolderCallbackApi={handleMoveTofolderCallbackApi}
                  assetArr={mainPageListingArr}
                /> */}
              </Grid>
            </Grid>
          </Box>
        </Drawer>

      <Box>
        <DamPortalModal
          open={downloadCustomModal}
          downloadCustomModalFlag={downloadCustomModal}
          onClose={handleCloseModal}
          titleMessage={"Download"}
          handleCallbackDownload={handleCallbackDownload}
          customDownloadApiMutation={customDownloadApiMutation}
        />
      </Box>
      <Box>
        <DamPortalModal
          open={isDeleteModal}
          onClose={handleCloseModal}
          titleMessage={"Delete"}
          deleteAssetFromDetailPage={isDeleteModal}
          handleCallbackDelete={handleCallbackDelete}
          mutationDeleteAsset={mutationDeleteAsset}
        />
      </Box>
      <Box>
        <DamPortalModal
          open={isReplaceModal}
          onClose={handleCloseModal}
          titleMessage={"Replace asset"}
          replaceFile={replaceFile}
          handleCallbackOnDrop={handleCallbackOnDrop}
          setReplaceFile={setReplaceFile}
          replaceAsset={isReplaceModal}
          replaceAssetFileMutation={replaceAssetFileMutation}
          handleCallbackReplaceAsset={handleCallbackReplaceAsset}
        />
      </Box>

      <Box className="buttonGroup">
        {detailPageDatta?.permissions?.download_asset && (
          <Button
            size="small"
            startIcon={<Download />}
            className="button"
            variant="filled"
            onClick={() =>
              handleDownloadModal(
                detailPageDatta?.id,
                detailPageDatta?.path,
                detailPageDatta?.filename
              )
            }
            // onClick={handleDownloadAsset}
          >
            Download
          </Button>
        )}

        <Button
          onClick={handleClick}
          size="small"
          aria-controls={open ? "assetType" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className="button icon"
        >
          <More />
        </Button>
        <Menu
          anchorEl={anchorEl}
          id="assetType"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          {...paperPropsStyles}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() =>
              handleAddToCollection(
                detailPageDatta?.id,
                detailPageDatta?.parent
              )
            }
          >
            <Media /> {commonMessages?.addToCollection}
          </MenuItem>
        {  !shouldButtonDisappear &&   <MenuItem onClick={() => handleReplaceModal()}>
            <Media /> Replace asset
          </MenuItem> }
          {detailPageDatta?.permissions?.download_asset && (
            <MenuItem onClick={() => handleOpenCartOptions(detailPageDatta)}>
              <Shop />   {commonMessages?.addToCart}
            </MenuItem>
          )}

          {detailPageDatta?.permissions?.move_asset && (
            <MenuItem onClick={() => handleMoveModal(detailPageDatta?.id)}>
              <Move /> Move to folder
            </MenuItem>
          )}
          {detailPageDatta?.permissions?.download_asset && (
            <MenuItem
              onClick={() =>
                handleDownloadModal(
                  detailPageDatta?.id,
                  detailPageDatta?.path,
                  detailPageDatta?.filename
                )
              }
            >
              <Downloaded /> Download
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              handleShareNavigation(detailPageDatta);
            }}
          >
            <Share /> Share
          </MenuItem>
          {detailPageDatta?.permissions?.delete_asset && (
            <MenuItem
              onClick={() => {
                handleDeleteAsset(detailPageDatta);
              }}
            >
              <Delete /> Delete
            </MenuItem>
          )}
        </Menu>
      </Box>
    </>
  );
};

export default SelectorDetail;
