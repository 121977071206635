import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react';
import {ReactComponent as Noimage} from '../assest/svg/NoImage.svg';
import {ReactComponent as PdfIcon} from '../assest/svg/pdf.svg';
import {ReactComponent as HtmlIcon} from '../assest/svg/html.svg';
import {ReactComponent as PsdIcon} from '../assest/svg/psd.svg';
import {ReactComponent as CsvIcon} from '../assest/svg/csv.svg';

import {ReactComponent as SvgIcon} from '../assest/svg/svg.svg';
import {ReactComponent as TiffIcon} from '../assest/svg/tiff.svg';
import {ReactComponent as EpsIcon} from '../assest/svg/eps.svg';
import {ReactComponent as Mp4Icon} from '../assest/svg/mp4.svg';
import { useNonPersistedStore } from "../store/nonPersistedStore";

const DetailedImage = ({detailPageImg}) => {

  const [thumnailReplace,setThumbnialReplace]  = useState();
  const {
    replacedImageZusNon,setReplacedImageZusNon
  } = useNonPersistedStore();

 

  useEffect(() => {
  setTimeout(() => {
    const cacheBuster = new Date().getTime(); 
    setThumbnialReplace(`${detailPageImg?.thumbnail}?t=${cacheBuster}`);
    // setThumbnialReplace(detailPageImg?.thumbnail)
    
  }, 1000);  
    
  
    
  }, [replacedImageZusNon,detailPageImg?.thumbnail])

  
  
  
  return (
    <>
      <Box
        sx={{
          padding: "1em",
          "& img": {
            width: "100%",
            objectFit: "contain",
            border: "1px solid var(--border)",
            padding: "5px",
            maxHeight: "200px",
            padding: "1em",
            background: "var(--white)",
          },
        }}
      >
        {detailPageImg?.mimetype.length === 0 ? (
          <>
            <Noimage height={120} className="svgNoImage" />
          </>
        ) : 
        
        
        // detailPageImg?.mimetype == "application/pdf" ? (
        //   <>
        //     {" "}
        //     <PdfIcon height={120} className="svgNoImage" />
        //   </>
        // ) : detailPageImg?.mimetype == "text/html" ? (
        //   <>
        //     {" "}
        //     <HtmlIcon height={120} className="svgNoImage" />
        //   </>
        // ) : detailPageImg?.mimetype == "text/csv" ? (
        //   <>
        //     {" "}
        //     <CsvIcon height={120} className="svgNoImage" />
        //   </>
        // ) : detailPageImg?.mimetype == "video/mp4" ? (
        //   <>
        //     {" "}
        //     <Mp4Icon height={120} className="svgNoImage" />
        //   </>
        // ) : detailPageImg?.mimetype == "application/postscript" ? (
        //   <>
        //     {" "}
        //     <EpsIcon height={120} className="svgNoImage" />
        //   </>
        // ) : detailPageImg?.mimetype == "image/tiff" ? (
        //   <>
        //     {" "}
        //     <TiffIcon height={120} className="svgNoImage" />
        //   </>
        // ) : detailPageImg?.mimetype == "image/svg+xml" ? (
        //   <>
        //     {" "}
        //     <SvgIcon height={120} className="svgNoImage" />
        //   </>
        // ) : detailPageImg?.mimetype == "image/x-photoshop" ? (
        //   <>
        //     {" "}
        //     <PsdIcon height={120} className="svgNoImage" />
        //   </>
        // ) : 
        
        (
          thumnailReplace && <img
            title={detailPageImg?.filename}

            // src={  replacedImageZusNon ? replacedImageZusNon :  `${"https://"}${"dam-api.pimcoredemos.com"}${
            //   thumnailReplace
            // }`}

            src={    `${"https://"}${"dam-api.pimcoredemos.com"}${
              thumnailReplace
            }`}
           
          />
        )}

        {/* <img src='https://images.pexels.com/photos/170811/pexels-photo-170811.jpeg?auto=compress&cs=tinysrgb&w=600' /> */}
      </Box>
    </>
  );
}

export default DetailedImage