import { Box } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
    <Box sx={{
        backgroundColor: 'var(--black)',
        color: 'var(--white)',
        padding: '1em',
        textAlign: 'center',
        position: 'relative',
        zIndex: '15',
        height: '50px'
    }}>
        Copyright ©2024 Digital Asset Management
    </Box>
  )
}

export default Footer