import { Box, Button, styled } from "@mui/material";
export const ErrorMessageStyled = styled(Box)(({ theme, status }) => ({
    color: "#CE0E2D",
   
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "500",
    marginTop: "5px",

}));
