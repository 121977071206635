import { ReactComponent as DownArrowDesc } from "../assest/svg/arrowdownDescending.svg";
import { ReactComponent as UpArrowAsc } from "../assest/svg/arrowUpAscending.svg";
import { ReactComponent as DeleteFolder } from "../assest/svg/deleteFolder.svg";
import { ReactComponent as CreateFolder } from "../assest/svg/UploadFolder.svg";
import { ReactComponent as RenameFolder } from "../assest/svg/editFolder.svg";


export const sortArray = [
  {
    label: "Creation date asc",
    value: "creationDate asc",
    svg: <UpArrowAsc />,
  },
  {
    label: "Creation date desc",
    value: "creationDate desc",
    svg: <DownArrowDesc />,
  },
  {
    label: "Modification date asc",
    value: "modificationDate asc",
    svg: <UpArrowAsc />,
  },
  {
    label: "Modification date desc",
    value: "modificationDate desc",
    svg: <DownArrowDesc />,
  },
  {
    label: "Filename asc",
    value: "filename asc",
    svg: <UpArrowAsc />,
  },
  {
    label: "Filename desc",
    value: "filename desc",
    svg: <DownArrowDesc />,
  },
];

export const manageforlderCruDArray = [
  { label: "Create folder", value: "creat", svg: <CreateFolder /> },
  { label: "Rename folder", value: "update", svg: <RenameFolder /> },
  { label: "Delete folder", value: "dele", svg: <DeleteFolder /> },
];
