import React, { useEffect } from 'react'
import { getFirstPageListing, getFolders } from '../api/postRequest';
import  {useMutation}  from "@tanstack/react-query";
import { Box, Grid,Typography } from '@mui/material';
import LeftMenu from '../components/LeftMenu';
import DamHeader from '../components/DamHeader';
import Selector from '../components/Selector';
import Footer from '../components/Footer';
import RightSectionImages from '../components/RightSectionImages';
import { useLocation } from 'react-router-dom';
import { useNonPersistedStore } from '../store/nonPersistedStore';
import { showErrorToast } from '../assest/Toaster';
import { useStore } from "../store/persistedStore";
import Loader from "../components/Loader";

const SearchResultPage = () => {
  const location = useLocation();
  const {
    clickedFolderZusNon,

    setClickedFolderZusNon,
  } = useNonPersistedStore();

  const { folderTreeStructureZus, setFolderTreeStructureZus } = useStore();
  const mutation = useMutation((post) => getFirstPageListing(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          //    let output =result.data?.data?.items
          //    const newArray=output?.map((obj)=>({...obj,["isChecked"]:false}))
          //    setMainPageListingArr(newArray)
        } else {
        }
      }
    },
    onError: (error) => {},
  });

  const mutationGetFolders = useMutation((post) => getFolders(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          setFolderTreeStructureZus(result?.data?.data?.items);       
          setClickedFolderZusNon({id:result?.data?.data?.items?.[0]?.id,label:result?.data?.data?.items?.[0]?.label,permissions:result?.data?.data?.items?.[0]?.permissions})
        } else {
          showErrorToast(result?.data?.message)
        }
      }
    },
    onError: (error) => {},
  });
 
  useEffect(() => {
    if(folderTreeStructureZus[0]?.clickable && !clickedFolderZusNon?.label){
    // if (clickedFolderZusNon?.id === 1 ) {

   
    mutationGetFolders.mutate();
      }
     
    // }
  }, []);

  return (
<>{!mutationGetFolders.isLoading?
    <Grid container className="container bg">
        <Grid item xs={1} className="menu">
          <LeftMenu />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ minHeight: "calc(100% - 50px)" }}
          className="rightPart"
        >
          <DamHeader />

         
            {/* <Box className="selector ">
              <Box className="breadcrumb">
                <Typography component="h2" variant="h5" className="heading">
                Search results for <span>{location?.state?.searchTerm}</span>
                </Typography>
              </Box>
              
            </Box> */}
            <Box className="search" >
              <RightSectionImages />
            </Box>
          
        </Grid>
        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>

  
 : <Loader/>}</> );
};

export default SearchResultPage