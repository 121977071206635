import React, { useEffect, useState } from 'react'
import DamHeader from '../components/DamHeader';
import { Box, Grid , Typography,Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox} from '@mui/material';
import Footer from '../components/Footer';
import LeftMenu from '../components/LeftMenu';
import { publicShareCollectionDelete, publicShareCollectionList } from '../api/postRequest';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {ReactComponent as ChevronBack} from '../assest/svg/chevronLeft.svg';
import Loader from '../components/Loader';
import { TooltipStyle } from '../components/RightSectionImages';
import {ReactComponent as Edit} from '../assest/svg/editicon.svg';

import {ReactComponent as Delete} from '../assest/svg/deleteicon.svg';
import DamPortalModal from '../components/DamPortalModal';
import { showSuccessToast } from '../assest/Toaster';
import commonMessages from '../utils/commomMessages';
import { useNonPersistedStore } from '../store/nonPersistedStore';
import PaginationComponent from '../components/PaginationComponent';
import { ReactComponent as DownArrowDesc } from "../assest/svg/sortDown.svg";
import { ReactComponent as UpArrowAsc } from "../assest/svg/sortUp.svg";

const PublicShareCollectionListPage = () => {
  let publicShareParamsId = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [collecionData, setCollectionData] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [collectionHistoryHeadingName, setCollectionHistoryHeadingName] = useState();
  const [isEditCollectionHistoryModal, setIsEditCollectionHistoryModal] =
    useState(false);
  const [isDeleteCollectionHistoryModal, setIsDeleteCollectionHistoryModal] =
    useState(false);

  const [
    deleteCollectionHistoryRowDetail,
    setDeleteCollectionHistoryRowDetail,
  ] = useState(null);
  const [editCollectionHistoryRowDetail, setEditCollectionHistoryRowDetail] =
    useState(null);

  const {
    setPublicShareCollectionHistoryPageTotalCountZusNon,
    publicShareCollectionHistoryPageTotalCountZusNon,
    setPublicShareCollectionHistoryListingZusNon,
    publicShareCollectionHistoryListingZusNon,
  } = useNonPersistedStore();
  const pageSize = 12; //
  const [classSort, setClassSort] = useState();

  



  const navigate = useNavigate();
  const publicShareCollectionListMutation = useMutation(
    (post) => publicShareCollectionList(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            setPublicShareCollectionHistoryListingZusNon(
              result?.data?.data?.items
            );
            setCollectionHistoryHeadingName(result?.data?.data?.name)
            setCollectionData(result?.data?.data);
            setPublicShareCollectionHistoryPageTotalCountZusNon(
              result?.data?.data?.totalResults
            );
          } else {
            setPublicShareCollectionHistoryListingZusNon(null);
            setCollectionData(null);
            setCollectionHistoryHeadingName(result?.data?.data?.name)
            setPublicShareCollectionHistoryPageTotalCountZusNon(0);
        

            setErrorMessage(result?.data?.message)
          }
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );


 

  const publicShareCollectionDeleteMutation = useMutation(
    (post) => publicShareCollectionDelete(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            showSuccessToast(result?.data?.message);
            handleCloseModal();
            let payload = {
              collectionId: parseInt(publicShareParamsId?.collectionTableId),
              showExpired:isChecked
            };
            if (publicShareParamsId?.collectionTableId) {
              publicShareCollectionListMutation.mutate(JSON.stringify(payload));
            }
          } else {
            setErrorMessage(result?.data?.message)
          }
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handlePageChange = (newPage) => {
    if (newPage === 0) {
      return;
    } else {
      setCurrentPage(newPage);
   

      if(parseInt(publicShareParamsId?.collectionTableId)){

        let payload = {
          currentPage: newPage,
          collectionId: parseInt(publicShareParamsId?.collectionTableId),
        };
        publicShareCollectionListMutation.mutate(JSON.stringify(payload));
      }
    
     
    }
  };

  useEffect(() => {
    let payload = {
      collectionId: parseInt(publicShareParamsId?.collectionTableId),
      currentPage: currentPage,
    };
    if (publicShareParamsId?.collectionTableId) {
      publicShareCollectionListMutation.mutate(JSON.stringify(payload));
    }
  }, []);
  const handleNavigationBack = () => {
    navigate(-1);
  };

  const handleEditCollectionHistoryModal = (item) => {
    setIsEditCollectionHistoryModal(true);
    setEditCollectionHistoryRowDetail(item);
  };
  const handleDeleteCollectionHistoryModal = (item) => {
    setIsDeleteCollectionHistoryModal(true);
    setDeleteCollectionHistoryRowDetail(item);
  };
  const handleCloseModal = () => {
    setIsEditCollectionHistoryModal(false);
    setIsDeleteCollectionHistoryModal(false);
  };
  const handleCallbackEditCollectionHistory = () => {};

  const handleCallbackDeleteCollectionHistory = (rowDetail) => {
    let payload = {
      collectionShareId: [rowDetail?.id],
   
    };
    if (rowDetail?.id) {
      publicShareCollectionDeleteMutation.mutate(payload);
    }
  };

  let headings =
    publicShareCollectionHistoryListingZusNon?.length > 0
      ? Object.keys(publicShareCollectionHistoryListingZusNon[0])
      : [];
  let keysToRemove = ["configsvsv", "id"];



  headings = headings?.filter((item) => !keysToRemove.includes(item));

  const handleConfigs = (row, configs) => {
    if (configs?.length > 1) {
      return configs?.map((item, idx) => {
        return (
          <Box>
            
            {configs[idx]?.type === "structuredData"
              ? "Metadata"
              : "Asset"}: {configs[idx]?.format}
            {idx !== configs?.length - 1 && ""}
          </Box>
        );
      });
    } else {
      return (
        <Box>
          {configs[0]?.type === "structuredData" ? "Metadata" : "Asset"}: {configs[0]?.format}
        </Box>
      );
    }
  };


  const handleAscSort=(val)=>{
   
    if(val=="name")
    {
      setClassSort("nameASC");
      let payload = {
        sortBy:"name",
        sortOrder:"asc",
        collectionId: parseInt(publicShareParamsId?.collectionTableId),
        showExpired:isChecked
      };
      publicShareCollectionListMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //  cloneCollection?.sort((a,b)=>a.Name.localeCompare(b.Name))
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
    if(val=="count")
    {
      setClassSort("countASC");
      let payload = {
        sortBy:"datapoolId",
        sortOrder:"asc",
        collectionId: parseInt(publicShareParamsId?.collectionTableId),
        showExpired:isChecked
      };
      publicShareCollectionListMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //  cloneCollection?.sort((a,b)=>a?.["Asset count"]-b?.["Asset count"])
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
    if (val == "expDate") {
      setClassSort("expASC");
      let payload = {
        sortBy:"expiryDate",
        sortOrder:"asc",
        collectionId: parseInt(publicShareParamsId?.collectionTableId),
        showExpired:isChecked
      };
      publicShareCollectionListMutation.mutate(JSON.stringify(payload));
      // let cloneCollection = publicShareCollectionHistoryListingZusNon;
      // cloneCollection?.sort(
      //   (a, b) => new Date(a?.["Expiry date"]) - new Date(b?.["Expiry date"])
      // );
      // setPublicShareCollectionHistoryListingZusNon(cloneCollection);
    }
    if (val == "sharedDate") {
      setClassSort("sharedASC");
      let payload = {
        sortBy:"sharedOn",
        sortOrder:"asc",
        collectionId: parseInt(publicShareParamsId?.collectionTableId),
        showExpired:isChecked
      };
      publicShareCollectionListMutation.mutate(JSON.stringify(payload));
      // let cloneCollection = publicShareCollectionHistoryListingZusNon;
      // cloneCollection?.sort(
      //   (a, b) => new Date(a?.["Shared on"]) - new Date(b?.["Shared on"])
      // );
      // setPublicShareCollectionHistoryListingZusNon(cloneCollection);
    }

   
  }
  
  const handleDescSort=(val)=>{
    if(val=="name")
    {
      setClassSort("nameDSC");
      let payload = {
        sortBy:"name",
        sortOrder:"desc",
        collectionId: parseInt(publicShareParamsId?.collectionTableId),
        showExpired:isChecked
      };
      publicShareCollectionListMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //  cloneCollection?.sort((a,b)=>b.Name.localeCompare(a.Name))
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
    if(val=="count")
    {
      setClassSort("countDSC");
      let payload = {
        sortBy:"datapoolId",
        sortOrder:"desc",
        collectionId: parseInt(publicShareParamsId?.collectionTableId),
        showExpired:isChecked
      };
       publicShareCollectionListMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //  cloneCollection?.sort((a,b)=>b?.["Asset count"]-a?.["Asset count"])
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
    if(val=="expDate")
    {
      setClassSort("expDSC");
      let payload = {
        sortBy:"expiryDate",
        sortOrder:"desc",
        collectionId: parseInt(publicShareParamsId?.collectionTableId),
        showExpired:isChecked
      };
      publicShareCollectionListMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //   cloneCollection?.sort((a,b)=>new Date(b?.["Expiry date"])-new Date(a?.["Expiry date"]))
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
    if(val=="sharedDate")
    {
      setClassSort("sharedDSC");
      let payload = {
        sortBy:"sharedOn",
        sortOrder:"desc",
        collectionId: parseInt(publicShareParamsId?.collectionTableId),
        showExpired:isChecked
      };
      publicShareCollectionListMutation.mutate(JSON.stringify(payload));
  //   let cloneCollection= publicShareCollectionHistoryListingZusNon;
  //  cloneCollection?.sort((a,b)=>new Date(b?.["Shared on"])-new Date(a?.["Shared on"]))
  //  setPublicShareCollectionHistoryListingZusNon(cloneCollection)
    }
  }

  

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); 
    
  
  };

  useEffect(() => {

    if(parseInt(publicShareParamsId?.collectionTableId)){

      let payload = {
        currentPage: currentPage,
        collectionId: parseInt(publicShareParamsId?.collectionTableId),
        showExpired:isChecked
      };
      publicShareCollectionListMutation.mutate(JSON.stringify(payload));
    }
  
  
   
  }, [isChecked])
  

  

  return (
    <>
      {publicShareCollectionListMutation?.isLoading && <Loader></Loader>}
      {publicShareCollectionDeleteMutation?.isLoading && <Loader></Loader>}
      <Box>
        <DamPortalModal
          open={isEditCollectionHistoryModal}
          onClose={handleCloseModal}
          titleMessage={"Edit collection"}
          isEditCollectionHistoryModal={isEditCollectionHistoryModal}
          editCollectionHistoryRowDetail={editCollectionHistoryRowDetail}
          handleCallbackEditCollectionHistory={
            handleCallbackEditCollectionHistory
          }
        />
      </Box>
      <Box>
        <DamPortalModal
          open={isDeleteCollectionHistoryModal}
          onClose={handleCloseModal}
          titleMessage={commonMessages?.uSureWannaDelete}
          isDeleteCollectionHistoryModal={isDeleteCollectionHistoryModal}
          deleteCollectionHistoryRowDetail={deleteCollectionHistoryRowDetail}
          publicShareCollectionDeleteMutation={publicShareCollectionDeleteMutation}
          handleCallbackDeleteCollectionHistory={
            handleCallbackDeleteCollectionHistory
          }
        />
      </Box>

      <Grid container className="container bg">
        <Grid item xs={1} className="menu">
          <LeftMenu />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ minHeight: "calc(100% - 50px)" }}
          className="rightPart"
        >
          <DamHeader />
          <Grid container className=" bodyPart">
            <Box className="selector ">
              <Box className="breadcrumb">
                <Typography component="h2" variant="h5" className="heading">
                  {commonMessages?.collectionShareHistoryHeading} of
                  <Box component="span" sx={{ marginLeft: "6px" }}>
                  {  collectionHistoryHeadingName}
                    {/* {collecionData?.name} */}
                  </Box>
                </Typography>
              </Box>
              {/* <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                Show expired
              </label> */}

             
              <Box className="buttonGroup">
              <Box className="checkbox">
                <Checkbox
                  checked={isChecked}
                  name="Show expired"
                  onChange={handleCheckboxChange}
                />
                <Typography component="label">Show expired</Typography>
              </Box>
                <Button
                  size="small"
                  startIcon={<ChevronBack />}
                  className="button"
                  onClick={handleNavigationBack}
                >
                  Back
                </Button>
              </Box>
            </Box>
            {!publicShareCollectionListMutation.isLoading?( publicShareCollectionHistoryListingZusNon &&
            publicShareCollectionHistoryListingZusNon?.length > 0 ? (
              <TableContainer sx={{ padding: "1em", marginTop: "70px" }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="table firstColumn"
                >
                  <TableHead>
                    <TableRow>
                      {headings?.map((heading, index) => (
                        <TableCell key={index}>
                          {heading == "Config" ? (
                            <> Download config</>
                          ) : heading == "Name" ? (
                            <Box className="flex">
                           
                              {heading}
                              <Box className="flex paddingL-sm arrow">
                                <UpArrowAsc
                                  className={
                                    classSort == "nameASC" ? "active" : ""
                                  }
                                  height={20}
                                  onClick={() => handleAscSort("name")}
                                />
                                <DownArrowDesc
                                  className={
                                    classSort == "nameDSC" ? "active" : ""
                                  }
                                  height={20}
                                  onClick={() => handleDescSort("name")}
                                />
                              </Box>
                            </Box>
                          ) : heading == "Expiry date" ? (
                            <Box className="flex">
                              
                              {heading}
                              <Box className="flex paddingL-sm arrow">
                                <UpArrowAsc
                                  className={
                                    classSort == "expASC" ? "active" : ""
                                  }
                                  height={20}
                                  onClick={() => handleAscSort("expDate")}
                                />
                                <DownArrowDesc
                                  className={
                                    classSort == "expDSC" ? "active" : ""
                                  }
                                  height={20}
                                  onClick={() => handleDescSort("expDate")}
                                />
                              </Box>
                            </Box>
                          ) : heading == "Shared on" ? (
                            <Box className="flex">
                              {" "}
                              {heading}{" "}
                              <Box className="flex paddingL-sm arrow">
                                <UpArrowAsc
                                  className={
                                    classSort == "sharedASC" ? "active" : ""
                                  }
                                  height={20}
                                  onClick={() => handleAscSort("sharedDate")}
                                />
                                <DownArrowDesc
                                  className={
                                    classSort == "sharedDSC" ? "active" : ""
                                  }
                                  height={20}
                                  onClick={() => handleDescSort("sharedDate")}
                                />
                              </Box>
                            </Box>
                          ) : (
                            heading
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {publicShareCollectionHistoryListingZusNon?.map(
                      (item, index) => (
                        <TableRow key={index}>
                          {headings?.map((heading, index) => (
                            <TableCell key={index}>
                              {heading === "Config" ? (
                                <> {handleConfigs(item, item?.Config)}</>
                              ) : heading === "URL" ? (
                                <>
                                  <a
                                    className={`button link ${new Date() > new Date(item['Expiry date']) ? "disabledLink" : ""}`}
                                    href={item[heading]?.replace(
                                      /https:\/\/dam-api\.pimcoredemos\.com\//,
                                       commonMessages?.damFeUrl
                                      //  commonMessages?.localUrl
                                    )}
                                    
                                    target="_blank"
                                  >
                                    {item[heading]?.replace(
                                      /https:\/\/dam-api\.pimcoredemos\.com\//,

                                    commonMessages?.damFeUrl

                                      //  commonMessages?.localUrl
                                    )}
                                  </a>
                                </>
                              ) : heading === "Action" ? (
                                <>
                                  {" "}
                                  <Box className="buttonGroup">
                                    
                                    <TooltipStyle
                                      placement="bottom"
                                      arrow
                                      title="Delete"
                                    >
                                      <Button
                                        size="small"
                                        className="button icon"
                                        onClick={() =>
                                          handleDeleteCollectionHistoryModal(
                                            item
                                          )
                                        }
                                      >
                                        <Delete />
                                      </Button>
                                    </TooltipStyle>
                                  </Box>
                                </>
                              ) : (
                                <>{item[heading]}</>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
                <Box
                  sx={{
                    textAlign: "right",
                    padding: "2em 0",
                    marginBottom: "20px",
                    position: "relative",
                  }}
                  className="paginationWrapper"
                >
                  <PaginationComponent
                    currentPage={currentPage}
                    totalCount={
                      publicShareCollectionHistoryPageTotalCountZusNon
                    }
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    itemCount={
                      publicShareCollectionHistoryListingZusNon?.length || 0
                    }
                  />
                </Box>
              </TableContainer>
            ) : (
              <Box
                sx={{ padding: "1em", marginTop: "var(--sideSetHeight)" }}
                className="notificationWrapper col-12"
              >
                <Box className="notification">{errorMessage ?? "Asset does not exist in this collection"}</Box>
              </Box>
            )):""}
          </Grid>
        </Grid>

        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default PublicShareCollectionListPage