import { Box, Grid ,Divider, Typography} from "@mui/material";


import FolderTree from "../components/FolderTree";
import RightSectionImages from "../components/RightSectionImages";
import LeftMenu from "../components/LeftMenu";
import DamHeader from "../components/DamHeader";
import Selector from "../components/Selector";
import Footer from "../components/Footer";
import { useNonPersistedStore } from "../store/nonPersistedStore";
import {ReactComponent as Folder} from '../assest/svg/folder.svg';
import { useEffect, useState } from "react";
import { useStore } from "../store/persistedStore";
import { getFolders } from "../api/postRequest";
import { useMutation } from "@tanstack/react-query";
import { showErrorToast } from "../assest/Toaster";
import { Button } from "@material-ui/core";

import { ReactComponent as Left } from "../assest/svg/arrow_back.svg";

import { ReactComponent as Right } from "../assest/svg/arrow_forward.svg";


const LandingPage = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { folderTreeStructureZus, setFolderTreeStructureZus } = useStore();
  const {
    clickedFolderZusNon,

    setClickedFolderZusNon,
  } = useNonPersistedStore();
  const [internalSearchValue, setInternalSearchValue] = useState(null);
  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };
  const mutationGetFolders = useMutation((post) => getFolders(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          setFolderTreeStructureZus(result?.data?.data?.items);       
          setClickedFolderZusNon({id:result?.data?.data?.items?.[0]?.id,label:result?.data?.data?.items?.[0]?.label,permissions:result?.data?.data?.items?.[0]?.permissions})
        } else {
          showErrorToast(result?.data?.message)
        }
      }
    },
    onError: (error) => {},
  });
  useEffect(() => {
    if(folderTreeStructureZus[0]?.clickable && !clickedFolderZusNon?.label){
    // if (clickedFolderZusNon?.id === 1 ) {

   
    mutationGetFolders.mutate();
      }
     
    // }
  }, []);

  

  return (
    <Grid container className="container" spacing="0">
      <Grid item className="menu">
        <LeftMenu />
      </Grid>
      <Grid item sx={{ minHeight: "calc(100% - 50px)" }} className="rightPart">
        <DamHeader />
        <Grid container className="bodyPart">
          <Grid
            item
            className={`leftSection  sidebar ${
              isExpanded ? "expanded" : "collapsed"
            }`}
          >
            <Button className="button icon sideNavigate"
            onClick={toggleSidebar}>
              {isExpanded ? <Left /> : <Right />}
            </Button>
            {/* <button onClick={toggleSidebar}>
           {isExpanded ? 'Collapse' : 'Expand'}
         </button> */}
            {isExpanded && <FolderTree isExpanded={isExpanded} setInternalSearchValue={setInternalSearchValue} />}
          </Grid>
          <Grid item className={` ${
              isExpanded ? "expanded" : "collapsed"
            }`}>
            <Box className="midMenu">
              <Typography component="h2" variant="h5" className="heading tabView">
                Asset management
              </Typography>
              {/* <Typography component="h5" variant="h5">
                <Folder /> {clickedFolderZusNon?.label}
              </Typography> */}

              <Selector />
            </Box>

            <RightSectionImages setInternalSearchValue={setInternalSearchValue} internalSearchValue={internalSearchValue} />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default LandingPage