const apiEndpoints = {
  API_URL: process.env.REACT_APP_API,
  GET_LOGIN_TOKEN: "api/login_check",
  VIEW_PROFILE: "api/view-profile",
  GET_PERMISSIONS: "api/user-permissions",

  UPDATE_USER_PROFILE: "api/update-profile",

  GET_LOGIN_API_TOKEN: "api/login",
  GET_FEATURED_PRODUCTS: "api/rest/getfeaturedProducts",
  GET_FIRSTPAGE_LISTING: "api/assets",
  GET_DETAILPAGE_SINGLEASSET: "api/asset",
  VERSION_ONDETAIL_PAGE: "api/version-history",

  COMPARE_VERSIONS: "api/asset/compare-versions",
  DOWNLOAD_SINGLE_ASSET: "api/download-asset",

  PUBLISH_OTHER_VERSION: "api/asset/publish-version",
  SAVE_TAGS: "api/asset/save-tags",
  GET_COLLECTION_MODAL_TAGS: "api/list-tags",
  DELETE_ASSET: "api/delete-asset",
  UPLOAD_SINGLEASSET_FILE: "api/upload-asset",

  GET_FOLDERS: "api/list-folders",
  MOVE_TO_FOLDER: "api/relocate",
  GET_HEADER_LIST_FILTERS: "api/list-filters",
  RENAME_FOLDER: "api/rename-folder",
  DELETE_FOLDER: "api/delete-asset",
  ADD_FOLDER: "api/add-folder",
  UPLOAD_ASSET_FROM_URL: "api/upload-asset-from-url",
  UPLOAD_ZIP: "api/assets/upload-zip-file",
  UPDATE_ASSET_METADATA: "api/asset/metadata-update",
  ADD_TO_COLLECTION: "api/add-to-collection",
  GET_COLLECTION_LIST: "api/collection-list",
  DELETE_COLLECTION_LIST: "api/delete-collection",
  CREATE_NEW_COLLECTION: "api/create-collection",
  RENAME_COLLECTION: "api/rename-collection",
  GET_COLLECTION_DETAIL: "api/collection",
  DELETE_COLLECTION_DETAIL_ASSET: "api/remove-from-collection",
  BULK_DOWNLOAD_ASSET: "api/assets-bulk-download",
  ADD_TO_CART: "api/add-to-cart",
  USER_CART: "api/user-cart",
  DELETE_CART_ITEM: "api/delete-cart-item",
  CLEAR_CART: "api/clear-cart",
  BATCH_META_DATA_UPDATE: "api/asset/batch-metadata-update",
  BATCH_UPDATE: "api/asset/batch-update",
  CART_OPTIONS: "api/download-types",
  REPLACE_ASSET: "api/replace-asset",

  BULK_DOWNLOAD: "api/asset/custom-download",
  EDIT_CART_CUSTOM: "api/edit-cart",
  SAVE_FILTER: "api/save-filter",
  GET_SAVED_FILTERS: "api/all-filters",
  CART_DOWNLOAD_API: "api/download-cart",
  GET_DASHBOARD_API: "api/dashboard",
  DELETE_SAVED_FILTERS: "api/delete-filter",
  GET_LANGUAGE_API: "api/generic-list",
  PUBLIC_SHARE_API: "api/public-share",
  COLLECTION_SHARE_API: "api/collection/publicshare",

  GET_PUBLIC_SHARE_LIST: "api/public-share/list",

  GET_COLLECTION_PUBLIC_SHARE_LIST: "api/public-share/collectionlist",
 
  PUBLIC_SHARE_COLLECTION_API: "api/collection/public-share/list",

  PUBLIC_SHARE_HISTORY_API: "api/public-shared/list",

  PUBLIC_SHARE_COLLECTION_DELETE_API: "api/collection/public-share/delete",
  PUBLIC_SHARE_PS_DELETE_API:"api/public-shared/delete",
  PUBLIC_SHARE_DOWNLOAD_API:"api/public-share/download",
  PUBLIC_SHARE_COLLECTION_DOWNLOAD_API:"api/collection/public-share/download"
 
};

export default apiEndpoints;
