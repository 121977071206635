// pages/NotFoundPage.js
import { Box, Grid } from '@mui/material';
import React from 'react';
import DamHeader from '../components/DamHeader';
import LeftMenu from '../components/LeftMenu';
import Footer from '../components/Footer';

const NotFoundPage = () => {
  return (


    
    <Grid container className="container bg">
    <Grid item xs={1} className="menu">
      <LeftMenu />
    </Grid>

    <Grid
      item
      xs={12}
      sx={{ minHeight: "calc(100% - 50px)" }}
      className="rightPart"
    >
      <DamHeader />

      <Grid container className=" bodyPart">
       <Box className="pageNotFound">
      <h1>404 - Page Not Found</h1>
     <p>The page you are looking for does not exist.</p>
    </Box>

       
      </Grid>
    </Grid>

    <Grid xs={12}>
      <Footer />
    </Grid>
  </Grid>
  );
};

export default NotFoundPage;