


import { Box, Grid ,Divider, Typography, Button, Radio, Accordion, AccordionSummary, AccordionDetails} from "@mui/material";

import {ReactComponent as ArrowDown} from '../assest/svg/arrowDown.svg';


import FolderTree from "../components/FolderTree";
import RightSectionImages from "../components/RightSectionImages";
import LeftMenu from "../components/LeftMenu";
import DamHeader from "../components/DamHeader";
import Selector from "../components/Selector";
import Footer from "../components/Footer";
import {ReactComponent as ManageFolder} from '../assest/svg/ManageFolder.svg';
import {ReactComponent as LeftArrow} from '../assest/svg/arrowLeft.svg';
import {ReactComponent as RightArrow} from '../assest/svg/arrowRight.svg';
import {ReactComponent as Folder} from '../assest/svg/folder.svg';
import {ReactComponent as ChevronBack} from '../assest/svg/chevronLeft.svg';
import {ReactComponent as ChevronNext} from '../assest/svg/chevronRight.svg';
import { useState } from "react";
import DamPortalModal from "../components/DamPortalModal";
import SortMenu from "../components/sortMenu";
import Select from 'react-select';


const ManageFolderPage = () => {
  const [manageFolderTreeItemClicked, setManageFolderTreeItemClicked] =
    useState();
  const [isRenameModal, setIsRenameModal] = useState(false);
  const [isDeletefolderModal, setIsDeletefolderModal] = useState(false);
  const [isAddfolderModal, setIsAddfolderModal] = useState(false);

  const [manageFolderId, setManageFolderId] = useState(false);
  const [targetObjectFolder, setTargetObjectFolder] = useState({});
  const [manageFolderLabel, setManageFolderLabel] = useState(false);
  const findObjectById = (id, data) => {
    for (const item of data) {
      if (item.id === id) {
        return item; // Found at the top level
      }
      if (item.children && item.children.length > 0) {
        const nestedResult = findObjectById(id, item.children);
        if (nestedResult) {
          return nestedResult; // Found in nested children
        }
      }
    }
    return null; // Not found
  };

  const setManageFolderTreeItemFn = (label, id, folderStructure) => {
    setManageFolderId(id);

    setManageFolderLabel(label);
    const targetObject = findObjectById(id, folderStructure);
    setTargetObjectFolder(targetObject);
  
   
    setManageFolderTreeItemClicked(true);
  };

  const handleRenameModal = () => {
    // setAssetId(id);
    setIsRenameModal(true);
  };

  const handleDeleteFolderModal = () => {
    // setAssetId(id);
    setIsDeletefolderModal(true);
  };
  const handleAddFolderModal = () => {
    // setAssetId(id);
    setIsAddfolderModal(true);
  };
  const handleCloseModal = () => {
    setIsRenameModal(false);
    setIsDeletefolderModal(false);
    setIsAddfolderModal(false);
  };

  return (
    <Grid container className="container">
      <Box>
        <DamPortalModal
          open={isRenameModal}
          onClose={handleCloseModal}
          titleMessage={"Rename folder"}
          // // node={selectedNode}
          isRenameModal={isRenameModal}
          manageFolderId={manageFolderId}
          manageFolderLabel={manageFolderLabel}
          // moveTofolderModalFlag={true}
          // assetId={assetId}
        />
      </Box>
      <Box>
        <DamPortalModal
          open={isDeletefolderModal}
          onClose={handleCloseModal}
          titleMessage={"Delete folder"}
          // // node={selectedNode}
          isDeletefolderModal={isDeletefolderModal}
          manageFolderId={manageFolderId}
          manageFolderLabel={manageFolderLabel}
          // moveTofolderModalFlag={true}
          // assetId={assetId}
        />
      </Box>
      <Box>
        <DamPortalModal
          open={isAddfolderModal}
          onClose={handleCloseModal}
          titleMessage={"Add Folder"}
          // // node={selectedNode}
          isAddfolderModal={isAddfolderModal}
          manageFolderId={manageFolderId}
          manageFolderLabel={manageFolderLabel}
        />
      </Box>

      <Grid container className="container bg" >
        <Grid item xs={1} className="menu">
          <LeftMenu />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ minHeight: "calc(100% - 50px)" }}
          className="rightPart"
        >
          <DamHeader />
          <Grid container className="bodyPart">
            <Grid item xs={7} className="assetDetailLeft">
              <Box className="headinAlign">
                <Typography component="h2" variant="h5">
                  Manage Folder
                </Typography>
                <Box className="buttonGroup">
                  <Button
                    size="small"
                    variant="filled"
                    startIcon={<ManageFolder />}
                    className="button"
                    disabled={!manageFolderTreeItemClicked}
                    onClick={() => handleAddFolderModal()}
                  >
                    Add Folder
                  </Button>
                </Box>
              </Box>
              {/* 
            <Box sx={{ borderBottom: '1px solid var(--border)'}}>
             <SortMenu />
            </Box> */}

              <Box className="manageFolder">
                <FolderTree
                  setManageFolderTreeItemFn={setManageFolderTreeItemFn}
                  managefolderPageFlag={true}
                />
              </Box>
            </Grid>
            <Grid item xs={5} className="mL">
              <Box
                className="headinAlign inner justifyBetween">
                {manageFolderTreeItemClicked && (
                  <Typography component="h5" variant="h5">
                    <Folder /> {manageFolderLabel}
                  </Typography>
                )}

                <Box className="buttonGroup">
                  <Button
                    onClick={() => handleRenameModal()}
                    size="small"
                    variant="filled"
                    disabled={!manageFolderTreeItemClicked}
                    startIcon={<ManageFolder />}
                    className="button"
                  >
                    Rename
                  </Button>

                  <Button
                    size="small"
                    variant="filled"
                    startIcon={<ManageFolder />}
                    className="button"
                    disabled={!manageFolderTreeItemClicked}
                    onClick={() => handleDeleteFolderModal()}
                  >
                    Delete folder
                  </Button>
                </Box>
              </Box>
              {manageFolderTreeItemClicked && (
                <Box sx={{ padding: "1em", marginTop: "var(--sideSet)" }}>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ArrowDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography component="b">Overview</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      Creation date : {targetObjectFolder?.creationDate}
                      <Box>
                        Modification date :
                        {targetObjectFolder?.modificationDate}
                      </Box>
                      {/* <button onClick={handleSelectAll}>Select All</button> */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ArrowDown />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography component="b">Tags</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {targetObjectFolder?.assignedTags &&
                        targetObjectFolder?.assignedTags?.length > 0 && (
                          <Select
                            isMulti
                            options={targetObjectFolder?.assignedTags}
                            value={targetObjectFolder?.assignedTags}
                            isDisabled={true}
                          />
                        )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManageFolderPage;