

import React, { useState } from 'react';
import { Box, Button, Typography, InputLabel, TextField, Select, Tooltip } from '@mui/material';
import { ReactComponent as ArrowLeft } from "../assest/svg/chevronLeft.svg";
import { ReactComponent as ArrowRight } from "../assest/svg/chevronRight.svg";
import { ReactComponent as KeyboardRight } from "../assest/svg/keyboardRight.svg";
import { ReactComponent as KeyboardLeft } from "../assest/svg/keyboardLeft.svg";


import usePagination, { DOTS } from '../customHooks/usePagination';
import { PatternFormat } from "react-number-format";
import { showErrorToast } from '../assest/Toaster';
import { withStyles} from "@material-ui/core/styles";


const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);

const PaginationComponent = ({ 
  currentPage, 
  setCurrentPage, 
  totalCount, 
  pageSize, 
  setPageSize, 
  onPageChange, 
  goToPage,
  itemCount
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize
  })
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState()

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null
  }

  const onNext = () => {
  
    if(currentPage===paginationRange[paginationRange?.length - 1]){
      return
    }else{
      onPageChange(currentPage + 1)
    }
   
   
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  const onFirst = () => {
    if (currentPage === 1) {
      return;
    } else {
      onPageChange(1);
    }
  };

  const onLast = () => {
    if (currentPage === paginationRange[paginationRange?.length - 1]) {
      return;
    } else {
      onPageChange(paginationRange[paginationRange?.length - 1]);
    }
  };

  const getMask = () => {
    if (paginationRange?.length) {
      const pageRange = Array.from(String(paginationRange[paginationRange?.length - 1]))
      const result = pageRange?.map((value) => "#")
      return result.join("")
    } else {
      return "#"
    }
  }

  const pageSizeOptions = [
    {
      label: 10,
      value: 10
    },
    {
      label: 20,
      value: 20
    },
    {
      label: 30,
      value: 30
    }
  ]

  const onPageSizeChanged = (e) => {
    if(e){
      setPageSize(e.target.value)
      setCurrentPage(1)
    }
  }

  const renderPageToPageSection = () => {
    return (
      <p className='showingEntry'>
        Showing{" "}
        {itemCount < pageSize
          ? totalCount - itemCount === 0
            ? `1`
            : `${totalCount - itemCount}`
          : `${currentPage * itemCount - itemCount + 1}`}{" "}
        to{" "}
        {itemCount < pageSize ? `${totalCount}` : `${currentPage * itemCount}`}{" "}
        of {totalCount} items
      </p>
    )
  }

  let lastPage = paginationRange && paginationRange[paginationRange?.length - 1]


  return (
    <div className="pagination">
      {renderPageToPageSection()}
      <ul>
        <li className={(currentPage==1)?`disabled` : ""} key={Math.random()} onClick={onFirst}>
          {/* First Page */}
          <TooltipStyle title="First page" placement="top" arrow><KeyboardLeft  width={20}/></TooltipStyle>
        </li>
        <li key={Math.random()} className={(currentPage==1)?`disabled` : ""} onClick={onPrevious}>
        <TooltipStyle title="Previous" placement="top" arrow><ArrowLeft className="svg" width={20} /></TooltipStyle>
        </li>
        {paginationRange?.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <div className="pagination-item dots" key={`dots-${Math.random()}`}>
                <li key={`dots-${Math.random()}`}>&#8230;</li>
              </div>
            );
          }
          return (
            <li className={currentPage === pageNumber ? "active" : ""} key={pageNumber} onClick={() => onPageChange(pageNumber)}>
              {pageNumber}
            </li>
          );
        })}

        <li className={(currentPage===paginationRange[paginationRange?.length - 1])?`disabled` : ""} key={Math.random()} onClick={onNext}>
        <TooltipStyle title="Next" placement="top" arrow><ArrowRight  className="svg" width={20} /></TooltipStyle>
        </li>
        <li className={(currentPage===paginationRange[paginationRange?.length - 1])?`disabled` : ""} key={Math.random()} onClick={onLast}>
        <TooltipStyle title="Last page" placement="top" arrow><KeyboardRight width={20}/></TooltipStyle>
        </li>
          {/* <select value={{ label : pageSize, value : pageSize}} handleChange={onPageSizeChanged}>
            {pageSizeOptions?.map(({value, label})=> 
              <option value={value}>{label}</option>
            )}
          </select> */}

        {/* <div className="flex divider gotopage ">
          <InputLabel>Go To Page</InputLabel>
          <PatternFormat
            value={searchText}
            valueIsNumericString
            format={`${getMask()}`}
            mask=" "
            customInput={TextField}
            allowLeadingzeros={false}
            id="outlined-start-adornment"
            size="small"
            autoComplete="off"
            className="range"
            placeholder='Go to page'
            onChange={(e) => {
              e.preventDefault();
              const { value } = e.target;
              setSearchText(value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                let totalPages = paginationRange[paginationRange.length - 1];
                if (parseInt(e.target.value) <= parseInt(totalPages)) {
                  goToPage(parseInt(searchText));
                  setSearchClicked(!searchClicked);
                } else {
                  showErrorToast("Please select valid page number");
                }
              }
            }}
          />
        </div> */}
      </ul>
    </div>
  );
};

export default PaginationComponent;
