import { Box, Grid ,Divider, Typography, Paper, Stack, TableRow, TableContainer, Table, TableHead, TableCell, TableBody, Chip, Button} from "@mui/material";

import RightSectionImages from "../components/RightSectionImages";
import LeftMenu from "../components/LeftMenu";
import DamHeader from "../components/DamHeader";
import Selector from "../components/Selector";
import Footer from "../components/Footer";
import {ReactComponent as ArrowDown} from '../assest/svg/arrowdownDescending.svg';
import  AssetsDelete from '../assest/images/AssetsDelete.png';
import  AssetsDownload from '../assest/images/AssetsDownload.png';
import  AssetsShare from '../assest/images/AssetsShare.png';
import  TotalAssets from '../assest/images/TotalAssets.png';
import { getDashboardData } from "../api/getRequest";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { withStyles} from "@material-ui/core/styles";
import {ReactComponent as Noimage} from '../assest/svg/NoImage.svg';
import {ReactComponent as PdfIcon} from '../assest/svg/pdf.svg';
import {ReactComponent as HtmlIcon} from '../assest/svg/html.svg';
import {ReactComponent as PsdIcon} from '../assest/svg/psd.svg';
import {ReactComponent as CsvIcon} from '../assest/svg/csv.svg';
import {ReactComponent as SvgIcon} from '../assest/svg/svg.svg';
import {ReactComponent as TiffIcon} from '../assest/svg/tiff.svg';
import {ReactComponent as EpsIcon} from '../assest/svg/eps.svg';
import {ReactComponent as Mp4Icon} from '../assest/svg/mp4.svg';
import { PieChart, Pie, Tooltip, Cell,Legend } from 'recharts';
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";

const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);
const Dashboard = () => {
 const [pieChartData,setPieChartData]= useState()
 const navigate = useNavigate();
 const [combinedDashboardData,setCombinedDashboardData]= useState()

 const Bullet = ({ backgroundColor, size }) => {
  return (
    <div
      className="CirecleBullet"
      style={{
        backgroundColor,
        width: size,
        height: size
      }}
    ></div>
  );
};
  const getDashboardDataMutation = useMutation(
    (post) => getDashboardData(post),
    {
      mutationKey: "getHeaderListFiltersI",
      method: "GET",
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            const updatedData = result?.data?.data?.assetTypeCount?.map(
              (item) => {
                // Replace "label" key with "name"
                return {
                  ...item,
                  name: item?.label,
                };
              }
            );
            setCombinedDashboardData(result?.data?.data)
            setPieChartData(updatedData);
          } else {
          }
        }
      },
      onError: (error) => {},
    }
  );
  const CustomizedLegend = (props) => {
    const { payload } = props;
    return (
      <ul className="LegendList">
        {payload?.map((entry, index) => (
          <li key={`item-${index}`}>
            <div className="BulletLabel">
              <Bullet backgroundColor={entry.payload.fill} size="10px" />
              <div className="BulletLabelText">{entry.value}</div>
            </div>
            <div style={{ marginLeft: "20px" }}>{entry.payload.value}</div>
          </li>
        ))}
      </ul>
    );
  };
  
  const handleNavigation = (id) => {
    navigate({
      pathname: "/detailPage",
      search: `id=${id}`,
    });
  
    
  };

  useEffect(() => {
    getDashboardDataMutation.mutate()
  }, [])
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF00FF', '#00FF00'];
  return (
    <>
      {getDashboardDataMutation.isLoading && <Loader></Loader>}
      <Grid container className="container" spacing="0">
        <Grid item className="menu">
          <LeftMenu />
        </Grid>
        <Grid
          item
          sx={{ minHeight: "calc(100% - 50px)" }}
          className="rightPart"
        >
          <DamHeader />
          <Grid container className="bodyPart">
            <figure className="heroImage">
              <img src="https://img.freepik.com/free-photo/man-using-tablet-work-connect-with-others_23-2149369110.jpg" />

              <Grid
                container
                className="heroHighlight"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item xs={3}>
                  <Typography
                    variant="h4"
                    component="h4"
                    className="heading"
                    sx={{ color: "var(--white)" }}
                  >
                    Dashboard{" "}
                  </Typography>
                </Grid>
                <Grid item xs={9} className="text-right">
                  {/* <Selector /> */}
                </Grid>
           

                {pieChartData?.map((obj, index) => (
                  <Grid item xs={3}>
                
                    <Paper data-color="primary" className="heroBox">
                      <Typography>{obj?.label}</Typography>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        mt={2}
                      >
                        <Box>
                          <Typography variant="h5" component="h5">
                            {obj?.value}
                            
                          </Typography>
                          <small className="flex">
                            {/* <ArrowDown width="15" /> 64.9% */}
                          </small>
                        </Box>
                        <Box className="heroIcon">
                          <img src={TotalAssets} />
                        </Box>
                      </Stack>
                    </Paper>
                  </Grid>
                ))}

                {/* <Grid item xs={3}>
                  <Paper data-color="download" className="heroBox">
                    <Typography>Total Assets Downloaded</Typography>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      mt={2}
                    >
                      <Box>
                        <Typography variant="h5" component="h5">
                          {" "}
                          416
                        </Typography>
                        <small className="flex">
                          <ArrowDown width="15" /> 44.4%
                        </small>
                      </Box>
                      <Box className="heroIcon">
                        <img src={AssetsDownload} />
                      </Box>
                    </Stack>
                  </Paper>
                </Grid> */}
                {/* <Grid item xs={3}>
                <Paper data-color="shared" className="heroBox">
                  <Typography>Assets Shared</Typography>
                  <Stack direction="row" justifyContent="space-between" mt={2}>
                    <Box>
                      <Typography variant="h5" component="h5">
                        {" "}
                        416
                      </Typography>
                      <small className="flex">
                        <ArrowDown width="15" /> 44.4%
                      </small>
                    </Box>
                    <Box className="heroIcon">
                      <img src={AssetsShare} />
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper data-color="error" className="heroBox">
                  <Typography>Deprecated Assets</Typography>
                  <Stack direction="row" justifyContent="space-between" mt={2}>
                    <Box>
                      <Typography variant="h5" component="h5">
                        {" "}
                        416
                      </Typography>
                      <small className="flex">
                        <ArrowDown width="15" /> 44.4%
                      </small>
                    </Box>
                    <Box className="heroIcon">
                      {" "}
                      <img src={AssetsDelete} />
                    </Box>
                  </Stack>
                </Paper>
              </Grid> */}
              </Grid>
            </figure>

            <Grid container spacing={1} sx={{ padding: "20px" }}>
              <Grid item xs={4}>
                <Box className="location">
                <Typography
                    variant="h5"
                    component="h5"
                    className="heading flex justifyBetween"
                  >
                  Report
                    {/* <Button className="button link">Show all list</Button> */}
                  </Typography>
                  <PieChart width={400} height={400}>
                    <Pie
                      dataKey="value"
                      isAnimationActive={false}
                      data={pieChartData}
                      cx={200}
                      cy={200}
                      outerRadius={80}
                      fill="#8884d8"
                      label
                      // paddingAngle={2}
                    >
                      {pieChartData?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS?.length]}
                        />
                      ))}
                    </Pie>
                    <Legend content={<CustomizedLegend />} />
                    <Tooltip formatter={(value, name) => [value, name]} />
                  </PieChart>

                  {/* <Typography variant='h5' component="h5" className='heading flex justifyBetween'>Assets Location 
            <Button className="button link">Show all list</Button>
            </Typography>  */}
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="location">
                  <Typography
                    variant="h5"
                    component="h5"
                    className="heading flex justifyBetween"
                    mb={3}
                  >
                  Recent Added Data
                    {/* <Button className="button link">Show all list</Button> */}
                  </Typography>

                  <TableContainer>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        className="table"
                        
                      >
                        <TableHead>
                          <TableRow>
                         
                          {/* <TableCell component="th" width="40%">
                              Namescscs
                            </TableCell> */}
                            <TableCell component="th" width="40%">
                              Name
                            </TableCell>
                            <TableCell component="th" width="15%">
                              Mime Type
                            </TableCell>
                            <TableCell component="th" width="15%">
                              Size
                            </TableCell>
                            <TableCell component="th" width="15%">
                              Date
                            </TableCell>
                      
                          </TableRow>
                        </TableHead>
                        <TableBody
                          sx={{
                            "& .button": {
                              border: 0,
                              margin: "2px !important",
                            },
                            "& img": {
                              height: "60px",
                              maxWidth: "60px",
                              objectFit: "cover",
                            },
                          }}
                        >
                          {combinedDashboardData?.recentAssets?.map((imgObj) => (
                            <TableRow
                              tabIndex={-1}
                             
                            >
                             
                              {/* <TableCell key={imgObj?.id}>
                                <TooltipStyle
                                  title={`${imgObj?.name}`}
                                  placement="bottom"
                                  arrow
                                >
                                  {imgObj?.mimetype?.length === 0 ? (
                                    <>
                                      <Noimage
                                        height={70}
                                        className="svgNoImage"
                                      />
                                    </>
                                  ) : imgObj?.mimetype == "application/pdf" ? (
                                    <>
                                      {" "}
                                      <PdfIcon
                                        height={70}
                                        className="svgNoImage"
                                      />
                                    </>
                                  ) : imgObj?.mimetype == "text/html" ? (
                                    <>
                                      {" "}
                                      <HtmlIcon
                                        height={70}
                                        className="svgNoImage"
                                      />
                                    </>
                                  ) : imgObj?.mimetype == "text/csv" ? (
                                    <>
                                      {" "}
                                      <CsvIcon height={120} />
                                    </>
                                  ) : imgObj?.mimetype == "video/mp4" ? (
                                    <>
                                      {" "}
                                      <Mp4Icon height={120} />
                                    </>
                                  ) : imgObj?.mimetype ==
                                    "application/postscript" ? (
                                    <>
                                      {" "}
                                      <EpsIcon height={120} />
                                    </>
                                  ) : imgObj?.mimetype == "image/tiff" ? (
                                    <>
                                      {" "}
                                      <TiffIcon height={120} />
                                    </>
                                  ) : imgObj?.mimetype == "image/svg+xml" ? (
                                    <>
                                      {" "}
                                      <SvgIcon height={120} />
                                    </>
                                  ) : imgObj?.mimetype ==
                                    "image/x-photoshop" ? (
                                    <>
                                      {" "}
                                      <PsdIcon height={120} />
                                    </>
                                  ) : (
                                    <img
                                      src={`${"http://"}${"dam-api.pimcoredemos.com"}${
                                        imgObj?.path
                                      }${imgObj?.name}`}
                                    />
                                  )}
                                </TooltipStyle>

                              </TableCell> */}
                              <TableCell
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleNavigation(imgObj?.id);
                                }}
                              >
                                <Typography variant="p">
                                  {imgObj?.name.charAt(0).toUpperCase() +
                                    imgObj?.name.slice(1)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="p">
                                  {imgObj?.mimetype}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="p">
                                  {imgObj?.size}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="p">
                                  {imgObj?.modificationDate}
                                </Typography>
                              </TableCell>
                            
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                </Box>
              </Grid>

              <Grid item xs={12}>
              
                {/* <Box className="location">
                  <Typography
                    variant="h5"
                    component="h5"
                    className="heading flex justifyBetween"
                  >
                    Assets Location
                    <Button className="button link">Show all list</Button>
                  </Typography>

                  <TableContainer>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      className="table mt"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell component="th"></TableCell>

                          <TableCell component="th">Name</TableCell>
                          <TableCell component="th">Filename</TableCell>
                          <TableCell component="th">Tags</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell width="10%">
                            <img className="responsive" />
                          </TableCell>
                          <TableCell>
                            <Typography>Alfa Romeo 1900C Series 2</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              110_ans_de_l-27automobile_au_Grand_Palais_-Alfa_Romeo_1900C_Series_2_Sprint_Coup-C3-A9_-_1954_-_008.jpg
                            </Typography>
                          </TableCell>
                          <TableCell width="18%">
                            <Chip label="Chip" variant="outlined" />
                            <Chip label="test" variant="outlined" />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard