import {
  Badge,
  Box,
  Button,
  Grid,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography, Modal, MenuItem, TextareaAutosize, 
} from "@mui/material";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import {ReactComponent as LeftArrow} from '../assest/svg/arrowLeft.svg';
import {ReactComponent as RightArrow} from '../assest/svg/arrowRight.svg';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as ArrowDown } from "../assest/svg/arrowDown.svg";
import { useNavigate } from "react-router-dom";
import {ReactComponent as Close} from '../assest/svg/cross.svg';
import CustomMoment from 'moment-timezone';
import {
  compareVersions,
  detailPageVersion,
  getLanguageApi,
  publishOtherVersion,
  saveTags,
  updateAssetMetadata,
} from "../api/postRequest";
import { useMutation } from "@tanstack/react-query";
import { ReactComponent as CheckIcon } from "../assest/svg/radiochecked.svg";
import { ReactComponent as View } from "../assest/svg/view.svg";
import { ReactComponent as Compare } from "../assest/svg/compare.svg";
import { ReactComponent as Save } from "../assest/svg/save.svg";
import { ReactComponent as Delete } from "../assest/svg/deleteicon.svg";

import { ReactComponent as ChevronDown } from "../assest/svg/chevronDown.svg";

import { showErrorToast, showSuccessToast } from "../assest/Toaster";
import Loader from "./Loader";
import Select from "react-select";
import { useNonPersistedStore } from "../store/nonPersistedStore";
import { ReactComponent as Edit } from "../assest/svg/editicon.svg";
import {withStyles} from "@material-ui/core/styles";
import DamPortalModal from "./DamPortalModal";
import CompareVersionPage from "../pages/CompareVersionPage";
import commonMessages from "../utils/commomMessages";


const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);

const customStyles = {
  control: base => ({
    ...base,
    height: 31,
    minHeight: 31,
    fontSize: '14px',
    margin: '0 10px'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    height: 24,
}),
clearIndicator: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    height: 24,
}),
// menuPortal: (base) => ({ ...base, zIndex: 9999 })
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DetailedDescription = ({ detailPageData ,callbackSingleAssetApiFn}) => {
  const [value, setValue] = React.useState(0);
  const [nameError, setNameError] = useState(false);

  const [idversionView, setIdversionView] = useState();
  
  const [versionCheckedId, setVersionCheckedId] = useState();
  const [compareVersionModal, setCompareVersionModal] = useState(false);
  const [versionApiData, setVersionApiData] = useState();
  const [languageOptions, setLanguageOptions] = useState();
  
  const [selectedValues, setSelectedValues] = useState(
    detailPageData?.assignedTags
  );
  const [metadata, setMetaData] = useState(detailPageData?.metadata);
  const [isMetadataEdit, setIsMetaDataEdit] = useState(false);
  const { setEditAssetZusNon, editAssetZusNon, clickedFolderZusNon,setVersionDataZusNon,versionDataZusNon} = useNonPersistedStore();
  let options = detailPageData?.tags;
  const handleSelectAll = () => {
    setSelectedValues(options);
  };
  const [newMetaData, setNewMetaData] = useState(
    {
      idx: Math.random(),
      name: "",
      language: "",
      type: "",
      data: ""
    })

  // const languageOptions = [
  //   { label: "en", value: "en" },
  //   { label: "french", value: "fe" },
  //   { label: "Japanese", value: "pb" },
  // ];
  const inputOptions = [
    { label: "Input", value: "input" },
    { label: "Checkbox", value: "checkbox" },
    { label: "TextArea", value: "textarea" },
    { label: "Date", value: "date" },
  ];


  const handleOnChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
  };

  const navigate = useNavigate();
  const mutation = useMutation((post) => detailPageVersion(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          let output = result.data?.data;
          const newArray = output?.map((obj) => ({
            ...obj,
            ["isChecked"]: false,
          }));

          setVersionDataZusNon(newArray);
        } else {
        }
      }
    },
    onError: (error) => {},
  });

  const getLanguageApiMutation = useMutation((post) => getLanguageApi(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          
          setLanguageOptions(result?.data?.data?.languages)
        } else {
        }
      }
    },
    onError: (error) => {},
  });

  const mutationPublishedVersion = useMutation(
    (post) => publishOtherVersion(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            mutation.mutate({ assetId: detailPageData?.id });
            setVersionCheckedId(null);
           
            showSuccessToast("Version Published Succesfully");
          } else {
          }
        }
      },
      onError: (error) => {},
    }
  );

  useEffect(() => {
    mutation?.mutate({ assetId: detailPageData?.id });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = () => {
  
  };

  const handleDelete = () => {
  
  };

  const handleCompare = (svg,id) => {
    if(svg=="svg"){
      setIdversionView(id)
      // setSvgViewClick(true)
    } else{
      setIdversionView(null)
    }
   
    setCompareVersionModal(true)
    // navigate({
    //   pathname: "/compareVersionPage",
    //   search: `id=${[...versionCheckedId]}`,
    // });
  };

  const handleCheckbox = (e, idx) => {
    let trackChecked = versionDataZusNon?.map(item =>
  item.id === idx ? { ...item, isChecked: !item.isChecked } : item
    );
   
    let checkedCount = trackChecked.filter(item => item.isChecked).length;
   
    if (checkedCount > 3) {
      e.preventDefault(); // Prevent checking the checkbox if already at maximum
      return;
    }
   
  let arr = trackChecked.filter(item => item.isChecked).map(item => item.id);
    setVersionCheckedId(arr);
    setVersionDataZusNon(trackChecked);
  };
  
  const handlePublishOtherVersion = (id) => {
    
     mutationPublishedVersion.mutate({ versionId: id });

  };


  const mutationUpdateAssetMetadata = useMutation(
    (post) => updateAssetMetadata(post, detailPageData?.id),
    {
      onSuccess: (result) => {
        if(result?.data?.success)
        {
         callbackSingleAssetApiFn()
         setIsMetaDataEdit(!isMetadataEdit)
        }
        else{
          showErrorToast(result.data.message)
        }
      },
      onError: (error) => {
      },
    }
  );

  
  const mutationCompareVersion = useMutation((post) => compareVersions(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          setVersionApiData(result.data.data);
       
        } else {
        }
      }
    },
    onError: (error) => {},
  });
  const tableHeadings = versionApiData && Object.keys(versionApiData);

  // Extract all unique property keys dynamically
  const allPropertyKeys = versionApiData && Array.from(
    new Set(tableHeadings.flatMap((key) => Object.keys(versionApiData[key])))
  );

  const handleSaveMetadata = () => {
    let payload = JSON.stringify({ metadata: { values: metadata } });
    mutationUpdateAssetMetadata.mutate(payload);
  };

  const handleInputChange = (val, item, index, key) => {

      if (key === "name" && val.trim() === "") {
        return; // Exit the function without updating the state
      }
      
      let updatedItem = { ...item, [key]: val };
  
      let res = [...metadata];
      if (key != "delete") {
        res.splice(index, 1, updatedItem);
        setMetaData(res);
      } else {
        res.splice(index, 1);
  
        setMetaData(res);
      }

    
    
   
  };


  

  const handleEditMetaDataBtn = () => {
    setIsMetaDataEdit(!isMetadataEdit);
  };
 const handleMetaDataCancelBtn = () => {
   setIsMetaDataEdit(!isMetadataEdit);
   setMetaData(detailPageData?.metadata);
 };
 const handleMetaDataSaveBtn = () => {
   const result = metadata?.map(({ idx, ...rest }) => ({ ...rest }));
 
   result?.forEach((obj) => {
     // check if the type is "date"
     if (obj.type === "date") {
   
       // Modify the data key using Moment.js to remove the time part
       if(obj?.data){
        obj.data = CustomMoment(obj?.data)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD");
       }
      
     }
   });
 
   let payload = JSON.stringify({ metadata: { values: result } });

   
   mutationUpdateAssetMetadata.mutate(payload);
 };

 const handleClose = () => {
   setCompareVersionModal(false);
   setIdversionView(null);
 };

 const handleAddMetadata = () => {
   if (!newMetaData?.name) {
     setNameError(true);
     // Display an error message or take appropriate action

     return; // Exit the function if the Name field is empty
   }

   setNameError(false);
   setMetaData([...metadata, newMetaData]);
 };

 const [dateToggle,setDateToggle]=useState(true)
 const handleInputChangeToCallNewName = (e) => {
  let value = e.target.value;

  // Prevent space as the first character
  if (value.startsWith(' ')) {
    value = value.slice(1); // Remove the leading space
  }

  // Limit the length to 50 characters
  value = value.slice(0, 50);

  handleChangeNewMetaData(value, "name");
};


const handleChangeNewMetaData = (inputValue, field) => {
 
  setNewMetaData({...newMetaData, [field]: inputValue})
  if(field==="language"){
  
    setNewMetaData({...newMetaData, [field]: inputValue?.value})
  }  if(field==="type"){
    setNewMetaData({...newMetaData, [field]: inputValue?.value})
  }   

}
useEffect(() => {
  getLanguageApiMutation.mutate()

 
}, [])

useEffect(() => {
  if(compareVersionModal){
   if(idversionView) {

    mutationCompareVersion.mutate({ versionIds: idversionView});
   }else{
  
    mutationCompareVersion.mutate({ versionIds: versionCheckedId.join(',')});
   }
   
   
  } else{
   
    setIdversionView(null)
  }
}, [compareVersionModal,idversionView])

useEffect(() => {
  setMetaData(detailPageData?.metadata)


}, [detailPageData?.metadata])




  return (
    <>
      {mutationPublishedVersion.isLoading && <Loader></Loader>}
      {mutationUpdateAssetMetadata.isLoading && <Loader></Loader>}
      {mutationCompareVersion.isLoading && <Loader></Loader>}
     { mutation.isLoading &&   <Loader></Loader>}

      {!mutationCompareVersion.isLoading && (
        <Box>
          <Modal
            open={compareVersionModal}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className="big compare"
          >
            <Box className="modalWrapper">
              <Box className="modalContainer">
                <Typography component="h5" variant="h5" className="modaltitle">
                  {Object.keys(versionApiData ?? {})?.length > 1
                    ? "Compare version"
                    : "View version detail"}
                  <Close onClick={handleClose} />
                </Typography>

                <Box className="modalContent">
                  {versionApiData && (
                    <table className="compareTable table">
                      <thead>
                        <tr>
                          <th>Keys</th>
                          {tableHeadings &&
                            tableHeadings.map((heading, index) => (
                              <th key={heading}>
                                Version <span>{index + 1}</span> ({heading})
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {allPropertyKeys &&
                          allPropertyKeys?.map((property) => (
                            <tr key={property}>
                              <th>{property}</th>

                              {tableHeadings &&
                                tableHeadings.map((heading, columnIndex) => {
                                  const firstValue =
                                    versionApiData[tableHeadings[0]][property];
                                  const currentValue =
                                    versionApiData[heading][property];
                                  const isDifferent =
                                    firstValue !== currentValue;
                                  // const cellStyle = isDifferent
                                  //   ? { backgroundColor: "blue" }
                                  //   : {};

                                  return (
                                    <td
                                      key={heading}
                                      className={
                                        isDifferent ? "classSetBackground" : ""
                                      }
                                    >
                                      {property !== "thumbnail" && currentValue}
                                      {property === "fullPath" && (
                                        <img
                                          src={`https://dam-api.pimcoredemos.com${currentValue}`}
                                          // title="green iguana"
                                          // alt="green iguana"
                                          width="200px"
                                        />
                                      )}
                                      {property === "thumbnail" && (
                                        <img
                                          src={`https://dam-api.pimcoredemos.com${currentValue}`}
                                          // title="green iguana"
                                          // alt="green iguana"
                                          width="200px"
                                        />
                                      )}
                                    </td>
                                  );
                                })}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
      <Box sx={{ padding: "1em 1em 1em 1em", marginTop: "var(--sideSet)" }}>
        <Box className="tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* <Tab label="Overview" /> */}
            <Tab label="Metadata" />
            <Tab label="Embedded metadata" />
            <Tab label="Version" />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          {/* {metadata?.map((item, index) => ( */}
          <>
            <TableContainer className="mt">
              <Box className="flex justifyBetween">
                {isMetadataEdit ? (
                  <>
                    <div className="flex justifyBetween alignEnd col-12">
                      <div className="flex">
                        <FormControl>
                          <label className="small">Name</label>
                          <input
                            type="text"
                            placeholder="Name"
                            value={newMetaData?.name}
                            onChange={handleInputChangeToCallNewName}
                            // onChange={(e) =>
                            //   handleChangeNewMetaData(e.target.value, "name")
                            // }
                            className="textbox selection"
                            maxLength={50}
                          />
                        </FormControl>
                        <FormControl>
                          <label className="small pl1">Language</label>
                          <Select
                            onChange={(e) =>
                              handleChangeNewMetaData(e, "language")
                            }
                            options={languageOptions}
                            className="selection"
                            styles={customStyles}
                            menuPosition="fixed"
                            menuPlacement="bottom"
                            isClearable={true}

                            // value={targetObjectFolder?.assignedTags}
                          />
                        </FormControl>
                        <FormControl>
                          <label className="small pl1">Value</label>
                          <Select
                            onChange={(e) => handleChangeNewMetaData(e, "type")}
                            options={inputOptions}
                            className="selection"
                            styles={customStyles}
                            // menuIsOpen={true}
                            menuPosition="fixed"
                            menuPlacement="bottom"
                            isClearable={true}
                            // value={targetObjectFolder?.assignedTags}
                          />
                        </FormControl>
                      </div>

                      <Box className="buttonGroup">
                        <Button
                          onClick={() => handleAddMetadata()}
                          startIcon={<Close className="small transist" />}
                          className="button"
                        >
                          Add
                        </Button>
                        <Button
                          className="button"
                          startIcon={<Close className="small" />}
                          onClick={() => handleMetaDataCancelBtn()}
                        >
                          Cancel
                        </Button>{" "}
                        <Button
                          className="button"
                          variant="filled"
                          startIcon={<Save />}
                          onClick={() => handleMetaDataSaveBtn()}
                          disabled={metadata?.length == 0  || mutationUpdateAssetMetadata?.isLoading}
                        >
                          Save
                        </Button>
                      </Box>
                    </div>
                  </>
                ) : (
                  <>
                    {detailPageData?.permissions?.edit_asset && (
                      <Box className="buttonGroup col-12 justifyEnd">
                        <Button
                          className="button"
                          variant="filled"
                          startIcon={<Edit />}
                          onClick={() => handleEditMetaDataBtn()}
                        >
                          Edit
                        </Button>
                      </Box>
                    )}{" "}
                  </>
                )}
              </Box>
              {nameError && (
                <Box className="error-message">
                  {commonMessages?.namefieldErrorMsg}
                </Box>
              )}
              <Table
                stickyHeader
                aria-label="sticky table"
                className="table mt fixed"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ zIndex: "0" }} component="th">
                      Name
                    </TableCell>
                    <TableCell sx={{ zIndex: "0" }} component="th">
                      Language
                    </TableCell>
                    <TableCell sx={{ zIndex: "0" }} component="th">
                      Value
                    </TableCell>
                    <TableCell sx={{ zIndex: "0" }} component="th">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metadata && metadata?.length > 0 ? (
                    <>
                      {metadata?.map((item, index) => (
                        <>
                          <TableRow tabIndex={-1} key={index}>
                            <TableCell>
                              {isMetadataEdit ? (
                                <input
                                  maxLength={50}
                                  type="text"
                                  className="textbox"
                                  value={item?.name}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      item,
                                      index,
                                      "name"
                                    )
                                  }
                                />
                              ) : (
                                item?.name
                              )}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {isMetadataEdit ? (
                                <>
                                  <Select
                                    defaultValue={
                                      item?.language == "en"
                                        ? { label: "en", value: "en" }
                                        : item.language == "de"
                                        ? { label: "de", value: "de" }
                                        : item.language == "fr"
                                        ? { label: "fr", value: "fr" }
                                        : { label: "en", value: "en" }
                                    }
                                    //  className="select"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e?.value,
                                        item,
                                        index,
                                        "language"
                                      )
                                    }
                                    styles={customStyles}
                                    isClearable={true}
                                    // menuIsOpen={true}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    options={languageOptions}
                                    // value={targetObjectFolder?.assignedTags}
                                  />
                                </>
                              ) : // <input
                              //   type="text"
                              //   className="textbox"
                              //   value={item?.name}
                              //   onChange={(e) =>
                              //     handleInputChange(
                              //       e.target.value,
                              //       item,
                              //       index,

                              //       "name"
                              //     )
                              //   }
                              // />
                              item?.language == "" ? (
                                "en"
                              ) : (
                                item?.language
                              )}
                            </TableCell>
                            <TableCell>
                              {isMetadataEdit ? (
                                <>
                                  {" "}
                                  {item?.type == "checkbox" ? (
                                    <>
                                      <Checkbox
                                        // value={item.data  === 1 ? true :false}
                                        //  isChecked={item.data  === 1 ? true :false  }

                                        checked={item.data === 1} // Control checkbox state
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.checked ? 1 : 0,
                                            item,
                                            index,
                                            "data"
                                          )
                                        }
                                      />
                                    </>
                                  ) : item?.type == "textarea" ? (
                                    <>
                                      <TextareaAutosize
                                        className="textbox"
                                        maxLength={200}
                                        style={{
                                          maxHeight: "160px", // Limit the maximum height
                                          overflowY: "auto", // Add scrollbars if content exceeds max-height
                                          resize: "vertical", // Disable manual resizing
                                        }}
                                        // size="md"
                                        // name="Size"
                                        value={item?.data}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            item,
                                            index,
                                            "data"
                                          )
                                        }
                                      />
                                    </>
                                  ) : item?.type == "date" ? (
                                    <>
                                      <DatePicker
                                        showIcon
                                        toggleCalendarOnIconClick
                                        onFocus={(e) =>
                                          (e.target.readOnly = true)
                                        }
                                        // onChange={(date) => field.onChange(date ? date : "")}
                                        // onChange={(date) =>
                                        //   handleModificationDateChange(date)
                                        // }
                                        onChange={(date) =>
                                          handleInputChange(
                                            date,
                                            item,
                                            index,
                                            "data"
                                          )
                                        }
                                        selected={item?.data}
                                        inputMode="none"
                                        // maxDate={moment().toDate()}
                                        showYearDropdown
                                        yearDropdownItemNumber={60}
                                        scrollableYearDropdown
                                      />
                                    </>
                                  ) : (
                                    <input
                                      type="text"
                                      className="textbox"
                                      value={item.data}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          item,
                                          index,
                                          "data"
                                        )
                                      }
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {item?.type == "checkbox" ? (
                                    <> {item?.data} </>
                                  ) : // <Checkbox
                                  //   value={item.data}
                                  //   disabled={true}
                                  //   // isChecked={true}
                                  //   onChange={(e) =>
                                  //     handleInputChange(
                                  //       e.target.checked,
                                  //       item,
                                  //       index,
                                  //       "data"
                                  //     )
                                  //   }
                                  // />
                                  item?.type == "date" ? (
                                    <>
                                      <DatePicker
                                        disabled={dateToggle}
                                        showIcon={!dateToggle}
                                        toggleCalendarOnIconClick
                                        onFocus={(e) =>
                                          (e.target.readOnly = true)
                                        }
                                        selected={item?.data}
                                        value={item?.data}
                                        inputMode="none"
                                        showYearDropdown
                                        yearDropdownItemNumber={60}
                                        scrollableYearDropdown
                                      />
                                    </>
                                  ) : (
                                    <>{item?.data} </>
                                  )}
                                </>
                              )}
                            </TableCell>
                            <TableCell>
                              <TooltipStyle
                                placement="bottom"
                                arrow
                                title="Delete"
                              >
                                <Button
                                  size="small"
                                  disabled={!isMetadataEdit}
                                  className="button icon"
                                  onClick={(e) => {
                                    handleInputChange(
                                      e.target.value,
                                      item,
                                      index,
                                      "delete"
                                    );
                                  }}
                                >
                                  <Close className="small" />
                                </Button>
                              </TooltipStyle>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No data
                        </TableCell>
                      </TableRow>
                    </>
                  )}

                  {/* 
                   
                     
                   */}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TableContainer className="mt">
            <Table stickyHeader aria-label="sticky table" className="table">
              <TableHead>
                <TableRow>
                  <TableCell component="th">Name</TableCell>
                  <TableCell component="th">Attribute</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailPageData &&
                detailPageData?.embeddedMetaData?.length === 0 ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        No data
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {Object?.entries(detailPageData?.embeddedMetaData)?.map(
                      ([key, value]) => (
                        <>
                          <TableRow tabIndex={-1}>
                            <TableCell>{key}</TableCell>
                            <TableCell>{value}</TableCell>
                          </TableRow>
                        </>
                      )
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <TableContainer className="mt">
            {versionCheckedId && versionCheckedId?.length > 0 && (
              <Box sx={{marginBottom:"8px"}}>
                <Typography className="notification">
                  {commonMessages?.selectCheckboxestoCompareVersion}
                </Typography>
              </Box>
            )}

            {versionCheckedId && versionCheckedId?.length > 1 && (
              <>
                <Button
                  size="small"
                  onClick={() => handleCompare()}
                  className="button"
                  sx={{ margin: "1em 0", float: "right" }}
                  variant="filled"
                  startIcon={<Compare />}
                >
                  Compare Versions
                </Button>
              </>
            )}
            <Table stickyHeader aria-label="sticky table" className="table">
              <TableHead>
                <TableRow>
                  <TableCell component="th"></TableCell>
                  <TableCell component="th">ID</TableCell>
                  <TableCell component="th">Version date</TableCell>
                  {/* <TableCell component="th">Published</TableCell> */}
                  <TableCell component="th">Publish Version</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {versionDataZusNon && versionDataZusNon?.length > 0 ? (
                  <>
                    {versionDataZusNon?.map((item, index) => (
                      <TableRow tabIndex={-1}>
                        <TableCell>
                          {versionDataZusNon?.length !== 1 && (
                            <Checkbox
                              name="select all"
                              className="checkbox"
                              checked={item?.isChecked}
                              onClick={(e) => handleCheckbox(e, item?.id)}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <label>
                            <b>{item?.id}</b>
                            <br />
                            {item?.note}
                          </label>
                        </TableCell>
                        <TableCell>
                          <label>{item?.date}</label>
                        </TableCell>
                        {/* <TableCell>
                      {item?.published && <CheckIcon height="15px" />}
                    </TableCell> */}
                        <TableCell>
                          {item?.published && (
                            <CheckIcon height="15px" className="published" />
                          )}
                          {!item?.published && (
                            <>
                              <TooltipStyle
                                placement="bottom"
                                arrow
                                title="View version details"
                              >
                                <Button
                                  className="button icon"
                                  onClick={() => handleCompare("svg", item?.id)}
                                >
                                  <View />
                                </Button>
                              </TooltipStyle>
                              <TooltipStyle
                                placement="bottom"
                                arrow
                                title="Set this as published version"
                              >
                                <Button
                                  className="button icon"
                                  onClick={() => {
                                    handlePublishOtherVersion(item?.id);
                                  }}
                                >
                                  <Compare />
                                </Button>
                              </TooltipStyle>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No data
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default DetailedDescription;
