export const menuStylesUserNameAndSettings = {
  overflow: "visible",
  filter: "drop-shadow(0px 0px 8px rgba(0,0,0,0.12))",
  mt: 1,
  borderRadius: 0,
  minWidth: "500px",
  "& .MuiPaper-root": {
    borderRadius: "0",
    border: "1px solid var(--border)",
    //  minWidth: "150px ",
  },
  "& .MuiList-root": {
    padding: 0,
    color: "var(--text)",
    justifyContent:"flex-start",
    "& li": {
        justifyContent:"flex-start",
        padding: '1em'
      },
    "& svg": {
      marginRight: "12px",
      fill: "var(--text)",
      width: "16px",
      height: '16px'
    },
    "& li:first-child svg":{
      width: '32px',
      height: '32px'
    }
  },
  "& .MuiMenuItem-root": {
    borderBottom: "1px solid var(--border)",
    fontSize: "14px",
  },
  "& .MuiChip-root": {
    borderRadius: 0,
    padding: 0,
    fontSize: "12px",
    height: "auto",
    width: "50px",
    marginLeft: "auto",
  },
  "& .MuiPopover-paper":{
    border: '0',
    "& ul":{
      border: '1px solid var(--border)',
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "relative",
      top: '11px',
      right: '-88%',
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    }
  }
};

export const paperPropsStylesUSerNAmeAndSettings = {
  elevation: 0,
  sx: menuStylesUserNameAndSettings,
};
