import React, { useEffect, useState } from "react";
import { ReactComponent as Share } from "../assest/svg/shareicon.svg";
import { ReactComponent as Media } from "../assest/svg/mediaicon.svg";
import { ReactComponent as Shop } from "../assest/svg/shopicon.svg";
import { ReactComponent as Download } from "../assest/svg/downloadicon.svg";
import { ReactComponent as Edit } from "../assest/svg/editicon.svg";
import { ReactComponent as Move } from "../assest/svg/moveicon.svg";
import { ReactComponent as Delete } from "../assest/svg/deleteicon.svg";
import { ReactComponent as Sort } from "../assest/svg/sort.svg";
import { ReactComponent as More } from "../assest/svg/more.svg";
import { ReactComponent as CardView } from "../assest/svg/cardview.svg";
import { ReactComponent as ListView } from "../assest/svg/listView.svg";
import {ReactComponent as ChevronBack} from '../assest/svg/chevronLeft.svg';
import {ReactComponent as DownArrow} from '../assest/svg/chevronDown.svg';
import {ReactComponent as Cross} from '../assest/svg/cross.svg';
// import { menuStyles, paperPropsStyles } from "../styledComponents/MenuStyles.js"; 
import { menuStyles, paperPropsStyles } from "../styledComponents/MenuStyles"; 
import { menuStylesThreeDots, paperPropsStylesThreeDotsLAndingPAge } from "../styledComponents/MenuStylesThreeDotsCardLandingPage"; 

import {
  Box,
  Button,
  Menu,
  MenuItem,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  InputLabel,
  Pagination,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import { useStore } from "../store/persistedStore";
import { useNonPersistedStore } from "../store/nonPersistedStore";
import DamHeader from "../components/DamHeader";
import LeftMenu from "../components/LeftMenu";
import { addToCart, customDownloadApi, deleteAsset, deleteCollectionDetailAsset, 
  downloadSelectedAssets, getCollectionDetail, getUserCart} from "../api/postRequest";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import Selector from "../components/Selector";
import PaginationComponent from "../components/PaginationComponent";
import DamPortalModal from "../components/DamPortalModal";

import { showErrorToast, showSuccessToast } from "../assest/Toaster";
import { truncate } from "../components/FolderTree";

import { sortArray } from "../utils/constant";

import {withStyles} from "@material-ui/core/styles";
import commonMessages from "../utils/commomMessages";

const TooltipStyle = withStyles({
  tooltip: {
    color: "var(--black) !important",
    backgroundColor: "var(--checked) !important",
    fontSize: 'var(--fontSize) !important',
    '& .MuiTooltip-arrow': {
      color: 'var(--checked)',
    },
  }
})(Tooltip);

const CollectionDetailPage = () => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [gridView, setIsGridView] = useState(true);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [loopMenuPopId, setLoopMenuPopId] = useState(null);
  const [addToCartModal, setAddToCartModal] = useState(false);
  const navigate = useNavigate();
  const [anchorElOption, setAnchorElOption] = useState(null);
  const openOption = Boolean(anchorElOption);
  const [anchorEl, setAnchorEl] = useState(null);
  const [optionBox, setOptionBox] = useState(false);

  const {
    setMainPageListingArr,
    mainPageListingArr,

    totalCountCollectionZustand,
    setTotalCountCollectionZustand, setClickedCollectionRowZus,clickedCollectionRowZus
  } = useStore();
  const {
    selectedMimeTypeZusNon,
    collectionListZusNon,
    setCollectionListZusNon,
    collectionDetailListingZusNon,
    setCollectionDetailListingZusNon,
    setCollectionDetailListingTotalCountZusNon,
    collectionDetailListingTotalCountZusNon,
    setUserCartListingZusNon, setUserCartListingTotalCountZusNon
   
  } = useNonPersistedStore();
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredAsseetsCheckAll, setFilteredAsseetsCheckAll] = useState([]);
  
  const [deleteId, setDeleteId] = useState();
  const [onlyChecked, setOnlyChecked] = useState();
  const [downloadCustomModal, setDownloadCustomModal] = useState(false);
  const [asset, setAsset] = useState();
  
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [sortingCollectionDetail, setSortingCollectionDetail] = useState();
  const pageSize = 12; //
  const openSort = Boolean(anchorElSort);

  const open = Boolean(anchorEl);

  const mutationGetCollectionDetail = useMutation(
    (post) => getCollectionDetail(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            let output = result?.data?.data?.mappedAssets;
            setIsAllChecked(false);

            const newArray = output?.map((obj) => ({
              ...obj,
              ["isChecked"]: false,
            }));

            setCollectionDetailListingZusNon(newArray);
            setTotalCountCollectionZustand(result.data?.data?.totalResults);
            //   setImageArray(newArray);
            //   setTotalCount(result.data?.data?.totalResults);
            setCollectionDetailListingTotalCountZusNon(
              result?.data?.data?.totalResults
            );
          } else {
            setCollectionDetailListingZusNon(null)
          }
        }
      },
      onError: (error) => {},
    }
  );

  const handleListView = () => {
    setIsGridView(false);
    // setIsListView(true);
  };
  const handleChecked = (e, idx) => {
    if (!e.target.checked) {
      setIsAllChecked(false);
    }

    let imgCollection = collectionDetailListingZusNon?.map((item) => {
      if (item?.elementId === idx) {
        item.isChecked = !item.isChecked;
      }
      return item;
    });

    const countIsCheckedTrue = imgCollection?.reduce(
      (acc, obj) => (obj?.isChecked ? acc + 1 : acc),
      0
    );

    if (imgCollection?.length == countIsCheckedTrue) {
      setIsAllChecked(true);
    }
    if (imgCollection?.length > 1) {
      setOptionBox(true);
    } else {
      setOptionBox(false);
    }
    let onlyIsChecked = imgCollection?.filter((item, idx) => {
      if (item?.isChecked) {
        return item;
      }
    });
    const newArrayOnlyIsChecked = onlyIsChecked?.map((obj) => ({
      dataPoolId: obj.dataPoolId,
      elementId: obj.elementId,
    }));
    setOnlyChecked(newArrayOnlyIsChecked);

    setCollectionDetailListingZusNon(imgCollection);

    // Filter the objects where isChecked is true and map to extract elementId and dataPoolId
    let filteredAssets =
      imgCollection &&
      imgCollection
        ?.filter((asset) => asset.isChecked)
        .map((asset) => ({
          elementId: asset.elementId,
          dataPoolId: asset.dataPoolId,
        }));
  };

  const handleCheckAll = () => {
    let flag = !isAllChecked;
    setIsAllChecked(flag);

    let imgCollection = collectionDetailListingZusNon?.map((item) => {
      item.isChecked = flag;
      return item;
    });
    setOptionBox(true);
    setMainPageListingArr(imgCollection);
    let filteredAssets =
      imgCollection &&
      imgCollection
        ?.filter((asset) => asset.isChecked)
        .map((asset) => ({
          elementId: asset.elementId,
          dataPoolId: asset.dataPoolId,
        }));
        setFilteredAsseetsCheckAll(filteredAssets)


  
  };
  useEffect(() => {
    let payload = {
      collectionId: searchParams?.get("id"),
      sortBy: "creationDate",
sortOrder: "desc"
    };
    setSortingCollectionDetail(["creationDate","desc"])
    if (searchParams?.get("id")) {
      mutationGetCollectionDetail.mutate(payload);
    }
  }, []);

  useEffect(() => {
    if (downloadCustomModal === false) {
      setAsset();
    }
  }, [downloadCustomModal]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleCloseSort = (sortObj) => {
    if (sortObj?.value) {
      let wordArray = sortObj?.value && sortObj?.value?.split(" ");

      // setSortingZusNon(wordArray);
      setSortingCollectionDetail(wordArray);

      let payload = {
        collectionId: searchParams?.get("id"),
        sortBy: wordArray && wordArray[0],
        sortOrder: wordArray && wordArray[1],
      };
      mutationGetCollectionDetail.mutate(payload);
      setCurrentPage(1);
      setAnchorElSort(null);
    } else {
      setAnchorElSort(null);
    }
  };


  const handleGridView = () => {
    setIsGridView(true);
  };
  const handlePageChange = (newPage) => {
    if (newPage === 0) {
      return;
    } else {
      setIsAllChecked(false);
      setCurrentPage(newPage);

      let payload = {
        currentPage: newPage,
        collectionId: searchParams?.get("id"),
      };
      mutationGetCollectionDetail.mutate(payload);
    }
  };
  const handleNavigationBack = () => {
    navigate(-1);
    setCollectionDetailListingZusNon(null);
  };

  const handleCloseModal = () => {
    setIsDeleteModal(false);
    setDownloadCustomModal(false)
    setAddToCartModal(false)
  };

  const handleDeleteAsset = (id) => {
    setIsDeleteModal(true);
    setDeleteId(id);
  };


  const getSelectedIds = (arr) => {
    let selectedIds = arr && arr?.reduce((acc, curr) => {
      if (curr?.isChecked) {
        acc.push(curr?.elementId);
      }
      return acc;
    }, []);
    return selectedIds || [];
  };

  const customDownloadApiMutation = useMutation(
    (post) => customDownloadApi(post),
    {
      onSuccess: (result) => {
        if (result) {
          if (result?.data?.success) {
            window.open(
              result?.data?.url,
              "_self"
              // "_blank"
            );

            handleCloseModal();
            showSuccessToast(result.data?.message);
          } else {
            showErrorToast(result.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const mutationDeleteAssetFromCollection = useMutation(
    (post) => deleteCollectionDetailAsset(post, clickedCollectionRowZus?.value),
    {
      onSuccess: (result) => {
        if (result) {
          if (result.data.success) {
            showSuccessToast(result?.data?.message);
            if (isAllChecked) {
              let payload = {
                currentPage: Math.max(currentPage - 1, 1),
                collectionId: searchParams?.get("id"), // Ensure page number is at least 1
              };
              mutationGetCollectionDetail.mutate(payload);
            }  else if (collectionDetailListingZusNon?.length ==1 ){
              let payload = {
                currentPage: Math.max(currentPage - 1, 1),
                collectionId: searchParams?.get("id"), // Ensure page number is at least 1
              };
              mutationGetCollectionDetail.mutate(payload);

            } else {
              let payload = {
                collectionId: searchParams?.get("id"),
                currentPage: currentPage,
              };
              mutationGetCollectionDetail.mutate(payload);
            }
            setIsAllChecked(false);
            if(isAllChecked){
              setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));  //
              }
           
            handleCloseModal();
          } else {
            showErrorToast(result?.data?.message);
          }
        }
      },
      onError: (error) => {},
    }
  );

  const handleCallbackDelete = () => {
    if (isDeleteModal) {
      if (deleteId?.elementId) {
        let payload = [
          {
            elementId: deleteId.elementId,
            dataPoolId: deleteId?.dataPoolId,
          },
        ];
        mutationDeleteAssetFromCollection.mutate(payload);
        // mutationDeleteAsset?.mutate(payload);
      } else {
        let payload = {
          elementId: getSelectedIds(collectionDetailListingZusNon),
        };
       
        if (isAllChecked) {
          mutationDeleteAssetFromCollection.mutate(filteredAsseetsCheckAll);
        } else {
          mutationDeleteAssetFromCollection.mutate(onlyChecked);
        }
        // mutationDeleteAsset?.mutate(payload);
      }
    }
  };


  const handleDownload = (id, parentId, name) => {
    setDownloadCustomModal(true);
    if (id) {
      setAsset({id:id,parentId:parentId});
    }
    // window.open(
    //   `https://dam-api.pimcoredemos.com/api/download-asset/${id}`,
    //   "_self"
    //   // "_blank"
    // );
    // setTimeout(() => {
    //   showSuccessToast("Asset downloaded succesfully");
    // }, 2000);

    // setDownloadModal(true);
  };

  const mutationDownloadSelected = useMutation(
    (post) => downloadSelectedAssets(post),
    {
      onSuccess: (result) => {
        if (result?.data?.success && result?.data?.url) {
          window.open(`${result?.data?.url}`);
        } else {
          showErrorToast(result.data?.message);
        }
      },
      onError: (error) => {},
    }
  );

  const handleDownloadSelected = () => {
    const selectedItems = collectionDetailListingZusNon?.filter(
      (obj) => obj?.isChecked == true
    );
    let selectedIds = [];
    selectedItems.forEach((element) => {
      selectedIds.push(element?.elementId);
    });
    let payload = { assetIds: selectedIds.toString() };
    mutationDownloadSelected.mutate(payload);
  };

  const mutationGetUserCart = useMutation((post) => getUserCart(post), {
    onSuccess: (result) => {
      if (result?.data?.success) {
        setUserCartListingZusNon(result?.data?.data?.entries);
        setUserCartListingTotalCountZusNon(result?.data?.data?.totalResults)
      } else {
      }
    },
    onError: (error) => {},
  });

  const mutationAddToCart = useMutation((post) => addToCart(post), {
    onSuccess: (result) => {
      if (result?.data?.success) {
        showSuccessToast(result.data?.message);
        mutationGetUserCart.mutate();
        handleCloseModal()
      } else {
        showErrorToast(result.data?.message);
      }
    },
    onError: (error) => {},
  });

  const handleAddToCart = (id, dataPoolId ,structuredData, assetData, customData) => {
    if (id && dataPoolId) {
      let configArr = [];
      if (structuredData?.type) {
        configArr.push(structuredData);
      }
      if (assetData?.type) {
        if (assetData?.format === "custom") {
          let res = { ...assetData, setup: customData };
          configArr.push(res);
        } else {
          configArr.push(assetData);
        }
      }
      let payload = {
        assets: [
          {
            dataPoolId: dataPoolId,
            selectedId: id,
            configs: configArr,
            // configs: [
            //   structuredData,
            //   assetData ,customData
            // ],
          },
        ],
      };
      mutationAddToCart.mutate(payload);
    } else {
      const selectedItems = collectionDetailListingZusNon?.reduce(
        (acc, curr) => {
          if (curr?.isChecked === true) {
            acc?.push({
              dataPoolId: curr?.dataPoolId,
              selectedId: curr?.elementId,
              configs: [
                 structuredData,
                  assetData ,customData
              ],
            });
          }
          return acc;
        },
        []
      );

      let payload = {
        assets: selectedItems,
      };
      mutationAddToCart.mutate(payload);
    }
  };
  const handleCloseOption = () => {
    setAnchorElOption(null);
  };

  const handleCrossOverlay = () => {
    setOptionBox(false);
    let imgCollection = collectionDetailListingZusNon?.map((item) => {
      item.isChecked = false;
      return item;
    });
    setIsAllChecked(false);
  };

  const handleClickOption = (event, obj) => {
    setLoopMenuPopId(obj);
    setAnchorElOption(event.currentTarget);
  };
  const handleNavigation = (id) => {
    navigate({
      pathname: "/detailPage",
      search: `id=${id}`,
    });
  };

  const handleSingleDownload = (assetObj, structuredData, assetData, customData) => {
   
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    let payload = {
      assets: [
        {
          dataPoolId: assetObj?.parentId,
          selectedId: assetObj?.id,
          configs: configArr,
        },
      ],
      source:"collectionDetail",
      collectionId: searchParams?.get("id"),
    };
    customDownloadApiMutation.mutate(payload);
  };

  const handleBulkDownload = (structuredData, assetData, customData) => {
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
    const selectedItems = collectionDetailListingZusNon?.reduce((acc, curr) => {
      if (curr?.isChecked === true) {
        acc?.push({
          dataPoolId: curr?.dataPoolId,
          selectedId: curr?.elementId,
          configs: configArr,
        });
      }
      return acc;
    }, []);

    let payload = {
      assets: selectedItems,
      source:"collectionDetail",
      collectionId: searchParams?.get("id"),
    };
  
    customDownloadApiMutation.mutate(payload);
  };


  const handleCallbackDownload = (structuredData, assetData, customData) => {
    if (asset?.id) {
      handleSingleDownload(asset, structuredData, assetData, customData);
    } else {
      handleBulkDownload(structuredData, assetData, customData);
    }
  };
  const handleOpenCartOptions = (elementId,dataPoolId) => {
    setAddToCartModal(true);
    if (elementId) {
      setAsset({elementId,dataPoolId});
      // setAsset(elementId);
    }
  };

  const handleBulkAddToCart = (structuredData, assetData, customData) => {
    let configArr = [];
    if (structuredData?.type) {
      configArr.push(structuredData);
    }
    if (assetData?.type) {
      if (assetData?.format === "custom") {
        let res = { ...assetData, setup: customData };
        configArr.push(res);
      } else {
        configArr.push(assetData);
      }
    }
  
    const selectedItems = collectionDetailListingZusNon?.reduce((acc, curr) => {
      if (curr?.isChecked === true) {
        acc?.push({
          dataPoolId: curr?.dataPoolId,
          selectedId: curr?.elementId,
          configs: configArr,
        });
      }
      return acc;
    }, []);

    let payload = {
      assets: selectedItems,
    };
 
    mutationAddToCart.mutate(payload);
  };
  const handleCallbackAddToCart = (structuredData, assetData, customData) => {
    if (asset) {
      if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
      
        return;
      } else if (
        (!structuredData.type && !assetData.format) ||
        (structuredData.type && !structuredData.format) ||
        (assetData.type && !assetData.format)
      ) {
        // Handle validation error, display message to user or prevent API call
  
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
     
        
        return;
      }
   
      handleAddToCart(asset?.elementId,asset?.dataPoolId, structuredData, assetData, customData);
    } else {
      if (assetData?.type?.length === 0 && structuredData?.type?.length === 0) {
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
        return;
      } else if (
        (!structuredData.type && !assetData.format) ||
        (structuredData.type && !structuredData.format) ||
        (assetData.type && !assetData.format)
      ) {
        // Handle validation error, display message to user or prevent API call
  
        showErrorToast(commonMessages?.assetMetadataCheckBoxMessage);
     
        
        return;
      }
      handleBulkAddToCart(structuredData, assetData, customData);
    }
  };
  return (
    <>
      {mutationGetCollectionDetail?.isLoading && <Loader></Loader>}
      {mutationDeleteAssetFromCollection.isLoading && <Loader></Loader>}
      <Box>
        <DamPortalModal
          open={downloadCustomModal}
          downloadCustomModalFlag={true}
          onClose={handleCloseModal}
          titleMessage={"Download"}
          handleCallbackDownload={handleCallbackDownload}
          customDownloadApiMutation={customDownloadApiMutation}
        />
      </Box>
      <Box>
        <DamPortalModal
          open={addToCartModal}
          addToCartModalFlag={addToCartModal}
          onClose={handleCloseModal}
          titleMessage={commonMessages?.addToCart}
          mutationAddToCart={mutationAddToCart}
          handleCallbackAddToCart={handleCallbackAddToCart}
        />
      </Box>
      <Box>
        <DamPortalModal
          open={isDeleteModal}
          onClose={handleCloseModal}
          titleMessage={"Remove asset(s)"}
          deleteIdRemoveCollection={deleteId}
          deleteAssetFromCollection={isDeleteModal}
          handleCallbackDelete={handleCallbackDelete}
        />
      </Box>
      <Grid container className="container bg">
        <Grid item xs={1} className="menu">
          <LeftMenu />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ minHeight: "calc(100% - 50px)" }}
          className="rightPart"
        >
          <DamHeader />
          <Box className="collectionMenu">
            <Grid container>
              <Grid item xs={6}>
                <Typography component="h2" variant="h5" className="heading">
                  {/* Collection detail */}
                  Collection: <span>{clickedCollectionRowZus?.name}</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  size="small"
                  startIcon={<ChevronBack />}
                  className="button"
                  onClick={handleNavigationBack}
                >
                  Back
                </Button>
              </Grid>
              {collectionDetailListingZusNon && (
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    onClick={handleClickSort}
                    size="small"
                    // sx={{ marginRight: "5px !important" }}
                    aria-controls={openSort ? "sorting" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSort ? "true" : undefined}
                    startIcon={<Sort />}
                    endIcon={<DownArrow />}
                    className="menuButton"
                  >
                    Sort
                  </Button>
                  <Menu
                    anchorEl={anchorElSort}
                    id="sorting"
                    open={openSort}
                    onClose={handleCloseSort}
                    className="customMenuClass"
                    // onClick={handleCloseSort}
                    {...paperPropsStyles}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                  >
                    {sortArray?.map((sortObj) => (
                      <MenuItem
                        sx={{
                          color:
                            sortObj?.value ===
                            sortingCollectionDetail?.join(" ")
                              ? "var(--primary)"
                              : "",
                        }}
                        onClick={() => handleCloseSort(sortObj)}
                      >
                        {sortObj.label}
                        <Box
                          component="span"
                          sx={{ display: "inline", marginLeft: "3px" }}
                        >
                          {" "}
                          {sortObj?.svg}
                        </Box>
                      </MenuItem>
                    ))}
                  </Menu>
                  <Box className={`checkbox ${isAllChecked ? "checked" : ""}`}>
                    <Checkbox
                      checked={isAllChecked}
                      name="select all"
                      onClick={handleCheckAll}
                    />
                    <Typography component="label">Select all</Typography>
                  </Box>
                </Grid>
              )}
              { collectionDetailListingZusNon && (
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <TooltipStyle placement="bottom" arrow title="Grid view">
                    <Button
                      sx={{ cursor: "pointer", marginRight: "2px !important" }}
                      onClick={handleGridView}
                      size="small"
                      className={`button icon ${gridView ? "active" : ""}`}
                    >
                      <CardView />
                    </Button>
                  </TooltipStyle>
                  <TooltipStyle placement="bottom" arrow title="List view">
                    <Button
                      size="small"
                      sx={{ cursor: "pointer" }}
                      onClick={handleListView}
                      className={`button icon ${!gridView ? "active" : ""}`}
                    >
                      <ListView />
                    </Button>
                  </TooltipStyle>
                </Grid>
             ) }
            </Grid>
            {!mutationGetCollectionDetail.isLoading ?(  !collectionDetailListingZusNon && (
              <Box className="notificationWrapper bg">
                <Box className="notification">
                  No items present in this collection.
                </Box>
              </Box>
            )):""}
          </Box>

          {!mutationGetCollectionDetail.isLoading ? (gridView ? (
            <Box className="bodyParts collections" sx={{ minHeight: '75vh'}}>
              <Box className="box detailed">
                {collectionDetailListingZusNon &&
                  collectionDetailListingZusNon?.map((imgObj) => (
                    <>
                      <div className="item" key={imgObj?.elementId}>
                        <Card
                          className={`card ${
                            isAllChecked || imgObj?.isChecked ? "checked" : ""
                          }`}
                        >
                          <Box className="cardMedia">
                            <CardMedia
                              sx={{
                                height: 180,
                                backgroundSize: "contain",
                                cursor: "pointer",
                              }}
                              // image={imgObj?.elementURL}
                              image={imgObj?.thumbnail}
                              title={imgObj?.elementName}
                              onClick={() => {
                                handleNavigation(imgObj?.elementId);
                              }}
                            />
                          </Box>
                          <CardContent
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ width: "80%", display: "flex" }}>
                              <Checkbox
                                checked={imgObj?.isChecked}
                                // value={imgObj?.isChecked}
                                id={imgObj?.elementId}
                                onClick={(e) =>
                                  handleChecked(e, imgObj?.elementId)
                                }
                              />
                              <TooltipStyle
                                placement="bottom"
                                arrow
                                title={
                                  imgObj?.elementName
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                  imgObj?.elementName?.slice(1)
                                }
                              >
                                <label htmlFor={imgObj?.id}>
                                  {truncate(
                                    imgObj?.elementName
                                      .charAt(0)
                                      .toUpperCase() +
                                      imgObj?.elementName.slice(1),
                                    35
                                  )}
                                </label>
                              </TooltipStyle>
                            </Box>
                            <Button
                              onClick={(e) => handleClickOption(e, imgObj)}
                              size="small"
                              sx={{ marginRight: "5px !important" }}
                              aria-controls={openOption ? "Option" : undefined}
                              aria-haspopup="true"
                              aria-expanded={openOption ? "true" : undefined}
                              className="button icon"
                            >
                              <More />
                            </Button>
                            <Menu
                              anchorEl={anchorElOption}
                              id="Option"
                              open={openOption}
                              onClose={handleCloseOption}
                              onClick={handleCloseOption}
                              // {...paperPropsStyles}
                              {...paperPropsStylesThreeDotsLAndingPAge}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <MenuItem
                                onClick={() =>
                                  handleOpenCartOptions(
                                    loopMenuPopId?.elementId,
                                    loopMenuPopId?.dataPoolId
                                  )
                                }
                                // onClick={() =>
                                //   handleAddToCart(
                                //     loopMenuPopId?.elementId,
                                //     loopMenuPopId?.dataPoolId
                                //   )
                                // }
                              >
                                <Shop /> {commonMessages?.addToCart}
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleDownload(
                                    loopMenuPopId?.elementId,
                                    loopMenuPopId?.dataPoolId
                                  )
                                }
                              >
                                <Download />
                                Download
                              </MenuItem>

                              <MenuItem
                                onClick={() => {
                                  handleDeleteAsset(loopMenuPopId);
                                }}
                              >
                                <Delete />
                                Remove
                              </MenuItem>
                            </Menu>
                          </CardContent>
                        </Card>
                      </div>
                    </>
                  ))}
              </Box>

              <PaginationComponent
                  currentPage={currentPage}
                  totalCount={collectionDetailListingTotalCountZusNon}
                  pageSize={pageSize}
                  onPageChange={handlePageChange}
                  itemCount={collectionDetailListingZusNon?.length || 0}
                  // itemsPerPage={itemsPerPage}
                />
           
            </Box>
          ) : (
            collectionDetailListingZusNon?.length && (
              <Box className="bodyParts collections" sx={{ minHeight: '75vh'}}>
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan="1"></TableCell>
                        <TableCell component="th" width="10%">
                          Image
                        </TableCell>
                        <TableCell component="th" width="40%">
                          Name
                        </TableCell>
                        <TableCell component="th" width="15%">
                          Mime type
                        </TableCell>
                        <TableCell component="th" width="15%">
                          Size
                        </TableCell>
                        <TableCell component="th" width="20%" align="center">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& .button": {
                          border: 0,
                          margin: "2px !important",
                        },
                        "& img": {
                          height: "60px",
                          maxWidth: "60px",
                          objectFit: "cover",
                        },
                      }}
                    >
                      {collectionDetailListingZusNon &&
                        collectionDetailListingZusNon?.map((imgObj) => (
                          <TableRow
                            tabIndex={-1}
                            className={`${
                              isAllChecked || imgObj?.isChecked ? "checked" : ""
                            }`}
                          >
                            <TableCell>
                              <Checkbox
                                checked={imgObj?.isChecked}
                                // value={imgObj?.isChecked}
                                onClick={(e) =>
                                  handleChecked(e, imgObj?.elementId)
                                }
                              />
                            </TableCell>
                            <TableCell key={imgObj?.elementId}>
                              <img
                                //   src={`${"http://"}${"dam-api.pimcoredemos.com"}${
                                //     imgObj?.elementPath
                                //   }${imgObj?.elementName}`}
                                // src={imgObj?.elementURL}

                                src={imgObj?.thumbnail}
                                title={imgObj?.elementName}
                              />
                              {/* <img src={imgObj?.path} title="green iguana" /> */}
                            </TableCell>
                            <TableCell
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                handleNavigation(imgObj?.elementId);
                              }}
                            >
                              <Typography variant="p">
                                {imgObj?.elementName.charAt(0).toUpperCase() +
                                  imgObj?.elementName.slice(1)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="p">
                                {imgObj?.elementMimeType}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="p">
                                {imgObj?.elementSize}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Box className="buttonGroup">
                                <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title={commonMessages?.addToCart}
                                >
                                  <Button
                                    onClick={() =>
                                      handleOpenCartOptions(
                                        imgObj?.elementId,
                                        imgObj?.dataPoolId
                                      )
                                    }
                                    // onClick={() =>
                                    //   handleAddToCart(
                                    //     imgObj?.elementId,
                                    //     imgObj?.dataPoolId
                                    //   )
                                    // }
                                    size="small"
                                    className="button icon"
                                  >
                                    <Shop />
                                  </Button>
                                </TooltipStyle>
                                <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title="Download"
                                >
                                  <Button
                                    size="small"
                                    className="button icon"
                                    onClick={() =>
                                      handleDownload(imgObj?.elementId)
                                    }
                                  >
                                    <Download />
                                  </Button>
                                </TooltipStyle>

                                <TooltipStyle
                                  placement="bottom"
                                  arrow
                                  title="Remove"
                                >
                                  <Button
                                    size="small"
                                    className="button icon"
                                    onClick={() => {
                                      handleDeleteAsset(imgObj);
                                    }}
                                  >
                                    <Cross />
                                  </Button>
                                </TooltipStyle>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

           
                  <PaginationComponent
                    currentPage={currentPage}
                    totalCount={collectionDetailListingTotalCountZusNon}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    itemCount={collectionDetailListingZusNon?.length || 0}
                    // itemsPerPage={itemsPerPage}
                  />
                
              </Box>
            )
          )): ""}

          {getSelectedIds(collectionDetailListingZusNon)?.length > 1 &&
            optionBox && (
              <Box className="selected small">
                <Box className="selectedOne">
                  <>
                    <span>
                      {collectionDetailListingZusNon &&
                        collectionDetailListingZusNon?.filter(
                          (val) => val?.isChecked === true
                        )?.length}{" "}
                    </span>
                    {getSelectedIds(collectionDetailListingZusNon)?.length > 1
                      ? "Assets"
                      : "Asset"}{" "}
                    selected
                  </>
                </Box>

                <Button
                  className="button link"
                  onClick={() => handleOpenCartOptions()}
                  // onClick={() => handleAddToCart()}
                >
                  <Shop />
                  {commonMessages?.addToCart}
                </Button>
                <Button
                  className="button link"
                  // onClick={() => handleDownloadSelected()}
                  onClick={() => handleDownload()}
                >
                  <Download />
                  Download
                </Button>
                <Button
                  className="button link"
                  onClick={() => handleDeleteAsset()}
                >
                  <Delete />
                  Remove
                </Button>

                <Button
                  className="button link"
                  onClick={() => handleCrossOverlay()}
                >
                  <Cross />
                  Clear All
                </Button>
              </Box>
            )}
        </Grid>
        <Grid xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default CollectionDetailPage;
