import { create } from "zustand";


export const useNonPersistedStore = create((set) => ({
  text: "adil",
  setText: (payload) => set({ text: payload }),
  cardEditId: "",
  // clickedFolderZusNon: {
  //   label: "Home",
  //   id: 1,
  //   permissions:{}
  // },
  clickedFolderZusNon: {
    label: "",
    id: "",
    permissions: {},
  },
  setClickedFolderZusNon: (payload) => set({ clickedFolderZusNon: payload }),
  clickedMoveFolderZusNon: {
    label: "",
    id: "",
    permissions: {},
  },
  setClickedMoveFolderZusNon: (payload) =>
    set({ clickedMoveFolderZusNon: payload }),
  languageOptionsZusNon: "",
  setLanguageOptionsZusNon: (payload) =>
    set({ languageOptionsZusNon: payload }),

  clickedMoveToFolderPopUpZusNon: "",
  setClickedMoveToFolderPopUpZusNon: (payload) =>
    set({ clickedMoveToFolderPopUpZusNon: payload }),

  editAssetZusNon: false,
  setEditAssetZusNon: (payload) => set({ editAssetZusNon: payload }),

  isMoveModalZusNon: false,
  setIsMoveModalZusNon: (payload) => set({ editAssetZusNon: payload }),
  expandedNonZus: [1],
  setExpandedNonZus: (payload) => set({ expandedNonZus: payload }),
  selectedMimeTypeZusNon: false,
  setSelectedMimeTypeZusNon: (payload) =>
    set({ selectedMimeTypeZusNon: payload }),
  collectionListZusNon: "",
  setCollectionListZusNon: (payload) => set({ collectionListZusNon: payload }),
  collectionDetailListingZusNon: "",
  setCollectionDetailListingZusNon: (payload) =>
    set({ collectionDetailListingZusNon: payload }),

  publicShareHistoryListingZusNon: "",
  setPublicShareHistoryListingZusNon: (payload) =>
    set({ publicShareHistoryListingZusNon: payload }),
  publicShareCollectionHistoryListingZusNon: "",
  setPublicShareCollectionHistoryListingZusNon: (payload) =>
    set({ publicShareCollectionHistoryListingZusNon: payload }),

  publicShareListinZusNon: "",
  setPublicShareListinZusNon: (payload) =>
    set({ publicShareListinZusNon: payload }),
  publicShareCompleteListinZusNon: "",
  setPublicShareCompleteListinZusNon: (payload) =>
    set({ publicShareCompleteListinZusNon: payload }),

  collectionDetailListingTotalCountZusNon: "",
  setCollectionDetailListingTotalCountZusNon: (payload) =>
    set({ collectionDetailListingTotalCountZusNon: payload }),
  publicShareHistoryPageTotalCountZusNon: "",
  setPublicShareHistoryPageTotalCountZusNon: (payload) =>
    set({ publicShareHistoryPageTotalCountZusNon: payload }),
  publicShareCollectionHistoryPageTotalCountZusNon: "",
  setPublicShareCollectionHistoryPageTotalCountZusNon: (payload) =>
    set({ publicShareCollectionHistoryPageTotalCountZusNon: payload }),

  publicShareTotalCountZusNon: "",
  setPublicShareTotalCountZusNon: (payload) =>
    set({ publicShareTotalCountZusNon: payload }),
  collectionListTotalCountZusNon: "",
  setCollectionListTotalCountZusNon: (payload) =>
    set({ collectionListTotalCountZusNon: payload }),
  userCartListZusNon: [],
  setUserCartListingZusNon: (payload) => set({ userCartListZusNon: payload }),
  userCartListTotalCountZusNon: null,
  setUserCartListingTotalCountZusNon: (payload) => set({ userCartListTotalCountZusNon: payload }),
  sortingZusNon: ["creationDate", "desc"],
  setSortingZusNon: (payload) => set({ sortingZusNon: payload }),
  isNewFilterOpenZusNon: false,
  setIsNewFilterOpenZusNon: (payload) =>
    set({ isNewFilterOpenZusNon: payload }),

    filterLabelValueForDeleteZusNon: null,
    setFilterLabelValueForDeleteZusNon: (payload) =>
    set({ filterLabelValueForDeleteZusNon: payload }),

    applyFilterBtnCreateNewZusNon: false,
    setApplyFilterBtnCreateNewZusNon: (payload) =>
    set({ applyFilterBtnCreateNewZusNon: payload }),
    applyFilterBtnSavedFilterZusNon: false,
    setApplyFilterBtnSavedFilterZusNon: (payload) =>
    set({ applyFilterBtnSavedFilterZusNon: payload }),
    applyFilterBtnSavedFilterCreateNewZusNon: false,
    setApplyFilterBtnSavedFilterCreateNewZusNon: (payload) =>
    set({ applyFilterBtnSavedFilterCreateNewZusNon: payload }),

    

  // filterMasalaZusNon: {
  //   selectedValueTypeMime: "",
  //   selectedModiDate: "",
  //   selectedCreationDate: "",
  //   selectedValueType:""
  // },
  filterMasalaZusNon: null,
   

  setFilterMasalaZusNon: (payload) =>
    set({ filterMasalaZusNon: payload }),
    

  setDomesticOption: (isDomesticOption) =>
    set({ isDomesticOption: isDomesticOption }),
    selectedFilterZusNon: null,
    setSelectedFilterZusNon: (payload) => set({ selectedFilterZusNon: payload }),
    replacedImageZusNon:"",
    setReplacedImageZusNon:  (payload) => set({ replacedImageZusNon: payload }),
    selectedIconZusNon:"",
    setSelectedIconZusNon:   (payload) => set({ selectedIconZusNon: payload }),
    versionDataZusNon:"",
    setVersionDataZusNon:   (payload) => set({ versionDataZusNon: payload }),
    searchValueZusNon:"",
    setSearchValueZusNon: (payload) => set({ searchValueZusNon: payload }) ,
     loaderZusNon:"",
    setLoaderZusNon: (payload) => set({ loaderZusNon: payload }),
    payloadForSortZusNon:"",
    setPayloadForSortZusNon: (payload) => set({ payloadForSortZusNon: payload })
    
}));
