export const menuStylesThreeDots = {
  overflow: "visible",
  // filter: "drop-shadow(0px 0px 8px rgba(0,0,0,0.12))",
  // mt: 1.5,
  borderRadius: 0,
  minWidth: "150px",
  zIndex: "30",
  "& .MuiPaper-root": {
    borderRadius: "0",
    // border: "1px solid var(--border)",
  },
  "& .MuiList-root": {
    padding: "0 !important",
    color: "var(--text)",
    border: "1px solid var(--border)",
    borderRadius: "3px",
    marginBottom: "5px",
    "& li":{
      justifyContent: 'flex-start',
      width:"150px"
    },
    "& svg": {
      marginRight: "5px",
      fill: "var(--text)",
      width: "30px",
    },
  },
  "& .MuiMenuItem-root": {
    borderBottom: "1px solid var(--border)",
    fontSize: "14px",
    padding: "5px !important",
    "&:last-child": {
      borderBottom: "0",
    },
  },
  "& .MuiChip-root": {
    borderRadius: 0,
    padding: 0,
    fontSize: "12px",
    height: "auto",
    width: "50px",
    marginLeft: "auto",
  },
};

export const paperPropsStylesThreeDotsLAndingPAge = {
  elevation: 0,
  sx: menuStylesThreeDots,
};
