
import { Box, Button, ButtonGroup,    Typography } from "@mui/material";


import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import {ReactComponent as Search} from '../assest/svg/search.svg';
import {ReactComponent as Collapse} from '../assest/svg/collapse.svg';

import { ReactComponent as ArrowDown } from "../assest/svg/chevronDown.svg";

import { useState,useEffect } from 'react';

import { getFirstPageListing, getFolders, moveToFolder } from "../api/postRequest";
import { useMutation } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../assest/Toaster";
import { useStore } from "../store/persistedStore";
import Loader from "./Loader";
import { useNonPersistedStore } from "../store/nonPersistedStore";
import { useLocation } from "react-router-dom";



export const truncateMove = (text, num) => {
  if(text?.length > num){
    return text.slice(0, num) + "..."
  } else {
    return text
  }
}

const FolderTreeMoveToFolder = ({
  moveTofolderModalFlag,
  managefolderPageFlag,
  hanldleCallbackMoveToFolder,setIsAllChecked,
  handleMoveTofolderCallbackApi,
  setIsMoveModal,
  isExpanded,
  setManageFolderTreeItemFn=()=>{},
  assetId,
  assetArr
}) => {
  const [expanded, setExpanded] = useState([1]);
  const [folderStructure, setFolderStructure] = useState([]);
  const [moveTofolderPayload, setMoveTofolderPayload] = useState();
 

  const {
    setMainPageListingArr,
 
    setTotalCountZustand,
    setClickedFolderZus,
    folderTreeStructureZus,
    setFolderTreeStructureZus,
  } = useStore();
  const {
    setSortingZusNon,
    sortingZusNon,
    clickedFolderZusNon,
    setClickedFolderZusNon,
    clickedMoveFolderZusNon,
    setClickedMoveFolderZusNon,
    setClickedMoveToFolderPopUpZusNon,
    clickedMoveToFolderPopUpZusNon,
    expandedNonZus,
    setExpandedNonZus
  } = useNonPersistedStore();

  const determinePermissions = (selectedFolder) => {
    
    if (!selectedFolder) {
      // If no folder is selected, return default permissions
      return {
        Create: false,
        Delete: false,
        Download: false,
        Edit: false,
        Move_assets: false,
        permission_view_owned_assets_only: false,
        View: false,
        View_versions: false
      };
    }
  
    // Extract permissions from the selected folder
    const permissions = selectedFolder.permissions;
  
    // Customize permissions based on the folder's characteristics or any other criteria
    // For demonstration purposes, let's say Brand Logos folder gets special permissions
    if (selectedFolder.label === 'Brand Logos1ad') {
      return {
        ...permissions,
        Download: true, // Allow downloading files from Brand Logos folder
        View: true // Allow viewing files in Brand Logos folder
      };
    }
  
    // Return default permissions for other folders
    return permissions;
  };
  const mutationGetFolders = useMutation((post) => getFolders(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          // mutation.mutate({assetId:detailPageData?.id})
          setFolderStructure(result?.data?.data?.items);
          setFolderTreeStructureZus(result?.data?.data?.items);
        
          showSuccessToast(result?.data?.message);
        } else {
        }
      }
    },
    onError: (error) => {},
  });
  useEffect(() => {
    const permissions = determinePermissions(clickedFolderZusNon);
  
    
  }, [clickedFolderZusNon])
  

  const mutation = useMutation((post) => getFirstPageListing(post), {
    onSuccess: (result) => {
      if (result) {
        if (result.data.success) {
          let output = result?.data?.data?.items;
          const newArray = output?.map((obj) => ({
            ...obj,
            ["isChecked"]: false,
          }));
          setMainPageListingArr(newArray);
          setTotalCountZustand(result.data?.data?.totalResults);
        } else {
        }
      }
    },
    onError: (error) => {},
  });
 
  const moveToFolderMutation = useMutation((post) => moveToFolder(post), {
    onSuccess: (result) => {
      if (result) {
        if (result?.data?.success) {
          showSuccessToast(result?.data?.message);
          hanldleCallbackMoveToFolder();
          handleMoveTofolderCallbackApi();
          setIsAllChecked(false)
        } else {
          showErrorToast(result?.data?.message);
        }
      }
    },
    onError: (error) => {},
  });


  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
     !moveTofolderModalFlag && setExpandedNonZus(nodeIds)
  };

  const handleButtonClick = () => {
    setExpanded([1]);
    setExpandedNonZus([1])
  };

  const handleCollapse = () => {
    setExpanded([1]);
   
  }

  const validateMoveFolder = (targetFolderId, assetIdArr) => {
    let validate = true;
    assetIdArr?.forEach((val) => {
      assetArr?.forEach((item) => {
        if (item?.id == val && item?.parent == targetFolderId) {
          showErrorToast(`${item?.name} already exists in the selected folder`);
          validate = false;
        }
      });
    });
    return validate;
  };

  const handleTreeItemClick = (label, id, permission) => {
    let payload = {
      sortBy: sortingZusNon && sortingZusNon[0],
      sortOrder: sortingZusNon && sortingZusNon[1],
      folderName: label,
      folderId:id,
    };
    if (moveTofolderModalFlag) {
      setClickedMoveToFolderPopUpZusNon({
        label: label,
        id: id,
        permissions: permission,
      });
    } else {
      
      setClickedFolderZusNon({ label: label, id: id, permissions: permission });
    }

    if (moveTofolderModalFlag) {
      let payload = {
        targetFolder: id,
        assetIds: assetId,
      };
      setMoveTofolderPayload(payload);
    } else if (managefolderPageFlag) {
      let payload = {
        targetFolder: id,
        assetId: assetId,
      };
      setManageFolderTreeItemFn(label, id, folderStructure);
      // setManageFolderTreeItemClicked(true);
    } else {
      mutation.mutate(payload);
    }
  };


  const handleMoveToFolderMutation = () => {
    
    // if (validateMoveFolder(moveTofolderPayload?.targetFolder, assetId)) {
      moveToFolderMutation.mutate(JSON.stringify(moveTofolderPayload));
    // }
  };
  useEffect(() => {
    mutationGetFolders.mutate();
  }, []);

  

  const renderTreeItems = (data) => {
    return (
      data &&
      data?.map((node) => (
        <>
          <TreeItem
            key={node?.id}
            nodeId={node?.id}
            label={
              <Box
                component="div"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  title={node?.label}
                  sx={{
                    color: !node.clickable
                      ? "#bdbdb1"
                      : node?.id === clickedMoveToFolderPopUpZusNon?.id
                      ? "var(--primary)"
                      : "",
                  }}
                  onClick={() => {
                    if (node?.clickable) {
                      handleTreeItemClick(
                        node?.label,
                        node?.id,
                        node?.permissions
                      );
                    }
                  }}
                >
                  {truncateMove(node?.label, 15)}{" "}
                  {location.pathname === "/manageFolderPage" &&
                    `(${node?.childrenCount})`}
                  {/* {location.pathname === '/manageFolderPage'  && node?.childrenCount >0 ?
                  <Chip sx={{marginLeft: 'auto'}} label={node?.childrenCount} />
                  : null
                } */}
                </Box>
                {node?.childrenCount > 0 && <ArrowDown height={15} />}
              </Box>
            }
            icon={<Search />}
            onToggle={handleToggle}
            expanded={expanded.includes(node.id)}
          
          >
            {node?.children &&
              node?.children?.length > 0 &&
              renderTreeItems(node?.children)}
          </TreeItem>
        </>
      ))
    );
  };
  const location = useLocation();
  
 


  return (
    <>
      {mutationGetFolders.isLoading && <Loader></Loader>}
      {mutation.isLoading && <Loader></Loader>}
      {moveToFolderMutation?.isLoading && <Loader></Loader>}
       <>
       
           
        <Box className={`treestructure `}>
       
          <Box className="topHead">
            <Typography component="h6" variant="h6">
              Folders
            </Typography>
            <ButtonGroup>
            
              {!moveTofolderModalFlag ? 
                (expandedNonZus?.length > 1 && <Button
                  className="button link"
                  onClick={handleButtonClick}
                  startIcon={<Collapse />}
                >
                  Collapse all
                </Button>) : 
                (expanded?.length > 1 && <Button
                  className="button link "
                  onClick={handleCollapse}
                  startIcon={<Collapse />}
                >
                  Collapse
              </Button>)
              }

 
              {moveTofolderModalFlag && moveTofolderPayload?.targetFolder && (
                <Button className="button marginL-xs" onClick={handleMoveToFolderMutation}>
                  Move to folder
                </Button>
              )}
            </ButtonGroup>
          </Box>
         
          <Box className="topBot" mb={4}>
            <TreeView
              aria-label="file system navigator"
              // defaultCollapseIcon={<Search />}
              // defaultExpandIcon={<Profile />}
              expanded={expanded}
              defaultExpanded={[1]}
              onNodeToggle={handleToggle}
            >
              {renderTreeItems(folderTreeStructureZus)}
            </TreeView>
          </Box>
        </Box>
      </>
    </>
  );
};

export default FolderTreeMoveToFolder